<template>
	<div class="clv_account_desktop_page">
		<div class="clv_account_desktop_page_loading" v-if="clvIsLoading"><div class="clv_loading"></div></div>
		<div class="clv_account_desktop_page_content" v-else>
			<div class="clv_account_desktop_clover_params">
				<div class="clv_tabs">
					<div class="clv_tabs_mobile"><v-select class="clv_select style-chooser" v-model.trim="clvCloverParamsTab" :options="clvCloverParamsTabs" @input="selectSTab"></v-select></div>
					<input type="radio" class="clv_tab_menu_input" id="clv_tab_menu_1" name="clv_tab_menu" :checked="clvIsTabMenu1Checked">
					<label class="clv_tab_menu_label" for="clv_tab_menu_1" @click="selectTab(1)">{{ clvCloverParamsTabs[0].label }}</label>
					<input type="radio" class="clv_tab_menu_input" id="clv_tab_menu_2" name="clv_tab_menu" :checked="clvIsTabMenu2Checked">
					<label class="clv_tab_menu_label" for="clv_tab_menu_2" @click="selectTab(2)">{{ clvCloverParamsTabs[1].label }}</label>
					<input type="radio" class="clv_tab_menu_input" id="clv_tab_menu_3" name="clv_tab_menu" :checked="clvIsTabMenu3Checked">
					<label class="clv_tab_menu_label" for="clv_tab_menu_3" @click="selectTab(3)">{{ clvCloverParamsTabs[2].label }}</label>
					<input type="radio" class="clv_tab_menu_input" id="clv_tab_menu_4" name="clv_tab_menu" :checked="clvIsTabMenu4Checked">
					<label class="clv_tab_menu_label" for="clv_tab_menu_4" @click="selectTab(4)">{{ clvCloverParamsTabs[3].label }}</label>
					<input type="radio" class="clv_tab_menu_input" id="clv_tab_menu_5" name="clv_tab_menu" :checked="clvIsTabMenu5Checked">
					<label class="clv_tab_menu_label" for="clv_tab_menu_5" @click="selectTab(5)">{{ clvCloverParamsTabs[4].label }}</label>
					<div class="clv_tab_block" :id="'clv_tab_block_' + tab" v-for="tab in 5" :key="tab">
						<div class="clv_account_desktop_portlet">
							<div class="clv_account_desktop_portlet_title">Настройки Clover ({{ clvCloverParamsTabs[(tab - 1)].label }})</div>
							<div class="clv_account_desktop_portlet_content">
								<div class="clv_account_desktop_clover_params_status"><b>Статус:</b><toggle-button class="clv_toggle_button" :value="toggleValue(clvIsActiveCloverParamsValue)" :sync="true" :width="40" :height="25" :color="{ checked: '#00FF0080' }" @change="toggleChangeForCloverParamsIsActive(tab, $event)"></toggle-button></div>
								<div class="clv_account_desktop_clover_params_api_link"><b>Ссылка:</b><a :href="$store.state.clvApiBaseURL + '/v2/oauth/clover_params?strategy=' + clvCloverParamsStrategyNames[Math.floor((tab - 1) / 2)] + (tab > 4 ? '' : ('&is_full_avg=' + ((tab - 1) % 2)))" target="_blank">{{ ($store.state.clvApiBaseURL + '/v2/oauth/clover_params?strategy=' + clvCloverParamsStrategyNames[Math.floor((tab - 1) / 2)] + (tab > 4 ? '' : ('&is_full_avg=' + ((tab - 1) % 2)))) }}</a></div>
								<table class="clv_table">
									<thead>
										<tr>
											<th>#</th>
											<th>key</th>
											<th>type</th>
											<th>start_value</th>
											<th>end_value</th>
											<th>step_inc</th>
											<th><font-awesome-icon :icon="['fas', 'flask']" /></th>
											<th><font-awesome-icon :icon="['fas', 'check']" /></th>
											<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
											<th><font-awesome-icon :icon="['fas', 'times']" /></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(value, index) in clvCloverParamsData" :key="index">
											<td data-label="#:">{{ (index + 1) }}</td>
											<td data-label="key:">{{ value.key }}</td>
											<td data-label="type:">{{ value.type }}</td>
											<td data-label="start_value:">{{ (value.start_value !== '' ? value.start_value : '−') }}</td>
											<td data-label="end_value:">{{ (value.end_value !== '' ? value.end_value : '−') }}</td>
											<td data-label="step_inc:">{{ (value.step_inc !== '' ? value.step_inc : '−') }}</td>
											<td data-label="is_test:"><toggle-button class="clv_toggle_button" :value="toggleValue(value.is_test)" :sync="true" :width="40" :height="25" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsTest(value.id, index, $event)" v-if="!clvCloverParamsMKeys.includes(value.key)"></toggle-button></td>
											<td data-label="is_active:"><toggle-button class="clv_toggle_button" :value="toggleValue(value.is_active)" :sync="true" :width="40" :height="25" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsActive(value.id, index, $event)" v-if="!clvCloverParamsMKeys.includes(value.key)"></toggle-button></td>
											<td data-label="Изменить:"><button type="button" class="clv_button blue icon" @click="$modal.show('ClvModalCloverParams', { type: 'editCloverParameter', index: index, id: value.id, strategy: Math.ceil(tab / 2), is_full_avg: ((tab - 1) % 2) })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
											<td data-label="Удалить:"><button type="button" class="clv_button red icon" @click="$modal.show('ClvModalCloverParams', { type: 'deleteCloverParameter', index: index, id: value.id })" v-if="!clvCloverParamsMKeys.includes(value.key)"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="clv_account_desktop_portlet_loading" :class="{ hide: !clvIsPortletLoading }"><img src="@/assets/images/loading.gif"></div>
							<div class="clv_account_desktop_portlet_buttons" :class="{ hide: clvIsPortletLoading }">
								<button type="button" class="clv_button big" @click="axios('getCloverParamsData')" v-if="clvCloverParamsDataLimitOffset"><font-awesome-icon :icon="['fas', 'arrow-down']" />Показать еще</button>
								<button type="button" class="clv_button big green" @click="$modal.show('ClvModalCloverParams', { type: 'addCloverParameter', strategy: Math.ceil(tab / 2), is_full_avg: ((tab - 1) % 2) })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить параметр</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="clv_account_desktop_modals">
				<div is="ClvModalCloverParams"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			clvCloverParamsTab: '',
			clvCloverParamsTabs: [
				{ code: 1, label: 'Пирамидинг' },
				{ code: 2, label: 'Пирамидинг + FA' },
				{ code: 3, label: 'Пошаговый' },
				{ code: 4, label: 'Пошаговый + FA' },
				{ code: 5, label: 'Zigzag' }
			],
			clvCloverParamsData: [],
			clvCloverParamsDataLimitOffset: 0,
			clvCloverParamsDataLimitCount: 100,
			clvCloverParamsStrategyNames: ['pyramid', 'step_by_step', 'zigzag'],
			clvCloverParamsStrategyId: 1,
			clvCloverParamsIsFullAvg: 0,
			clvCloverParamsMKeys: ['date_from', 'date_to'],
			clvIsActiveCloverParamsData: {},
			clvIsActiveCloverParamsValue: 0,
			clvIsTabMenu1Checked: false,
			clvIsTabMenu2Checked: false,
			clvIsTabMenu3Checked: false,
			clvIsTabMenu4Checked: false,
			clvIsTabMenu5Checked: false,
			clvIsPortletLoading: false,
			clvIsLoading: true
		}),
		watch: {
			$route(to) {
				this.initTabs(to.query.tab);
				if (to.path == '/account/clover_params' && !Object.keys(to.query).length) {
					this.getCloverParamsData();
				}
			}
		},
		methods: {
			isReady: function() {
				if (this.$store.state.clvIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.clvAccountData.group == 1) {
					this.getCloverParamsData();
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			initTabs: function(tab) {
				this.clvIsTabMenu1Checked = false;
				this.clvIsTabMenu2Checked = false;
				this.clvIsTabMenu3Checked = false;
				this.clvIsTabMenu4Checked = false;
				this.clvIsTabMenu5Checked = false;
				this.clvCloverParamsTab = this.clvCloverParamsTabs[(([1,2,3,4,5].includes(tab) ? tab : 1) - 1)];
				if      (tab == 2) { this.clvIsTabMenu2Checked = true; this.clvCloverParamsStrategyId = 1; this.clvCloverParamsIsFullAvg = 1; }
				else if (tab == 3) { this.clvIsTabMenu3Checked = true; this.clvCloverParamsStrategyId = 2; this.clvCloverParamsIsFullAvg = 0; }
				else if (tab == 4) { this.clvIsTabMenu4Checked = true; this.clvCloverParamsStrategyId = 2; this.clvCloverParamsIsFullAvg = 1; }
				else if (tab == 5) { this.clvIsTabMenu5Checked = true; this.clvCloverParamsStrategyId = 3; this.clvCloverParamsIsFullAvg = 0; }
				else               { this.clvIsTabMenu1Checked = true; this.clvCloverParamsStrategyId = 1; this.clvCloverParamsIsFullAvg = 0; }
			},
			selectTab: function(tab) {
				if (this.clvCloverParamsStrategyId !== Math.ceil(tab / 2) || this.clvCloverParamsIsFullAvg !== ((tab - 1) % 2)) {
					this.$router.push({ path: '/account/clover_params', query: { tab: tab }});
					this.clvCloverParamsTab = this.clvCloverParamsTabs[(tab - 1)];
					this.clvCloverParamsStrategyId = Math.ceil(tab / 2);
					this.clvCloverParamsIsFullAvg = ((tab - 1) % 2);
					this.getCloverParamsData();
				}
			},
			selectSTab: function(event) {
				this.selectTab(Number(event.code));
			},
			getCloverParamsData: function() {
				window.scrollTo(0, 0);
				this.clvCloverParamsData = [];
				this.clvCloverParamsDataLimitOffset = 0;
				this.clvIsActiveCloverParamsValue = this.$store.state.clvSystemSettings['is_active_clover_params_' + this.clvCloverParamsTab.code];
				this.axios('getCloverParamsData');
			},
			axios: function(type, var1, var2, var3) {

				var _this = this;
				if (localStorage.getItem('clvToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.clvApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('clvToken') }
					};

					if (type == 'getCloverParamsData') {
						if (!_this.clvCloverParamsDataLimitOffset) {
							_this.clvIsLoading = true;
							_this.$store.state.clvIsAccount = true;
						}
						_this.clvIsPortletLoading = true;
						config.url = '/v2/admin/account/clover_params';
						config.params = { strategy: _this.clvCloverParamsStrategyId, is_full_avg: _this.clvCloverParamsIsFullAvg, limit_offset: _this.clvCloverParamsDataLimitOffset, limit_count: _this.clvCloverParamsDataLimitCount };
					}
					else if (type == 'editCloverParameterIsTest') {
						_this.clvCloverParamsData[var2].is_test = var3;
						config.url = '/v2/admin/account/clover_params/is_test';
						config.data = { id: var1, is_test: var3 };
						config.method = 'patch';
					}
					else if (type == 'editCloverParameterIsActive') {
						_this.clvCloverParamsData[var2].is_active = var3;
						config.url = '/v2/admin/account/clover_params/is_active';
						config.data = { id: var1, is_active: var3 };
						config.method = 'patch';
					}
					else if (type == 'editCloverParamsIsActive') {
						_this.$set(_this.$store.state.clvSystemSettings, 'is_active_clover_params_' + var1, var2);
						_this.clvIsActiveCloverParamsData['is_active_clover_params_' + var1] = var2;
						_this.clvIsActiveCloverParamsValue = var2;
						config.url = '/v2/admin/account/settings';
						config.data = { data: _this.clvIsActiveCloverParamsData };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getCloverParamsData') {
								_this.$parent.$parent.goToTop(!_this.clvCloverParamsDataLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.data.length) {
									for (var i in data.data.data) {
										_this.clvCloverParamsData.push(data.data.data[i]);
									}
								}
								_this.clvCloverParamsDataLimitOffset = (data.data.count > _this.clvCloverParamsDataLimitOffset + _this.clvCloverParamsDataLimitCount ? _this.clvCloverParamsDataLimitOffset + _this.clvCloverParamsDataLimitCount : 0);
							}
							else if (type == 'editCloverParamsIsActive') {
								_this.clvIsActiveCloverParamsData = {};
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.clvIsLoading = false;
						_this.clvIsPortletLoading = false;
						_this.$store.state.clvIsAccount = false;
						_this.$store.state.clvIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChangeForIsTest: function(id, index, event) {
				this.axios('editCloverParameterIsTest', id, index, Number(event.value));
			},
			toggleChangeForIsActive: function(id, index, event) {
				this.axios('editCloverParameterIsActive', id, index, Number(event.value));
			},
			toggleChangeForCloverParamsIsActive: function(type, event) {
				this.axios('editCloverParamsIsActive', type, Number(event.value));
			}
		},
		components: {
			ClvModalCloverParams: () => import('@/views/modal_components/ClvModalCloverParams.vue')
		},
		created: function() {
			this.initTabs(this.$route.query.tab);
			this.isReady();
		}
	}
</script>

<style>
	.clv_account_desktop_clover_params tr th,
	.clv_account_desktop_clover_params tr td {
		word-break: break-all;
		word-break: break-word;
		width: 20%;
	}
	.clv_account_desktop_clover_params tr th:nth-child(1),
	.clv_account_desktop_clover_params tr td:nth-child(1),
	.clv_account_desktop_clover_params tr th:nth-child(7),
	.clv_account_desktop_clover_params tr td:nth-child(7),
	.clv_account_desktop_clover_params tr th:nth-child(8),
	.clv_account_desktop_clover_params tr td:nth-child(8),
	.clv_account_desktop_clover_params tr th:nth-child(9),
	.clv_account_desktop_clover_params tr td:nth-child(9),
	.clv_account_desktop_clover_params tr th:nth-child(10),
	.clv_account_desktop_clover_params tr td:nth-child(10) {
		min-width: 45px;
		width: 1%;
	}
	.clv_account_desktop_clover_params_status,
	.clv_account_desktop_clover_params_api_link {
		margin: 0 0 20px 0;
		word-break: break-all;
		line-height: 22px;
		display: table;
		width: 100%;
	}
	.clv_account_desktop_clover_params_status {
		margin: 0 0 2px 0;
	}
	.clv_account_desktop_clover_params_status b,
	.clv_account_desktop_clover_params_api_link b {
		float: left;
		min-width: 60px;
	}
	.clv_account_desktop_clover_params_status .clv_toggle_button {
		margin: 0;
	}

	@media (max-width: 1100px) {
		.clv_account_desktop_clover_params .clv_tabs_mobile {
			display: table;
		}
		.clv_account_desktop_clover_params .clv_tab_menu_label {
			display: none;
		}
		.clv_account_desktop_clover_params .clv_tabs .clv_account_desktop_portlet {
			border-radius: 15px;
		}
	}
</style>
