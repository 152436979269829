import Vue from 'vue'
import Router from 'vue-router'
import ClvLogin from '@/views/oauth_components/ClvLogin'
import ClvAccount from '@/views/account_components/ClvAccount'
import ClvAccountNews from '@/views/account_components/ClvAccountNews'
import ClvAccountVoting from '@/views/account_components/ClvAccountVoting'
import ClvAccountSettings from '@/views/account_components/ClvAccountSettings'
import ClvAccountTransliteration from '@/views/admin_components/ClvAccountTransliteration'
import ClvAccountSystemSettings from '@/views/admin_components/ClvAccountSystemSettings'
import ClvAccountSymbolSettings from '@/views/admin_components/ClvAccountSymbolSettings'
import ClvAccountCloverParams from '@/views/admin_components/ClvAccountCloverParams'
import ClvAccountUsers from '@/views/admin_components/ClvAccountUsers'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      name: 'Clv404',
      component: ClvLogin
    },
    {
      path: '/',
      name: 'ClvLogin',
      component: ClvLogin
    },
    {
      path: '/account',
      component: ClvAccount,
      children: [
        { path: '', component: ClvAccountVoting },
        { path: 'news', component: ClvAccountNews },
        { path: 'settings', component: ClvAccountSettings },
        { path: 'transliteration', component: ClvAccountTransliteration },
        { path: 'transliteration/:key', component: ClvAccountTransliteration },
        { path: 'system_settings', component: ClvAccountSystemSettings },
        { path: 'symbol_settings', component: ClvAccountSymbolSettings },
        { path: 'clover_params', component: ClvAccountCloverParams },
        { path: 'users', component: ClvAccountUsers },
        { path: ':pathMatch(.*)*', component: ClvAccountVoting }
      ]
    }
  ]
})
