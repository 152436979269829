<template>
	<div class="clv_account_desktop_page">
		<div class="clv_account_desktop_page_loading" v-if="clvIsLoading"><div class="clv_loading"></div></div>
		<div class="clv_account_desktop_page_content" v-else>
			<div class="clv_account_desktop_transliteration">
				<div class="clv_account_desktop_transliteration_navigation">
					<div class="clv_account_desktop_transliteration_navigation_filters">
						<div class="clv_account_desktop_transliteration_navigation_filters_label" v-html="$store.getters.getLanguageText('1.8', 3)"></div>
						<div class="clv_account_desktop_transliteration_navigation_filters_select"><v-select class="clv_select style-chooser" v-model.trim="clvTransliterationFilter" :placeholder="$store.getters.getLanguageText('1.8', 2)" :options="clvTransliterationFilterOptions" @input="getTransliterationDataByFilter"></v-select></div>
						<div class="clv_account_desktop_transliteration_navigation_filters_reset_cache">
							<div :class="{ hide: !clvIsTransliterationFilterLoading }"><img src="@/assets/images/loading.gif"></div>
							<div :class="{ hide: clvIsTransliterationFilterLoading }"><button type="button" class="clv_button" @click="axios('resetTransliteration')" v-html="$store.getters.getLanguageText('1.1', 18)"></button></div>
						</div>
					</div>
					<div class="clv_account_desktop_page_content_navigation_search">
						<input type="text" class="clv_input" v-model.number="clvTransliterationSearch" @keyup.13="getTransliterationDataBySearch" :placeholder="$store.getters.getLanguageText('1.8.1', 0)" autocomplete="off">
						<div class="clv_account_desktop_page_content_navigation_search_button" @click="getTransliterationDataBySearch"><font-awesome-icon :icon="['fas', 'search']" /></div>
						<div class="clv_account_desktop_page_content_navigation_search_result" v-show="clvTransliterationSearchResult.length || clvIsTransliterationSearchLoading">
							<div class="clv_account_desktop_page_content_navigation_search_result_block">
								<div class="clv_account_desktop_page_content_navigation_search_result_loading" :class="{ hide: !clvIsTransliterationSearchLoading }"><span v-html="$store.getters.getLanguageText('1.8', 0)"></span>&#160;<img src="@/assets/images/loading.gif"></div>
								<div class="clv_account_desktop_page_content_navigation_search_result_content" :class="{ hide: clvIsTransliterationSearchLoading }">
									<span v-if="clvTransliterationSearchResult[0] && clvTransliterationSearchResult[0].length">
										<span class="clv_account_desktop_page_content_navigation_search_result_content_link" v-if="clvTransliterationSearchResult[0].length == 1" @click="getTransliterationData(clvTransliterationSearchResult[0][0].key)" v-html="$store.getters.getLanguageText('1.8.1', 2, { type: clvTransliterationSearchResult[0][0].type })"></span>
										<span class="clv_account_desktop_page_content_navigation_search_result_content_links" v-else>
											<span v-html="$store.getters.getLanguageText('1.8.1', 3)"></span>
											<span v-for="(value, index) in clvTransliterationSearchResult[0]" :key="index">
												<span>{{ (index ? ',' : '') }} </span>
												<span class="clv_account_desktop_page_content_navigation_search_result_content_link" @click="getTransliterationData(value.key)">{{ value.type }}</span>
											</span>
										</span>
									</span>
									<span v-else v-html="$store.getters.getLanguageText('1.8.1', 1)"></span>
								</div>
							</div>
						</div>
						<div class="clv_account_desktop_page_content_navigation_search_background" :class="{ hide: !clvTransliterationSearchResult.length && !clvIsTransliterationSearchLoading }" @click="closeTransliterationDataSearch"></div>
					</div>
				</div>
				<div class="clv_account_desktop_transliteration_statistics">
					<div class="clv_account_desktop_portlet">
						<div class="clv_account_desktop_portlet_title">Статистика</div>
						<div class="clv_account_desktop_portlet_content">
							<div class="clv_account_desktop_transliteration_statistics_row"><b>Русских символов в файле</b>:<span>{{ clvTransliterationStatisticsForLanguage.count_ru_characters }}</span></div>
							<div class="clv_account_desktop_transliteration_statistics_row"><b>Русских символов в базе данных</b>:<span>{{ clvTransliterationStatisticsForDatabase.count_ru_characters }}</span></div>
							<div class="clv_account_desktop_transliteration_statistics_row"><b>Русских символов всего</b>:<span>{{ (clvTransliterationStatisticsForLanguage.count_ru_characters + clvTransliterationStatisticsForDatabase.count_ru_characters) }}</span></div>
							<div class="clv_account_desktop_transliteration_statistics_row"><b>Английских символов в файле</b>:<span>{{ clvTransliterationStatisticsForLanguage.count_en_characters }}</span></div>
							<div class="clv_account_desktop_transliteration_statistics_row"><b>Английских символов в базе данных</b>:<span>{{ clvTransliterationStatisticsForDatabase.count_en_characters }}</span></div>
							<div class="clv_account_desktop_transliteration_statistics_row"><b>Английских символов всего</b>:<span>{{ (clvTransliterationStatisticsForLanguage.count_en_characters + clvTransliterationStatisticsForDatabase.count_en_characters) }}</span></div>
							<div class="clv_account_desktop_transliteration_statistics_row"><b>Символов переведено</b>:<span>{{ ((clvTransliterationStatisticsForLanguage.count_en_characters - clvTransliterationStatisticsForLanguage.count_ru_in_en_characters) + (clvTransliterationStatisticsForDatabase.count_en_characters - clvTransliterationStatisticsForDatabase.count_ru_in_en_characters)) }} из {{ (clvTransliterationStatisticsForLanguage.count_en_characters + clvTransliterationStatisticsForDatabase.count_en_characters) }} ({{ (100 - ((clvTransliterationStatisticsForLanguage.count_en_characters + clvTransliterationStatisticsForDatabase.count_en_characters) > 0 ? ((clvTransliterationStatisticsForLanguage.count_ru_in_en_characters + clvTransliterationStatisticsForDatabase.count_ru_in_en_characters) * 100 / (clvTransliterationStatisticsForLanguage.count_en_characters + clvTransliterationStatisticsForDatabase.count_en_characters)) : 0)).toFixed(2) }}%)</span></div>
						</div>
					</div>
				</div>
				<div class="clv_account_desktop_portlet">
					<div class="clv_account_desktop_portlet_title">Транслитерация (из файла)</div>
					<div class="clv_account_desktop_portlet_content">
						<table class="clv_table" v-if="clvTransliterationDataFromLanguage.length">
							<thead>
								<tr>
									<th>Индекс</th>
									<th>RU</th>
									<th>{{ getCountAllCharacters(clvTransliterationDataFromLanguage, 'ru') }}</th>
									<th>EN</th>
									<th>{{ getCountAllCharacters(clvTransliterationDataFromLanguage, 'en') }}</th>
									<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in clvTransliterationDataFromLanguage" :key="index">
									<td data-label="Индекс:">{{ index }}</td>
									<td data-label="RU:" v-html="value.ru"></td>
									<td data-label="Количество символов RU:">{{ getCountCharacters(value.ru) }}</td>
									<td data-label="EN:" v-html="value.en" :class="{ bg_red: isRUCharacters(value.en) }"></td>
									<td data-label="Количество символов EN:">{{ getCountCharacters(value.en) }}</td>
									<td data-label="Изменить:"><button type="button" class="clv_button icon blue" @click="$modal.show('ClvModalTransliteration', { type: 'editTransliteration', uid: 1, index: index })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
								</tr>
							</tbody>
						</table>
						<div v-else>Данных нет!</div>
					</div>
					<div class="clv_account_desktop_portlet_buttons">
						<button type="button" class="clv_button big green" @click="$modal.show('ClvModalTransliteration', { type: 'addTransliteration', uid: 1 })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить переменную</button>
					</div>
				</div>
				<div class="clv_account_desktop_portlet">
					<div class="clv_account_desktop_portlet_title">Транслитерация (из базы данных)</div>
					<div class="clv_account_desktop_portlet_content">
						<table class="clv_table" v-if="clvTransliterationDataFromDatabase.length">
							<thead>
								<tr>
									<th>Индекс</th>
									<th>RU</th>
									<th>{{ getCountAllCharacters(clvTransliterationDataFromDatabase, 'ru') }}</th>
									<th>EN</th>
									<th>{{ getCountAllCharacters(clvTransliterationDataFromDatabase, 'en') }}</th>
									<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in clvTransliterationDataFromDatabase" :key="index">
									<td data-label="Индекс:">{{ index }}</td>
									<td data-label="RU:" v-html="value.ru"></td>
									<td data-label="Количество символов RU:">{{ getCountCharacters(value.ru) }}</td>
									<td data-label="EN:" v-html="value.en" :class="{ bg_red: isRUCharacters(value.en) }"></td>
									<td data-label="Количество символов EN:">{{ getCountCharacters(value.en) }}</td>
									<td data-label="Изменить:"><button type="button" class="clv_button icon blue" @click="$modal.show('ClvModalTransliteration', { type: 'editTransliteration', uid: 2, index: index })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
								</tr>
							</tbody>
						</table>
						<div v-else>Данных нет!</div>
					</div>
					<div class="clv_account_desktop_portlet_buttons">
						<button type="button" class="clv_button big green" @click="$modal.show('ClvModalTransliteration', { type: 'addTransliteration', uid: 2 })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить переменную</button>
					</div>
				</div>
			</div>
			<div class="clv_account_desktop_modals">
				<div is="ClvModalTransliteration"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			clvTransliterationSearch: '',
			clvTransliterationSearchResult: [],
			clvTransliterationFilter: '',
			clvTransliterationFilterOptions: [
				{ code: '1', label: '1. Сайт' },
				{ code: '1.1', label: '..... 1.1. Кнопки' },
				{ code: '1.2', label: '..... 1.2. Подсказки' },
				{ code: '1.3', label: '..... 1.3. Оповещения' },
				{ code: '1.3.1', label: '.......... 1.3.1. ... для аккаунта' },
				{ code: '1.3.2', label: '.......... 1.3.2. ... для голосований CLV' },
				{ code: '1.4', label: '..... 1.4. Сокращения' },
				{ code: '1.5', label: '..... 1.5. Списки данных' },
				{ code: '1.5.1', label: '.......... 1.5.1. ... для стран мира' },
				{ code: '1.6', label: '..... 1.6. Графики' },
				{ code: '1.7', label: '..... 1.7. Таблицы' },
				{ code: '1.7.1', label: '.......... 1.7.1. Заголовки таблиц' },
				{ code: '1.7.2', label: '.......... 1.7.2. Статусы и типы таблиц' },
				{ code: '1.8', label: '..... 1.8. Фильтры, сортировка и поиск' },
				{ code: '1.8.1', label: '.......... 1.8.1. ... для транслитерации' },
				{ code: '1.8.2', label: '.......... 1.8.2. ... для новостей' },
				{ code: '1.9', label: '..... 1.9. Склонения слов' },
				{ code: '2', label: '2. Аккаунт' },
				{ code: '3', label: '3. Сервисы' },
				{ code: '3.1', label: '..... 3.1. CLV' },
				{ code: '3.1.1', label: '.......... 3.1.1. Справка' },
				{ code: '3.1.2', label: '.......... 3.1.2. Валютные пары' },
				{ code: '3.1.3', label: '.......... 3.1.3. История голосований' },
				{ code: '3.2', label: '..... 3.2. Настройки' },
				{ code: '3.3', label: '..... 3.3. Новости' },
				{ code: '4', label: '4. Виджеты' },
				{ code: '5', label: '5. Модальные окна' },
				{ code: '5.1', label: '..... 5.1. ClvModalCurrencyPairs' },
				{ code: '6', label: '6. E-mail сообщения' },
				{ code: '7', label: '7. Telegram сообщения' }
			],
			clvTransliterationDataFromLanguage: [],
			clvTransliterationDataFromDatabase: [],
			clvTransliterationStatisticsForLanguage: {},
			clvTransliterationStatisticsForDatabase: {},
			clvIsTransliterationSearchLoading: false,
			clvIsTransliterationFilterLoading: false,
			clvIsTransliterationFilterReInit: false,
			clvIsLoading: true
		}),
		watch: {
			$route(to) {
				if (to.path == '/account/transliteration' && !Object.keys(to.query).length) {
					if (this.clvIsTransliterationFilterReInit) {
						this.clvTransliterationFilter = this.clvTransliterationFilterOptions[0];
					}
					this.getTransliterationData();
				}
			}
		},
		methods: {
			isReady: function() {
				if (this.$store.state.clvIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.clvAccountData.group == 1) {
					this.clvTransliterationFilter = this.clvTransliterationFilterOptions[0];
					this.getTransliterationData(this.$route.params.key);
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			isRUCharacters: function(text) {
				return this.$store.state.clvRegexSearchRULetters.test(text) ? true : false;
			},
			getCountCharacters: function(text) {
				return text.replace(/({{ \w+ }})|(<[^>]+>)/gi, '').length;
			},
			getCountAllCharacters: function(data, language) {
				var count = 0;
				for (var i in data) {
					count += this.getCountCharacters(data[i][language]);
				}
				return count;
			},
			getTransliterationData: function(key) {
				window.scrollTo(0, 0);
				this.clvTransliterationDataFromLanguage = [];
				this.clvTransliterationDataFromDatabase = [];
				this.clvIsTransliterationFilterReInit = false;
				this.closeTransliterationDataSearch();
				this.axios('getTransliterationData', key);
			},
			getTransliterationDataByFilter: function() {
				this.getTransliterationData();
			},
			getTransliterationDataBySearch: function() {
				this.clvTransliterationSearchResult.splice(0, 1);
				if (this.$store.state.clvRegexENRULettersAndNumbersAndMarks.test(this.clvTransliterationSearch)) {
					this.axios('getTransliterationDataBySearch');
				}
			},
			closeTransliterationDataSearch: function() {
				this.clvTransliterationSearch = '';
				this.clvTransliterationSearchResult.splice(0, 1);
			},
			axios: function(type, var1) {

				var _this = this;
				if (localStorage.getItem('clvToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.clvApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('clvToken') }
					};

					if (type == 'getTransliterationData') {
						_this.clvIsLoading = true;
						_this.$store.state.clvIsAccount = true;
						if (var1 !== undefined) {
							config.url = '/v2/admin/account/transliteration/' + var1;
						}
						else {
							config.url = '/v2/admin/account/transliteration';
							config.params = { type: _this.clvTransliterationFilter.code };
						}
					}
					else if (type == 'getTransliterationDataBySearch') {
						_this.clvIsTransliterationSearchLoading = true;
						config.url = '/v2/admin/account/transliteration/search';
						config.params = { search_value: _this.clvTransliterationSearch };
					}
					else if (type == 'resetTransliteration') {
						_this.clvIsTransliterationFilterLoading = true;
						config.url = '/v2/admin/account/transliteration/reset';
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getTransliterationData') {
								_this.$parent.$parent.goToTop(true);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.transliteration_data_from_language !== undefined) {
									for (var i in data.data.transliteration_data_from_language.ru) {
										_this.clvTransliterationDataFromLanguage.push({ ru: data.data.transliteration_data_from_language.ru[i], en: data.data.transliteration_data_from_language.en[i] });
									}
								}
								if (data.data.transliteration_data_from_database !== undefined) {
									for (var j in data.data.transliteration_data_from_database.ru) {
										_this.clvTransliterationDataFromDatabase.push({ ru: data.data.transliteration_data_from_database.ru[j], en: data.data.transliteration_data_from_database.en[j] });
									}
								}
								if (data.data.transliteration_statistics_for_language !== undefined) {
									_this.clvTransliterationStatisticsForLanguage = data.data.transliteration_statistics_for_language;
								}
								if (data.data.transliteration_statistics_for_database !== undefined) {
									_this.clvTransliterationStatisticsForDatabase = data.data.transliteration_statistics_for_database;
								}
								if (var1 !== undefined) {
									_this.clvIsTransliterationFilterReInit = true;
									if (_this.$route.path !== '/account/transliteration/' + var1) {
										_this.$router.push({ path: '/account/transliteration/' + var1 });
									}
									if (data.data.transliteration_type !== undefined) {
										var codes = _this.clvTransliterationFilterOptions.map(a => a.code);
										var index = codes.indexOf(String(data.data.transliteration_type));
										_this.clvTransliterationFilter = _this.clvTransliterationFilterOptions[(index >= 0 ? index : 0)];
									}
								}
								else {
									if (_this.$route.path !== '/account/transliteration') {
										_this.$router.push({ path: '/account/transliteration' });
									}
								}
							}
							else if (type == 'getTransliterationDataBySearch') {
								_this.clvTransliterationSearchResult.splice(0, 1, data.data);
							}
						}
					}).catch(function() {
						if (type == 'getTransliterationDataBySearch') {
							_this.clvTransliterationSearchResult.splice(0, 1);
						}
						else {
							_this.$parent.$parent.exit();
						}
					}).finally(function() {
						_this.clvIsLoading = false;
						_this.clvIsTransliterationSearchLoading = false;
						_this.clvIsTransliterationFilterLoading = false;
						_this.$store.state.clvIsAccount = false;
						_this.$store.state.clvIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		components: {
			ClvModalTransliteration: () => import('@/views/modal_components/ClvModalTransliteration.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.clv_account_desktop_transliteration_navigation {
		margin: 0 0 15px 0;
		display: table;
		width: 100%;
	}
	.clv_account_desktop_transliteration_navigation_filters {
		position: relative;
		padding: 0 127px 0 92px;
		display: table;
		width: 100%;
	}
	.clv_account_desktop_transliteration_navigation_filters_label {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 15px;
		border-radius: 15px 2px 2px 15px;
		background: #333333B3;
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 30px;
		width: 90px;
		height: 30px;
	}
	.clv_account_desktop_transliteration_navigation_filters_select {
		padding: 0 15px;
		border-radius: 2px;
		background: #111111B3;
		width: 100%;
		height: 30px;
	}
	.clv_account_desktop_transliteration_navigation_filters_select .clv_select .vs__dropdown-toggle {
		padding: 6px 0 7px 0;
		border: none;
	}
	.clv_account_desktop_transliteration_navigation_filters_reset_cache {
		position: absolute;
		top: 0;
		right: 0;
		border-radius: 2px 15px 15px 2px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		background: #008000B3;
		text-align: center;
		line-height: 30px;
		width: 125px;
		height: 30px;
	}
	.clv_account_desktop_transliteration_navigation_filters_reset_cache img {
		margin: -3px 0 0 0;
	}
	.clv_account_desktop_transliteration_navigation_filters_reset_cache button {
		font-size: 12px;
	}
	.clv_account_desktop_transliteration_statistics_row span {
		margin: 0 0 0 8px;
	}
	.clv_account_desktop_transliteration tr th:nth-child(2),
	.clv_account_desktop_transliteration tr td:nth-child(2),
	.clv_account_desktop_transliteration tr th:nth-child(4),
	.clv_account_desktop_transliteration tr td:nth-child(4) {
		width: 50%;
	}

	@media (max-width: 1000px) {
		.clv_account_desktop_transliteration_navigation {
			margin: 0 0 10px 0;
		}
	}
	@media (max-width: 650px) {
		.clv_account_desktop_transliteration_navigation_filters {
			padding: 0;
		}
		.clv_account_desktop_transliteration_navigation_filters_label {
			position: relative;
			border-radius: 15px 15px 2px 2px;
			width: 100%;
		}
		.clv_account_desktop_transliteration_navigation_filters_select {
			margin: 2px 0;
		}
		.clv_account_desktop_transliteration_navigation_filters_reset_cache {
			position: relative;
			border-radius: 2px 2px 15px 15px;
			width: 100%;
		}
	}
</style>
