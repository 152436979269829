<template>
	<div class="clv_account_desktop_page">
		<div class="clv_account_desktop_page_loading" v-if="clvIsLoading"><div class="clv_loading"></div></div>
		<div class="clv_account_desktop_page_content" v-else>
			<div class="clv_account_desktop_portlet">
				<div class="clv_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.1.1', 0)"></div>
				<div class="clv_account_desktop_portlet_content" v-html="$store.getters.getLanguageText('3.1.1', 1)"></div>
				<div class="clv_account_desktop_portlet_buttons">
					<button type="button" class="clv_button big green" @click="$modal.show('ClvModalCurrencyPairs', { type: 'getCLVHelp' })"><font-awesome-icon :icon="['fas', 'file-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 16)"></span></button>
				</div>
			</div>
			<div class="clv_account_desktop_portlet">
				<div class="clv_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.1.2', 0)"></div>
				<div class="clv_account_desktop_portlet_content">
					<div class="clv_account_desktop_currency_pairs">
						<div class="clv_account_desktop_currency_pairs_alert" v-if="$store.state.clvAccountData.group == 1 && !clvIsTraderFound" v-html="$store.getters.getLanguageText('3.1.2', 12)"></div>
						<div class="clv_account_desktop_currency_pairs_alert" v-if="!$store.state.clvAccountData.is_voting" v-html="$store.getters.getLanguageText('3.1.2', 13)"></div>
						<table class="clv_table" v-if="Object.keys(clvCurrencyPairs).length">
							<thead>
								<tr>
									<th><font-awesome-icon :icon="['fas', 'check']" /></th>
									<th></th>
									<th><font-awesome-icon :icon="['fas', 'asterisk']" /></th>
									<th><font-awesome-icon :icon="['fas', 'exclamation-circle']" /></th>
									<th><font-awesome-icon :icon="['fas', 'plus']" /></th>
									<th><font-awesome-icon :icon="['fas', 'times']" /></th>
									<th></th>
									<th><span v-html="$store.getters.getLanguageText('3.1.2', 1)"></span><b>|</b><span v-html="$store.getters.getLanguageText('3.1.2', 2)"></span></th>
									<th></th>
									<th><font-awesome-icon :icon="['fas', 'times']" /></th>
									<th><font-awesome-icon :icon="['fas', 'plus']" /></th>
								</tr>
							</thead>
							<tbody v-if="$store.state.clvAccountData.group == 1">
								<tr class="not_selected_admin" v-for="(value, index) in clvCurrencyPairs" :key="index">
									<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'"><toggle-button class="clv_toggle_button" :value="currencyPairsToggleValue(value.is_selected)" :sync="true" :width="40" :height="25" :color="{ checked: '#00FF0080' }" @change="currencyPairsToggleChange(1, index, $event)"></toggle-button></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'"><span>{{ value.index }}</span> [<span class="orange">{{ value.avg_volatility }}</span>/<span class="red">{{ value.max_volatility }}</span>]</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 2) + ':'" :class="{ active: clvCurrencyPairsSettings[0] }"><v-select class="clv_select style-chooser" v-model.trim="value.multiplier" :placeholder="$store.getters.getLanguageText('3.1.2', 3)" :options="['0.1','0.2','0.3','0.4','0.5','0.6','0.7','0.8','0.9','1.0']" @input="currencyPairsSelectChange(1, index, $event)"></v-select></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 3) + ':'" :class="{ active: clvCurrencyPairsSettings[1] }"><v-select class="clv_select style-chooser" v-model.trim="value.trend_status" :placeholder="$store.getters.getLanguageText('3.1.2', 4)" :options="clvCurrencyPairsTrendStatuses" @input="currencyPairsSelectChange(2, index, $event)"></v-select></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 4) + ':'" :class="{ active: clvCurrencyPairsSettings[2] }"><toggle-button class="clv_toggle_button" :value="currencyPairsToggleValue(value.sell_trading)" :sync="true" :width="40" :height="25" :color="{ checked: '#00FF0080' }" @change="currencyPairsToggleChange(4, index, $event)"></toggle-button></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 5) + ':'" :class="{ active: clvCurrencyPairsSettings[3] }"><toggle-button class="clv_toggle_button" :value="currencyPairsToggleValue(value.sell_closing)" :sync="true" :width="40" :height="25" :color="{ checked: '#FF6666CC' }" @change="currencyPairsToggleChange(5, index, $event)"></toggle-button></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 6) + ':'" :class="[value.sell_value > 1 ? 'green' : (value.sell_value < 1 ? 'red' : '')]">{{ parseFloat(value.sell_value).toFixed(2) }}</td>
									<td data-label=""><vue-slider class="clv_range_slider" :min="0.50" :max="1.50" :interval="0.01" :tooltip-formatter="currencyPairsRangeFormatter" v-model="value.slider_value" @change="currencyPairsRangeChange(index, $event)" @drag-end="currencyPairsRangeDragEnd(index)"></vue-slider></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 7) + ':'" :class="[value.buy_value > 1 ? 'green' : (value.buy_value < 1 ? 'red' : '')]">{{ parseFloat(value.buy_value).toFixed(2) }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 8) + ':'" :class="{ active: clvCurrencyPairsSettings[3] }"><toggle-button class="clv_toggle_button" :value="currencyPairsToggleValue(value.buy_closing)" :sync="true" :width="40" :height="25" :color="{ checked: '#FF6666CC' }" @change="currencyPairsToggleChange(3, index, $event)"></toggle-button></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 9) + ':'" :class="{ active: clvCurrencyPairsSettings[2] }"><toggle-button class="clv_toggle_button" :value="currencyPairsToggleValue(value.buy_trading)" :sync="true" :width="40" :height="25" :color="{ checked: '#00FF0080' }" @change="currencyPairsToggleChange(2, index, $event)"></toggle-button></td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr :class="{ disabled: !value.is_active, not_selected: !value.is_selected }" v-for="(value, index) in clvCurrencyPairs" :key="index">
									<td :data-label="$store.getters.getLanguageText('1.7.1', 0) + ':'"><toggle-button class="clv_toggle_button" :value="currencyPairsToggleValue(value.is_selected)" :sync="true" :width="40" :height="25" :disabled="!value.is_active" :color="{ checked: '#00FF0080' }" @change="currencyPairsToggleChange(1, index, $event)"></toggle-button></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'"><span>{{ value.index }}</span> [<span class="orange">{{ value.avg_volatility }}</span>/<span class="red">{{ value.max_volatility }}</span>]</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 2) + ':'"><v-select class="clv_select style-chooser" v-model.trim="value.multiplier" :placeholder="$store.getters.getLanguageText('3.1.2', 3)" :options="['0.1','0.2','0.3','0.4','0.5','0.6','0.7','0.8','0.9','1.0']" @input="currencyPairsSelectChange(1, index, $event)"></v-select></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 3) + ':'"><v-select class="clv_select style-chooser" v-model.trim="value.trend_status" :placeholder="$store.getters.getLanguageText('3.1.2', 4)" :options="clvCurrencyPairsTrendStatuses" @input="currencyPairsSelectChange(2, index, $event)"></v-select></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 4) + ':'"><toggle-button class="clv_toggle_button" :value="currencyPairsToggleValue(value.sell_trading)" :sync="true" :width="40" :height="25" :disabled="!value.is_active" :color="{ checked: '#00FF0080' }" @change="currencyPairsToggleChange(4, index, $event)"></toggle-button></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 5) + ':'"><toggle-button class="clv_toggle_button" :value="currencyPairsToggleValue(value.sell_closing)" :sync="true" :width="40" :height="25" :disabled="!value.is_active" :color="{ checked: '#FF6666CC' }" @change="currencyPairsToggleChange(5, index, $event)"></toggle-button></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 6) + ':'" :class="[value.sell_value > 1 ? 'green' : (value.sell_value < 1 ? 'red' : '')]">{{ parseFloat(value.sell_value).toFixed(2) }}</td>
									<td data-label=""><vue-slider class="clv_range_slider" :min="0.50" :max="1.50" :interval="0.01" :tooltip-formatter="currencyPairsRangeFormatter" :disabled="!value.is_active" v-model="value.slider_value" @change="currencyPairsRangeChange(index, $event)" @drag-end="currencyPairsRangeDragEnd(index)"></vue-slider></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 7) + ':'" :class="[value.buy_value > 1 ? 'green' : (value.buy_value < 1 ? 'red' : '')]">{{ parseFloat(value.buy_value).toFixed(2) }}</td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 8) + ':'"><toggle-button class="clv_toggle_button" :value="currencyPairsToggleValue(value.buy_closing)" :sync="true" :width="40" :height="25" :disabled="!value.is_active" :color="{ checked: '#FF6666CC' }" @change="currencyPairsToggleChange(3, index, $event)"></toggle-button></td>
									<td :data-label="$store.getters.getLanguageText('1.7.1', 9) + ':'"><toggle-button class="clv_toggle_button" :value="currencyPairsToggleValue(value.buy_trading)" :sync="true" :width="40" :height="25" :disabled="!value.is_active" :color="{ checked: '#00FF0080' }" @change="currencyPairsToggleChange(2, index, $event)"></toggle-button></td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="clv_account_desktop_currency_pairs_last_save" :class="{ red: clvCountHoursAfterLastSave >= 16 }" v-if="$store.state.clvAccountData.group !== 1">
						<b v-html="$store.getters.getLanguageText('3.1.2', 5)"></b>
						<span v-if="clvCountHoursAfterLastSave >= 16" v-html="$store.getters.getLanguageText('3.1.2', 6)"></span>
						<span v-else-if="!clvCountHoursAfterLastSave && !clvCountMinutesAfterLastSave && clvCountSecondsAfterLastSave <= 10" v-html="$store.getters.getLanguageText('3.1.2', 10)"></span>
						<span v-else-if="!clvCountHoursAfterLastSave && !clvCountMinutesAfterLastSave && clvCountSecondsAfterLastSave > 10" v-html="$store.getters.getLanguageText('3.1.2', 9)"></span>
						<span v-else>
							<span v-if="clvCountHoursAfterLastSave > 0" v-html="$store.getters.getLanguageText('3.1.2', 7, { options: [$parent.$parent.getDeclension(clvCountHoursAfterLastSave)], value: clvCountHoursAfterLastSave })"></span>&#160;
							<span v-if="clvCountMinutesAfterLastSave > 0" v-html="$store.getters.getLanguageText('3.1.2', 8, { options: [$parent.$parent.getDeclension(clvCountMinutesAfterLastSave)], value: clvCountMinutesAfterLastSave })"></span>&#160;
							<span v-html="$store.getters.getLanguageText('3.1.2', 11)"></span>
						</span>
					</div>
				</div>
				<div class="clv_account_desktop_portlet_alert" v-html="clvPortletAlert1"></div>
				<div class="clv_account_desktop_portlet_loading" :class="{ hide: !clvIsPortletLoading1 }"><img src="@/assets/images/loading.gif"></div>
				<div class="clv_account_desktop_portlet_buttons" :class="{ hide: clvIsPortletLoading1 }">
					<button type="button" class="clv_button big red" @click="$modal.show('ClvModalCurrencyPairs', { type: 'stopVoting' })" v-if="!$store.state.clvAccountData.group && $store.state.clvAccountData.is_voting"><font-awesome-icon :icon="['fas', 'sync-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 19)"></span></button>
					<button type="button" class="clv_button big green" @click="axios('saveCurrencyPairs')"><font-awesome-icon :icon="['fas', 'save']" /><span v-html="$store.getters.getLanguageText('1.1', 7)"></span></button>
				</div>
			</div>
			<div class="clv_account_desktop_portlet" v-if="$store.state.clvAccountData.group == 1">
				<div class="clv_account_desktop_portlet_title">Дополнительные настройки</div>
				<div class="clv_account_desktop_portlet_content">
					<div class="clv_account_desktop_currency_pairs_settings">
						<div class="clv_account_desktop_currency_pairs_settings_row">
							<div class="clv_account_desktop_currency_pairs_settings_row_label">
								<toggle-button class="clv_toggle_button" :value="currencyPairsToggleValue(clvCurrencyPairsSettings[0])" :sync="true" :width="40" :height="25" :color="{ checked: '#00FF0080' }" @change="currencyPairsToggleChange(6, 0, $event)"></toggle-button>
							</div>
							<div class="clv_account_desktop_currency_pairs_settings_row_value">игнорировать решения трейдеров по уровню доверия к символам</div>
						</div>
						<div class="clv_account_desktop_currency_pairs_settings_row">
							<div class="clv_account_desktop_currency_pairs_settings_row_label">
								<toggle-button class="clv_toggle_button" :value="currencyPairsToggleValue(clvCurrencyPairsSettings[1])" :sync="true" :width="40" :height="25" :color="{ checked: '#00FF0080' }" @change="currencyPairsToggleChange(6, 1, $event)"></toggle-button>
							</div>
							<div class="clv_account_desktop_currency_pairs_settings_row_value">игнорировать решения трейдеров по статусам тренда</div>
						</div>
						<div class="clv_account_desktop_currency_pairs_settings_row">
							<div class="clv_account_desktop_currency_pairs_settings_row_label">
								<toggle-button class="clv_toggle_button" :value="currencyPairsToggleValue(clvCurrencyPairsSettings[2])" :sync="true" :width="40" :height="25" :color="{ checked: '#00FF0080' }" @change="currencyPairsToggleChange(6, 2, $event)"></toggle-button>
							</div>
							<div class="clv_account_desktop_currency_pairs_settings_row_value">игнорировать решения трейдеров о торговых символах</div>
						</div>
						<div class="clv_account_desktop_currency_pairs_settings_row">
							<div class="clv_account_desktop_currency_pairs_settings_row_label">
								<toggle-button class="clv_toggle_button" :value="currencyPairsToggleValue(clvCurrencyPairsSettings[3])" :sync="true" :width="40" :height="25" :color="{ checked: '#00FF0080' }" @change="currencyPairsToggleChange(6, 3, $event)"></toggle-button>
							</div>
							<div class="clv_account_desktop_currency_pairs_settings_row_value">игнорировать решения трейдеров о просушках</div>
						</div>
					</div>
				</div>
				<div class="clv_account_desktop_portlet_alert" v-html="clvPortletAlert2"></div>
				<div class="clv_account_desktop_portlet_loading" :class="{ hide: !clvIsPortletLoading2 }"><img src="@/assets/images/loading.gif"></div>
				<div class="clv_account_desktop_portlet_buttons" :class="{ hide: clvIsPortletLoading2 }">
					<button type="button" class="clv_button big green" @click="axios('saveCurrencyPairsSettings')"><font-awesome-icon :icon="['fas', 'save']" /><span v-html="$store.getters.getLanguageText('1.1', 7)"></span></button>
				</div>
			</div>
			<div class="clv_account_desktop_portlet">
				<div class="clv_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.1.3', 0)"></div>
				<div class="clv_account_desktop_portlet_content">
					<div class="clv_account_desktop_currency_pairs_archive">
						<p v-html="$store.getters.getLanguageText('3.1.3', 1)"></p>
						<div class="clv_account_desktop_currency_pairs_archive_accordion" :class="{ is_admin: $store.state.clvAccountData.group == 1 }">
							<div v-if="Object.keys(clvCurrencyPairsArchive).length">
								<div class="clv_account_desktop_currency_pairs_archive_accordion_block" v-for="(value, name, index) in clvCurrencyPairsArchive" :key="index">
									<div class="clv_account_desktop_currency_pairs_archive_accordion_head" @click="currencyPairsToggleArchiveAccordion(index, clvCurrencyPairsArchiveIsShow[index])">
										<b v-html="$store.getters.getLanguageText('3.1.3', 2)"></b>
										<span v-html="$store.getters.getLanguageText('3.1.3', 3, { time: (new Date(name.replace(/(time|current)/g, '') * 1000).toLocaleString($store.state.clvLanguage)) })"></span>
										<span v-if="$store.state.clvAccountData.group == 1 && value[0].user_id"><b>CLV ID: {{ value[0].user_id }}</b></span>
										<font-awesome-icon :icon="['fas', 'angle-up']" v-if="clvCurrencyPairsArchiveIsShow[index]" />
										<font-awesome-icon :icon="['fas', 'angle-down']" v-else />
									</div>
									<div class="clv_account_desktop_currency_pairs_archive_accordion_body" :class="{ hide: !clvCurrencyPairsArchiveIsShow[index] }">
										<table class="clv_table">
											<thead>
												<tr>
													<th v-html="$store.getters.getLanguageText('1.7.1', 1)"></th>
													<th><font-awesome-icon :icon="['fas', 'asterisk']" /></th>
													<th><font-awesome-icon :icon="['fas', 'exclamation-circle']" /></th>
													<th><font-awesome-icon :icon="['fas', 'plus']" /></th>
													<th><font-awesome-icon :icon="['fas', 'times']" /></th>
													<th v-html="$store.getters.getLanguageText('1.7.1', 10)"></th>
													<th v-html="$store.getters.getLanguageText('1.7.1', 11)"></th>
													<th><font-awesome-icon :icon="['fas', 'times']" /></th>
													<th><font-awesome-icon :icon="['fas', 'plus']" /></th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(value2, index2) in value" :key="index2">
													<td :data-label="$store.getters.getLanguageText('1.7.1', 1) + ':'" class="uppercase"><b>{{ value2.index }}</b></td>
													<td :data-label="$store.getters.getLanguageText('1.7.1', 2) + ':'">{{ parseFloat(value2.multiplier).toFixed(1) }}</td>
													<td :data-label="$store.getters.getLanguageText('1.7.1', 3) + ':'">{{ ($store.state.clvAccountData.group == 1 ? parseFloat(value2.trend_status.code).toFixed(1) : value2.trend_status.label) }}</td>
													<td :data-label="$store.getters.getLanguageText('1.7.1', 4) + ':'" :class="[value2.sell_trading ? 'bg_green' : 'bg_red']">{{ value2.sell_trading }}</td>
													<td :data-label="$store.getters.getLanguageText('1.7.1', 5) + ':'" :class="[value2.sell_closing ? 'bg_green' : 'bg_red']">{{ value2.sell_closing }}</td>
													<td :data-label="$store.getters.getLanguageText('1.7.1', 10) + ':'" :class="[value2.sell_value > 1 ? 'green' : (value2.sell_value < 1 ? 'red' : '')]">{{ parseFloat(value2.sell_value).toFixed(2) }}</td>
													<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'" :class="[value2.buy_value > 1 ? 'green' : (value2.buy_value < 1 ? 'red' : '')]">{{ parseFloat(value2.buy_value).toFixed(2) }}</td>
													<td :data-label="$store.getters.getLanguageText('1.7.1', 8) + ':'" :class="[value2.buy_closing ? 'bg_green' : 'bg_red']">{{ value2.buy_closing }}</td>
													<td :data-label="$store.getters.getLanguageText('1.7.1', 9) + ':'" :class="[value2.buy_trading ? 'bg_green' : 'bg_red']">{{ value2.buy_trading }}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div v-else-if="clvIsShowCurrencyPairsArchive" v-html="$store.getters.getLanguageText('3.1.3', 4)"></div>
						</div>
					</div>
				</div>
				<div class="clv_account_desktop_portlet_alert" v-html="clvPortletAlert3"></div>
				<div class="clv_account_desktop_portlet_loading" :class="{ hide: !clvIsPortletLoading3 }"><img src="@/assets/images/loading.gif"></div>
				<div class="clv_account_desktop_portlet_buttons" :class="{ hide: clvIsPortletLoading3 }">
					<button type="button" class="clv_button big" @click="axios('getCurrencyPairsArchive')" v-if="clvCurrencyPairsArchiveLimitOffset"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 15)"></span></button>
					<button type="button" class="clv_button big" @click="closeCurrencyPairsArchive" v-if="clvIsShowCurrencyPairsArchive"><font-awesome-icon :icon="['fas', 'file-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 17)"></span></button>
					<button type="button" class="clv_button big green" @click="axios('getCurrencyPairsArchive')" v-else><font-awesome-icon :icon="['fas', 'file-alt']" /><span v-html="$store.getters.getLanguageText('1.1', 14)"></span></button>
				</div>
			</div>
			<div class="clv_account_desktop_modals">
				<div is="ClvModalCurrencyPairs"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			clvTemp: '',
			clvPortletAlert1: '',
			clvPortletAlert2: '',
			clvPortletAlert3: '',
			clvCurrencyPairs: [],
			clvCurrencyPairsTrendStatuses: [
				{ code: -1.0, label: '!R Trend Sell' },
				{ code: -0.5, label: 'Trend Sell' },
				{ code: 0.0, label: 'Flat' },
				{ code: 0.5, label: 'Trend Buy' },
				{ code: 1.0, label: '!R Trend Buy' }
			],
			clvCurrencyPairsAllTrendStatuses: [
				{ code: -1.0, label: '!R Trend Sell' },
				{ code: -0.9, label: '!R Trend Sell' },
				{ code: -0.8, label: '!R Trend Sell' },
				{ code: -0.7, label: '!R Trend Sell' },
				{ code: -0.6, label: 'Trend Sell' },
				{ code: -0.5, label: 'Trend Sell' },
				{ code: -0.4, label: 'Trend Sell' },
				{ code: -0.3, label: 'Trend Sell' },
				{ code: -0.2, label: 'Flat' },
				{ code: -0.1, label: 'Flat' },
				{ code: 0.0, label: 'Flat' },
				{ code: 0.1, label: 'Flat' },
				{ code: 0.2, label: 'Flat' },
				{ code: 0.3, label: 'Trend Buy' },
				{ code: 0.4, label: 'Trend Buy' },
				{ code: 0.5, label: 'Trend Buy' },
				{ code: 0.6, label: 'Trend Buy' },
				{ code: 0.7, label: '!R Trend Buy' },
				{ code: 0.8, label: '!R Trend Buy' },
				{ code: 0.9, label: '!R Trend Buy' },
				{ code: 1.0, label: '!R Trend Buy' }
			],
			clvCurrencyPairsSaveData: [],
			clvCurrencyPairsSaveComments: [],
			clvCurrencyPairsSettings: [],
			clvCurrencyPairsArchive: {},
			clvCurrencyPairsArchiveIsShow: [],
			clvCurrencyPairsArchiveLimitOffset: 0,
			clvCurrencyPairsArchiveLimitCount: 10,
			clvCurrencyPairsArchiveLastSaveTime: 0,
			clvCountSecondsAfterLastSave: 0,
			clvCountMinutesAfterLastSave: 0,
			clvCountHoursAfterLastSave: 0,
			clvIsTraderFound: false,
			clvIsShowCurrencyPairsArchive: false,
			clvIsPortletLoading1: false,
			clvIsPortletLoading2: false,
			clvIsPortletLoading3: false,
			clvIsSuccessAxios: false,
			clvIsLoading: true,
			clvIsError: false
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.clvIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				this.axios('getCurrencyPairs');
			},
			resetAlerts: function() {

				this.clvPortletAlert1 = '';
				this.clvPortletAlert2 = '';
				this.clvPortletAlert3 = '';
			},
			getError: function(i) {

				this.clvIsError = true;

				if      (i == 1) { this.clvTemp = this.$store.getters.getLanguageText('1.3.2', 0); }
				else if (i == 2) { this.clvTemp = this.$store.getters.getLanguageText('1.3.2', 1); }
				else             { this.clvTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.clvTemp + '</div>';
			},
			getCurrencyPairsAllTrendStatus: function(value) {
				return this.clvCurrencyPairsAllTrendStatuses.find(f => Number(f.code) == Number(value));
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('clvToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.clvApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('clvToken') }
					};

					_this.resetAlerts();
					_this.clvIsSuccessAxios = false;
					_this.clvIsError = false;

					if (type == 'getCurrencyPairs') {
						_this.clvIsLoading = true;
						_this.$store.state.clvIsAccount = true;
						config.url = '/v2' + (_this.$store.state.clvAccountData.group == 1 ? '/admin' : '') + '/account/currency_pairs';
					}
					else if (type == 'getCurrencyPairsArchive') {
						_this.clvIsPortletLoading3 = true;
						_this.clvIsShowCurrencyPairsArchive = false;
						config.url = '/v2' + (_this.$store.state.clvAccountData.group == 1 ? '/admin' : '') + '/account/currency_pairs/archive';
						config.params = { limit_offset: _this.clvCurrencyPairsArchiveLimitOffset, limit_count: _this.clvCurrencyPairsArchiveLimitCount };
					}
					else if (type == 'saveCurrencyPairs') {
						_this.clvCurrencyPairsSaveData = [];
						_this.clvCurrencyPairsSaveComments = [];
						for (var i in _this.clvCurrencyPairs) {
							if (_this.$store.state.clvAccountData.group == 1) {
								_this.clvCurrencyPairsSaveData.push([ _this.clvCurrencyPairs[i].index, Number(Math.round(_this.clvCurrencyPairs[i].multiplier * 10) / 10), Number(Math.round(_this.clvCurrencyPairs[i].trend_status.code * 10) / 10), Number(Math.round(_this.clvCurrencyPairs[i].buy_value * 100) / 100), Number(_this.clvCurrencyPairs[i].buy_trading), Number(_this.clvCurrencyPairs[i].buy_closing), Number(Math.round(_this.clvCurrencyPairs[i].sell_value * 100) / 100), Number(_this.clvCurrencyPairs[i].sell_trading), Number(_this.clvCurrencyPairs[i].sell_closing), Number(_this.clvCurrencyPairs[i].is_active) ]);
							}
							else {
								if (_this.clvCurrencyPairs[i].is_selected) {
									_this.clvCurrencyPairsSaveData.push([ _this.clvCurrencyPairs[i].index, Number(Math.round(_this.clvCurrencyPairs[i].multiplier * 10) / 10), Number(Math.round(_this.clvCurrencyPairs[i].trend_status.code * 10) / 10), Number(Math.round(_this.clvCurrencyPairs[i].buy_value * 100) / 100), Number(_this.clvCurrencyPairs[i].buy_trading), Number(_this.clvCurrencyPairs[i].buy_closing), Number(Math.round(_this.clvCurrencyPairs[i].sell_value * 100) / 100), Number(_this.clvCurrencyPairs[i].sell_trading), Number(_this.clvCurrencyPairs[i].sell_closing) ]);
								}
							}
							if ([-1,1].includes(Number(_this.clvCurrencyPairs[i].trend_status.code)) && _this.clvCurrencyPairs[i].trend_status_comment) { _this.clvCurrencyPairsSaveComments.push({ id: (Number(_this.clvCurrencyPairs[i].trend_status.code) == 1 ? 1 : 2), index: _this.clvCurrencyPairs[i].index, comment: _this.clvCurrencyPairs[i].trend_status_comment }); }
							if ((Number(_this.clvCurrencyPairs[i].slider_value) <= 0.7 || Number(_this.clvCurrencyPairs[i].slider_value) >= 1.3) && _this.clvCurrencyPairs[i].slider_value_comment) { _this.clvCurrencyPairsSaveComments.push({ id: 3, index: _this.clvCurrencyPairs[i].index, value: _this.clvCurrencyPairs[i].slider_value, comment: _this.clvCurrencyPairs[i].slider_value_comment }); }
							if (Number(_this.clvCurrencyPairs[i].multiplier) <= 0.6 && _this.clvCurrencyPairs[i].multiplier_comment) { _this.clvCurrencyPairsSaveComments.push({ id: 4, index: _this.clvCurrencyPairs[i].index, comment: _this.clvCurrencyPairs[i].multiplier_comment }); }
							if (Number(_this.clvCurrencyPairs[i].buy_trading) < 1 && _this.clvCurrencyPairs[i].buy_trading_comment) { _this.clvCurrencyPairsSaveComments.push({ id: 5, index: _this.clvCurrencyPairs[i].index, comment: _this.clvCurrencyPairs[i].buy_trading_comment }); }
							if (Number(_this.clvCurrencyPairs[i].buy_closing) > 0 && _this.clvCurrencyPairs[i].buy_closing_comment) { _this.clvCurrencyPairsSaveComments.push({ id: 6, index: _this.clvCurrencyPairs[i].index, comment: _this.clvCurrencyPairs[i].buy_closing_comment }); }
							if (Number(_this.clvCurrencyPairs[i].sell_trading) < 1 && _this.clvCurrencyPairs[i].sell_trading_comment) { _this.clvCurrencyPairsSaveComments.push({ id: 7, index: _this.clvCurrencyPairs[i].index, comment: _this.clvCurrencyPairs[i].sell_trading_comment }); }
							if (Number(_this.clvCurrencyPairs[i].sell_closing) > 0 && _this.clvCurrencyPairs[i].sell_closing_comment) { _this.clvCurrencyPairsSaveComments.push({ id: 8, index: _this.clvCurrencyPairs[i].index, comment: _this.clvCurrencyPairs[i].sell_closing_comment }); }
						}
						if (!_this.clvIsError) {
							_this.clvIsPortletLoading1 = true;
							config.url = '/v2' + (_this.$store.state.clvAccountData.group == 1 ? '/admin' : '') + '/account/currency_pairs';
							config.data = { data: JSON.stringify(_this.clvCurrencyPairsSaveData), comments: JSON.stringify(_this.clvCurrencyPairsSaveComments) };
							config.method = 'post';
						}
					}
					else if (type == 'saveCurrencyPairsSettings') {
						_this.clvIsPortletLoading2 = true;
						_this.clvCurrencyPairsSettings[4] = [];
						for (var j in _this.clvCurrencyPairs) {
							_this.clvCurrencyPairsSettings[4].push([ _this.clvCurrencyPairs[j].index, Number(Math.round(_this.clvCurrencyPairs[j].multiplier * 10) / 10), Number(Math.round(_this.clvCurrencyPairs[j].trend_status.code * 10) / 10), Number(Math.round(_this.clvCurrencyPairs[j].buy_value * 100) / 100), Number(_this.clvCurrencyPairs[j].buy_trading), Number(_this.clvCurrencyPairs[j].buy_closing), Number(Math.round(_this.clvCurrencyPairs[j].sell_value * 100) / 100), Number(_this.clvCurrencyPairs[j].sell_trading), Number(_this.clvCurrencyPairs[j].sell_closing), Number(_this.clvCurrencyPairs[j].is_active) ]);
						}
						config.url = '/v2/admin/account/currency_pairs/settings';
						config.data = { data: JSON.stringify(_this.clvCurrencyPairsSettings) };
						config.method = 'patch';
					}

					if (!_this.clvIsError) {
						axios(config).then(function(data) {
							if (data.status == 200) {
								_this.clvIsSuccessAxios = true;
								if (type == 'getCurrencyPairs') {
									_this.$parent.$parent.goToTop(true);
									_this.$parent.$parent.checkAppVersion(data.headers);
									_this.clvCurrencyPairsArchiveLastSaveTime = 0;
									for (var i in data.data.currency_pairs_data) {
										_this.clvCurrencyPairs.push({
											index: i,
											multiplier: data.data.currency_pairs_data[i].multiplier.toFixed(1),
											multiplier_temp: data.data.currency_pairs_data[i].multiplier.toFixed(1),
											multiplier_comment: '',
											trend_status: _this.getCurrencyPairsAllTrendStatus(data.data.currency_pairs_data[i].trend_status),
											trend_status_temp: _this.getCurrencyPairsAllTrendStatus(data.data.currency_pairs_data[i].trend_status),
											trend_status_comment: '',
											buy_value: data.data.currency_pairs_data[i].buy_value,
											buy_trading: data.data.currency_pairs_data[i].buy_trading,
											buy_trading_temp: data.data.currency_pairs_data[i].buy_trading,
											buy_trading_comment: '',
											buy_closing: data.data.currency_pairs_data[i].buy_closing,
											buy_closing_temp: data.data.currency_pairs_data[i].buy_closing,
											buy_closing_comment: '',
											sell_value: data.data.currency_pairs_data[i].sell_value,
											sell_trading: data.data.currency_pairs_data[i].sell_trading,
											sell_trading_temp: data.data.currency_pairs_data[i].sell_trading,
											sell_trading_comment: '',
											sell_closing: data.data.currency_pairs_data[i].sell_closing,
											sell_closing_temp: data.data.currency_pairs_data[i].sell_closing,
											sell_closing_comment: '',
											avg_volatility: (data.data.currency_pairs_data[i].avg_volatility ? data.data.currency_pairs_data[i].avg_volatility.toFixed(2) : '−'),
											max_volatility: (data.data.currency_pairs_data[i].max_volatility ? data.data.currency_pairs_data[i].max_volatility.toFixed(2) : '−'),
											slider_value: data.data.currency_pairs_data[i].buy_value,
											slider_value_temp: data.data.currency_pairs_data[i].buy_value,
											slider_value_comment: '',
											is_active: data.data.currency_pairs_data[i].is_active,
											is_selected: data.data.currency_pairs_data[i].is_selected
										});
										if (data.data.currency_pairs_data[i].created_at) {
											_this.clvCurrencyPairsArchiveLastSaveTime = Number(data.data.currency_pairs_data[i].created_at);
										}
									}
									if (_this.$store.state.clvAccountData.group == 1) {
										_this.clvCurrencyPairsSettings.push(_this.$store.state.clvSystemSettings['is_active_currency_pairs_multiplier']);
										_this.clvCurrencyPairsSettings.push(_this.$store.state.clvSystemSettings['is_active_currency_pairs_trend_status']);
										_this.clvCurrencyPairsSettings.push(_this.$store.state.clvSystemSettings['is_active_currency_pairs_trading']);
										_this.clvCurrencyPairsSettings.push(_this.$store.state.clvSystemSettings['is_active_currency_pairs_closing']);
										_this.clvCurrencyPairsSettings.push([]);
										_this.clvCurrencyPairsArchiveLastSaveTime = Number(data.data.currency_pairs_time);
										_this.clvIsTraderFound = Number(data.data.is_trader_found) ? true : false;
									}
									else {
										_this.clvCountSecondsAfterLastSave = Number(data.data.count_seconds_after_last_save);
										_this.clvCountMinutesAfterLastSave = Number(data.data.count_minutes_after_last_save);
										_this.clvCountHoursAfterLastSave = Number(data.data.count_hours_after_last_save);
									}
								}
								else if (type == 'getCurrencyPairsArchive') {
									var time = '';
									if (_this.$store.state.clvAccountData.group == 1 && _this.clvCurrencyPairsArchiveLastSaveTime) {
										time = 'time' + _this.clvCurrencyPairsArchiveLastSaveTime + 'current';
										if (_this.clvCurrencyPairsArchive[time] == undefined) {
											_this.clvCurrencyPairsArchive[time] = [];
											for (var j in _this.clvCurrencyPairs) {
												if (_this.clvCurrencyPairs[j].is_active && _this.clvCurrencyPairs[j].is_selected) {
													_this.clvCurrencyPairsArchive[time].push(_this.clvCurrencyPairs[j]);
												}
											}
											_this.clvCurrencyPairsArchiveIsShow.push(0);
										}
									}
									if (data.data.data.length) {
										for (var k in data.data.data) {
											time = 'time' + data.data.data[k].created_at;
											if (_this.clvCurrencyPairsArchive[time] == undefined) {
												_this.clvCurrencyPairsArchive[time] = [];
												_this.clvCurrencyPairsArchiveIsShow.push(0);
											}
											data.data.data[k].trend_status = _this.getCurrencyPairsAllTrendStatus(data.data.data[k].trend_status);
											_this.clvCurrencyPairsArchive[time].push(data.data.data[k]);
										}
									}
									_this.clvCurrencyPairsArchiveLimitOffset = (data.data.count > _this.clvCurrencyPairsArchiveLimitOffset + _this.clvCurrencyPairsArchiveLimitCount ? _this.clvCurrencyPairsArchiveLimitOffset + _this.clvCurrencyPairsArchiveLimitCount : 0);
									_this.clvIsShowCurrencyPairsArchive = true;
								}
								else if (type == 'saveCurrencyPairs') {
									_this.clvCountSecondsAfterLastSave = 0;
									_this.clvCountMinutesAfterLastSave = 0;
									_this.clvCountHoursAfterLastSave = 0;
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (type == 'saveCurrencyPairs') {
										if      (data.response.data.error == 'DATA_IS_EMPTY') { _this.clvPortletAlert1 = _this.getError(1); }
										else if (data.response.data.error == 'DATA_NOT_VALID') { _this.clvPortletAlert1 = _this.getError(2); }
									}
									else if (type == 'saveCurrencyPairsSettings') {
										if      (data.response.data.error == 'DATA_IS_EMPTY') { _this.clvPortletAlert2 = _this.getError(1); }
										else if (data.response.data.error == 'DATA_NOT_VALID') { _this.clvPortletAlert2 = _this.getError(2); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.exit(); }
							}
							if (!_this.clvIsError) {
								if (type == 'saveCurrencyPairs') {
									_this.clvPortletAlert1 = _this.getError(0);
								}
								else if (type == 'saveCurrencyPairsSettings') {
									_this.clvPortletAlert2 = _this.getError(0);
								}
							}
						}).finally(function() {
							if (['saveCurrencyPairs', 'saveCurrencyPairsSettings'].includes(type) && _this.clvIsSuccessAxios) {
								document.location.reload(true);
							}
							else {
								_this.clvIsLoading = false;
								_this.clvIsPortletLoading1 = false;
								_this.clvIsPortletLoading2 = false;
								_this.clvIsPortletLoading3 = false;
								_this.$store.state.clvIsAccount = false;
								_this.$store.state.clvIsLoading = false;
							}
						});
					}
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			currencyPairsToggleValue: function(value) {
				return value > 0 ? true : false;
			},
			currencyPairsToggleChange: function(type, index, event) {
				if (type == 6) {
					this.clvCurrencyPairsSettings.splice(index, 1, Number(event.value));
				}
				else {
					if      (type == 1) { this.currencyPairsToggleChangeIsSelected(index, Number(event.value)); }
					else if (type == 2) { this.clvCurrencyPairs[index].buy_trading = Number(event.value); }
					else if (type == 3) { this.clvCurrencyPairs[index].buy_closing = Number(event.value); }
					else if (type == 4) { this.clvCurrencyPairs[index].sell_trading = Number(event.value); }
					else if (type == 5) { this.clvCurrencyPairs[index].sell_closing = Number(event.value); }
					if ([2,3,4,5].includes(type)) {
						var _this = this; setTimeout(function() { _this.currencyPairsCheckVoting((Number(type) + 3), index); }, 0);
					}
				}
			},
			currencyPairsToggleChangeIsSelected: function(index, value) {
				if (this.$store.state.clvAccountData.group == 1) {
					this.clvCurrencyPairs[index].is_active = value;
				}
				else {
					this.clvCurrencyPairs[index].multiplier = 0.6;
					this.clvCurrencyPairs[index].trend_status = this.getCurrencyPairsAllTrendStatus(0);
					this.clvCurrencyPairs[index].buy_value = 1;
					this.clvCurrencyPairs[index].buy_trading = 1;
					this.clvCurrencyPairs[index].buy_closing = 0;
					this.clvCurrencyPairs[index].sell_value = 1;
					this.clvCurrencyPairs[index].sell_trading = 1;
					this.clvCurrencyPairs[index].sell_closing = 0;
					this.clvCurrencyPairs[index].slider_value = 1;
				}
				this.clvCurrencyPairs[index].is_selected = value;
			},
			currencyPairsToggleArchiveAccordion: function(index, value) {
				for (var i in this.clvCurrencyPairsArchiveIsShow) {
					if (i !== index) { this.clvCurrencyPairsArchiveIsShow.splice(i, 1, 0); }
				}
				this.clvCurrencyPairsArchiveIsShow.splice(index, 1, (value ? 0 : 1));
			},
			currencyPairsSelectChange: function(type, index, event) {
				if (type == 1 || (type == 2 && event.code !== undefined && [-1,1].includes(Number(event.code)))) {
					if (type == 2) { this.clvCurrencyPairs[index].trend_status_comment = ''; }
					var _this = this, id = (type == 1 ? 4 : (Number(event.code) == 1 ? 1 : 2));
					setTimeout(function() { _this.currencyPairsCheckVoting(id, index); }, 0);
				}
			},
			currencyPairsRangeChange: function(index, event) {
				this.clvCurrencyPairs[index].buy_value = Number(event);
				this.clvCurrencyPairs[index].sell_value = (2 - Number(event)).toFixed(2);
				this.clvCurrencyPairs[index].slider_value = Number(event);
			},
			currencyPairsRangeDragEnd: function(index) {
				var _this = this; setTimeout(function() { _this.currencyPairsCheckVoting(3, index); }, 0);
			},
			currencyPairsRangeFormatter: function(event) {
				return Number(event).toFixed(2);
			},
			currencyPairsCheckVoting: function(id, index) {
				if (this.$store.state.clvSystemSettings['is_check_voting_for_group_' + this.$store.state.clvAccountData.group]) {
					if (([1,2].includes(id) && this.clvCurrencyPairs[index].trend_status_comment == '') || (id == 3 && (Number(this.clvCurrencyPairs[index].slider_value) <= 0.7 || Number(this.clvCurrencyPairs[index].slider_value) >= 1.3) && Number(this.clvCurrencyPairs[index].slider_value_temp) > 0.7 && Number(this.clvCurrencyPairs[index].slider_value_temp) < 1.3 && this.clvCurrencyPairs[index].slider_value_comment == '') || (id == 4 && Number(this.clvCurrencyPairs[index].multiplier) <= 0.6 && Number(this.clvCurrencyPairs[index].multiplier_temp) > 0.6 && this.clvCurrencyPairs[index].multiplier_comment == '') || (id == 5 && Number(this.clvCurrencyPairs[index].buy_trading) < 1 && Number(this.clvCurrencyPairs[index].buy_trading_temp) > 0 && this.clvCurrencyPairs[index].buy_trading_comment == '') || (id == 6 && Number(this.clvCurrencyPairs[index].buy_closing) > 0 && Number(this.clvCurrencyPairs[index].buy_closing_temp) < 1 && this.clvCurrencyPairs[index].buy_closing_comment == '') || (id == 7 && Number(this.clvCurrencyPairs[index].sell_trading) < 1 && Number(this.clvCurrencyPairs[index].sell_trading_temp) > 0 && this.clvCurrencyPairs[index].sell_trading_comment == '') || (id == 8 && Number(this.clvCurrencyPairs[index].sell_closing) > 0 && Number(this.clvCurrencyPairs[index].sell_closing_temp) < 1 && this.clvCurrencyPairs[index].sell_closing_comment == '')) {
						this.$modal.show('ClvModalCurrencyPairs', { type: 'addComment', id: id, index: index });
					}
				}
			},
			closeCurrencyPairsArchive: function() {
				this.clvCurrencyPairsArchive = {};
				this.clvCurrencyPairsArchiveLimitOffset = 0;
				this.clvIsShowCurrencyPairsArchive = false;
			}
		},
		components: {
			ClvModalCurrencyPairs: () => import('@/views/modal_components/ClvModalCurrencyPairs.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.clv_account_desktop_currency_pairs table tr.disabled {
		display: none;
	}
	.clv_account_desktop_currency_pairs table tr th,
	.clv_account_desktop_currency_pairs table tr td {
		padding: 0 10px;
		border: none;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.clv_account_desktop_currency_pairs table tr th {
		padding: 10px 2px;
	}
	.clv_account_desktop_currency_pairs table tr th:nth-child(1),
	.clv_account_desktop_currency_pairs table tr td:nth-child(1),
	.clv_account_desktop_currency_pairs table tr th:nth-child(5),
	.clv_account_desktop_currency_pairs table tr td:nth-child(5),
	.clv_account_desktop_currency_pairs table tr th:nth-child(6),
	.clv_account_desktop_currency_pairs table tr td:nth-child(6),
	.clv_account_desktop_currency_pairs table tr th:nth-child(10),
	.clv_account_desktop_currency_pairs table tr td:nth-child(10),
	.clv_account_desktop_currency_pairs table tr th:nth-child(11),
	.clv_account_desktop_currency_pairs table tr td:nth-child(11) {
		padding: 4px 2px;
		width: 1%;
	}
	.clv_account_desktop_currency_pairs table tr th:nth-child(2),
	.clv_account_desktop_currency_pairs table tr td:nth-child(2) {
		width: 18%;
	}
	.clv_account_desktop_currency_pairs table tr th:nth-child(3),
	.clv_account_desktop_currency_pairs table tr td:nth-child(3) {
		padding: 0 10px 0 0;
		width: 1%;
	}
	.clv_account_desktop_currency_pairs table tr th:nth-child(4),
	.clv_account_desktop_currency_pairs table tr td:nth-child(4) {
		padding: 0 30px 0 0;
		width: 1%;
	}
	.clv_account_desktop_currency_pairs table tr th:first-child,
	.clv_account_desktop_currency_pairs table tr td:first-child {
		padding: 4px 2px 4px 0;
	}
	.clv_account_desktop_currency_pairs table tr th:last-child,
	.clv_account_desktop_currency_pairs table tr td:last-child {
		padding: 4px 0 4px 2px;
	}
	.clv_account_desktop_currency_pairs table tr.disabled td,
	.clv_account_desktop_currency_pairs table tr.not_selected td:nth-child(n + 2),
	.clv_account_desktop_currency_pairs table tr.not_selected_admin td:nth-child(n + 2) {
		position: relative;
		opacity: 0.6;
	}
	.clv_account_desktop_currency_pairs table tr.not_selected_admin td.active:nth-child(n + 2) {
		opacity: 1;
	}
	.clv_account_desktop_currency_pairs table tr.not_selected td:nth-child(n + 2):before,
	.clv_account_desktop_currency_pairs table tr.not_selected_admin td:nth-child(n + 2):before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 10;
	}
	.clv_account_desktop_currency_pairs table tr.not_selected_admin td.active:nth-child(n + 2):before {
		position: unset;
	}
	.clv_account_desktop_currency_pairs table tr td:nth-child(2) {
		text-transform: uppercase;
	}
	.clv_account_desktop_currency_pairs table tr td:nth-child(3) .clv_select,
	.clv_account_desktop_currency_pairs table tr td:nth-child(4) .clv_select {
		display: inline-block;
		width: 50px;
	}
	.clv_account_desktop_currency_pairs table tr td:nth-child(4) .clv_select {
		width: 105px;
	}
	.clv_account_desktop_currency_pairs table tr td:nth-child(3) .clv_select .vs__dropdown-toggle,
	.clv_account_desktop_currency_pairs table tr td:nth-child(4) .clv_select .vs__dropdown-toggle {
		padding: 3px 0 3px 0;
	}
	.clv_account_desktop_currency_pairs table tr td:nth-child(3) .clv_select .vs__dropdown-toggle .vs__clear,
	.clv_account_desktop_currency_pairs table tr td:nth-child(4) .clv_select .vs__dropdown-toggle .vs__clear {
		display: none;
	}
	.clv_account_desktop_currency_pairs table tr td:nth-child(3) .clv_select .vs__dropdown-option,
	.clv_account_desktop_currency_pairs table tr td:nth-child(4) .clv_select .vs__dropdown-option {
		padding: 3px 15px;
	}
	.clv_account_desktop_currency_pairs table tr th:nth-child(8) span {
		display: inline-block;
		width: 80px;
	}
	.clv_account_desktop_currency_pairs .clv_range_slider .vue-slider-rail {
		background: rgb(255,255,255);
		background: linear-gradient(90deg, rgba(255,104,104,1) 40%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 49.5%, rgba(102,102,102,1) 49.5%, rgba(102,102,102,1) 50.5%, rgba(255,255,255,1) 50.5%, rgba(255,255,255,1) 60%, rgba(0,221,0,1) 60%);
	}
	.clv_account_desktop_currency_pairs .clv_range_slider .vue-slider-process,
	.clv_account_desktop_currency_pairs .clv_range_slider:hover .vue-slider-process {
		background: none;
	}
	.clv_account_desktop_currency_pairs .clv_range_slider .vue-slider-dot-tooltip {
		display: none;
	}
	.clv_account_desktop_currency_pairs .clv_toggle_button {
		margin: -1px 0;
	}
	.clv_account_desktop_currency_pairs_last_save {
		margin: 20px 0 0 0;
		text-align: right;
	}
	.clv_account_desktop_currency_pairs_last_save.red {
		color: #FF9999;
	}
	.clv_account_desktop_currency_pairs_last_save b {
		margin: 0 8px 0 0;
	}
	.clv_account_desktop_currency_pairs_alert {
		margin: 0 0 20px 0;
		padding: 10px 15px;
		border-radius: 5px;
		background: #E26868;
		text-align: center;
	}
	.clv_account_desktop_currency_pairs_settings_row {
		position: relative;
		margin: 0 0 5px 0;
		display: table;
		width: 100%;
	}
	.clv_account_desktop_currency_pairs_settings_row:last-child {
		margin: 0;
	}
	.clv_account_desktop_currency_pairs_settings_row_label {
		position: absolute;
	}
	.clv_account_desktop_currency_pairs_settings_row_value {
		padding: 4px 0 4px 50px;
		line-height: 17px;
	}
	.clv_account_desktop_currency_pairs_archive_accordion_block {
		margin: 0 0 5px 0;
		border: 1px solid #999999;
		border-radius: 10px;
		background: #444444E6;
	}
	.clv_account_desktop_currency_pairs_archive_accordion_block:last-child {
		margin: 0;
	}
	.clv_account_desktop_currency_pairs_archive_accordion.is_admin .clv_account_desktop_currency_pairs_archive_accordion_block:first-child {
		background: #222222E6;
	}
	.clv_account_desktop_currency_pairs_archive_accordion_head {
		position: relative;
		padding: 10px 40px 10px 20px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		cursor: pointer;
		font-size: 12px;
		line-height: 20px;
		min-height: 40px;
	}
	.clv_account_desktop_currency_pairs_archive_accordion_head span {
		margin: 0 0 0 10px;
	}
	.clv_account_desktop_currency_pairs_archive_accordion_head svg {
		position: absolute;
		top: 50%;
		right: 20px;
		margin: -10px 0 0 0;
		font-size: 20px;
	}
	.clv_account_desktop_currency_pairs_archive_accordion_body {
		margin: 0 20px 20px 20px;
		padding: 20px 0 0 0;
		border-top: 1px solid #999999;
	}

	@media (max-width: 1200px) {
		.clv_account_desktop_currency_pairs table tr td {
			padding: 7px 12px !important;
			border: 1px solid #999999;
			border-bottom: 0;
		}
		.clv_account_desktop_currency_pairs table tr td:nth-child(2):before {
			text-transform: none;
		}
		.clv_account_desktop_currency_pairs table tr.not_selected td:nth-child(n + 2):before,
		.clv_account_desktop_currency_pairs table tr.not_selected_admin td:nth-child(n + 2):before {
			content: attr(data-label);
			position: relative;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
		}
	}
	@media (max-width: 1100px) {
		.clv_account_desktop_currency_pairs table tr th:nth-child(8) span {
			width: 100%;
		}
		.clv_account_desktop_currency_pairs table tr th:nth-child(8) b {
			display: none;
		}
	}
	@media (max-width: 550px) {
		.clv_account_desktop_currency_pairs_archive_accordion_head {
			font-size: 11px;
		}
	}
</style>
