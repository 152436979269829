<template>
	<div class="clv_account_desktop_page">
		<div class="clv_account_desktop_page_loading" v-if="clvIsLoading"><div class="clv_loading"></div></div>
		<div class="clv_account_desktop_page_content" v-else>
			<div class="clv_account_desktop_portlet">
				<div class="clv_account_desktop_portlet_title">Список пользователей</div>
				<div class="clv_account_desktop_portlet_content">
					<div class="clv_account_desktop_users">
						<table class="clv_table">
							<thead>
								<tr>
									<th>Id</th>
									<th>Группа</th>
									<th>Псевдоним</th>
									<th>Страна</th>
									<th>Баланс</th>
									<th>Вес</th>
									<th>Qual</th>
									<th>Final</th>
									<th><font-awesome-icon :icon="['fas', 'chart-line']" /></th>
									<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
									<th><font-awesome-icon :icon="['fas', 'times']" /></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in clvUsers" :key="index">
									<td data-label="Id:">{{ value.id }}</td>
									<td data-label="Группа:">{{ clvUserGroups[value.group] }}</td>
									<td data-label="Псевдоним:">{{ value.username }}</td>
									<td data-label="Страна:">{{ value.country }}</td>
									<td data-label="Баланс:">${{ value.balance.toFixed(2) }}</td>
									<td data-label="Вес:">{{ value.weight }}</td>
									<td data-label="Qual:">{{ value.qual_weight }}</td>
									<td data-label="Final:">{{ (Math.round(((value.is_voting ? ((($store.state.clvSystemSettings.time - value.voted_at) / (60 * 60)) > 16 ? (value.weight - (value.weight * 4 * (Math.ceil(($store.state.clvSystemSettings.time - value.voted_at) / (60 * 60)) - 16) / 100)) : value.weight) : 0) * value.qual_weight / 100) * 100) / 100) }}</td>
									<td data-label="Статистика:"><button type="button" class="clv_button orange icon" @click="$modal.show('ClvModalUsersQuals', { type: 'getUserQuals', index: index, id: value.id })" v-if="[0,2].includes(value.group)"><font-awesome-icon :icon="['fas', 'chart-line']" /></button></td>
									<td data-label="Изменить:"><button type="button" class="clv_button blue icon" @click="$modal.show('ClvModalUsers', { type: 'editUser', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
									<td data-label="Удалить:"><button type="button" class="clv_button red icon" @click="$modal.show('ClvModalUsers', { type: 'deleteUser', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="clv_account_desktop_portlet_buttons">
					<button type="button" class="clv_button big green" @click="$modal.show('ClvModalUsers', { type: 'addUser' })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить пользователя</button>
				</div>
			</div>
			<div class="clv_account_desktop_modals">
				<div is="ClvModalUsers"></div>
				<div is="ClvModalUsersQuals"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			clvUsers: [],
			clvUserGroups: [],
			clvParserSources: [],
			clvIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.clvIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.clvAccountData.group == 1) {
					this.axios();
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('clvToken')) {

					var config = {
						url: '/v2/admin/account/users',
						method: 'get', baseURL: _this.$store.state.clvApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('clvToken') }
					};

					_this.clvIsLoading = true;
					_this.$store.state.clvIsAccount = true;

					axios(config).then(function(data) {
						if (data.status == 200) {
							_this.$parent.$parent.goToTop(true);
							_this.$parent.$parent.checkAppVersion(data.headers);
							for (var i in data.data.users_data) {
								_this.clvUsers[i] = data.data.users_data[i];
								_this.clvUsers[i].phone = (_this.clvUsers[i].phone !== '' ? '+' : '') + _this.clvUsers[i].phone;
							}
							for (var j in data.data.system_groups) {
								_this.clvUserGroups[data.data.system_groups[j].group] = data.data.system_groups[j].name;
							}
							for (var k in data.data.parser_sources) {
								_this.clvParserSources.push(data.data.parser_sources[k].source);
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.clvIsLoading = false;
						_this.$store.state.clvIsAccount = false;
						_this.$store.state.clvIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		components: {
			ClvModalUsers: () => import('@/views/modal_components/ClvModalUsers.vue'),
			ClvModalUsersQuals: () => import('@/views/modal_components/ClvModalUsersQuals.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.clv_account_desktop_users tr th:nth-child(1),
	.clv_account_desktop_users tr td:nth-child(1),
	.clv_account_desktop_users tr th:nth-child(9),
	.clv_account_desktop_users tr td:nth-child(9),
	.clv_account_desktop_users tr th:nth-child(10),
	.clv_account_desktop_users tr td:nth-child(10),
	.clv_account_desktop_users tr th:nth-child(11),
	.clv_account_desktop_users tr td:nth-child(11) {
		min-width: 45px;
		width: 1%;
	}
	.clv_account_desktop_users tr td:nth-child(n + 2) {
		word-break: break-all;
		word-break: break-word;
	}
</style>
