<template>
	<div class="clv_account_desktop_page">
		<div class="clv_account_desktop_page_loading" v-if="clvIsLoading"><div class="clv_loading"></div></div>
		<div class="clv_account_desktop_page_content" v-else>
			<div class="clv_account_desktop_portlet">
				<div class="clv_account_desktop_portlet_title">Настройки системы</div>
				<div class="clv_account_desktop_portlet_content">
					<div class="clv_account_desktop_system_settings">
						<table class="clv_table">
							<thead>
								<tr>
									<th>№</th>
									<th>Имя</th>
									<th>Ключ</th>
									<th>Значение</th>
									<th>Тип</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, name, index) in clvSystemSettings" :key="index">
									<td data-label="№:">{{ (index + 1) }}</td>
									<td data-label="Имя:">{{ value.name }}</td>
									<td data-label="Ключ:">{{ value.key }}</td>
									<td data-label="Значение:"><input type="text" class="clv_input" v-model.trim="value.value" placeholder="value.key" autocomplete="off" @keyup="change(value.key, value.value)"></td>
									<td data-label="Тип:">{{ value.type }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="clv_account_desktop_portlet_loading" :class="{ hide: !clvIsPortletLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="clv_account_desktop_portlet_buttons" :class="{ hide: clvIsPortletLoading }">
					<button type="button" class="clv_button big green" @click="axios('saveSystemSettings')"><font-awesome-icon :icon="['fas', 'save']" />Сохранить</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			clvSystemSettings: {},
			clvSystemSettingsChangeData: {},
			clvIsPortletLoading: false,
			clvIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.clvIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.clvAccountData.group == 1) {
					this.axios('getSystemSettings');
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('clvToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.clvApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('clvToken') }
					};

					if (type == 'getSystemSettings') {
						_this.clvIsLoading = true;
						_this.$store.state.clvIsAccount = true;
						config.url = '/v2/admin/account/settings';
					}
					else if (type == 'saveSystemSettings') {
						_this.clvIsPortletLoading = true;
						config.url = '/v2/admin/account/settings';
						config.data = { data: _this.clvSystemSettingsChangeData };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getSystemSettings') {
								_this.$parent.$parent.goToTop(true);
								_this.$parent.$parent.checkAppVersion(data.headers);
								for (var i in data.data) {
									_this.clvSystemSettings[data.data[i].key] = data.data[i];
								}
							}
							else if (type == 'saveSystemSettings') {
								_this.clvSystemSettingsChangeData = {};
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.clvIsLoading = false;
						_this.clvIsPortletLoading = false;
						_this.$store.state.clvIsAccount = false;
						_this.$store.state.clvIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			change: function(key, value) {
				this.clvSystemSettingsChangeData[key] = value;
			}
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.clv_account_desktop_system_settings tr th:nth-child(1),
	.clv_account_desktop_system_settings tr td:nth-child(1) {
		min-width: 45px;
		width: 1%;
	}
	.clv_account_desktop_system_settings tr th:nth-child(2),
	.clv_account_desktop_system_settings tr td:nth-child(2),
	.clv_account_desktop_system_settings tr th:nth-child(3),
	.clv_account_desktop_system_settings tr td:nth-child(3),
	.clv_account_desktop_system_settings tr th:nth-child(4),
	.clv_account_desktop_system_settings tr td:nth-child(4) {
		width: 33.33333%;
	}
</style>
