<template>
	<div class="clv_account_desktop_page">
		<div class="clv_account_desktop_page_loading" v-if="clvIsLoading"><div class="clv_loading"></div></div>
		<div class="clv_account_desktop_page_content" v-else>
			<div class="clv_account_desktop_portlet">
				<div class="clv_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.3', 0, { options: [0] })"></div>
				<div class="clv_account_desktop_portlet_content">
					<div class="clv_account_desktop_news_instruction">
						<div class="clv_account_desktop_news_instruction_intro" v-html="getNewsInstruction()"></div>
						<div class="clv_account_desktop_news_instruction_button"><button type="button" class="clv_button common green" @click="clvIsShowInstruction = !clvIsShowInstruction" v-html="$store.getters.getLanguageText('1.1', (clvIsShowInstruction ? 17 : 21))"></button></div>
					</div>
				</div>
			</div>
			<div class="clv_account_desktop_page_content_navigation">
				<div class="clv_account_desktop_page_content_navigation_filters">
					<div class="clv_account_desktop_page_content_navigation_filters_label" v-html="$store.getters.getLanguageText('1.8', 3)"></div>
					<div class="clv_account_desktop_page_content_navigation_filters_block">
						<div class="clv_account_desktop_page_content_navigation_filters_checkboxes">
							<label class="clv_checkbox" for="clv_account_desktop_stats_is_hide_locked_account">
								<input type="checkbox" id="clv_account_desktop_stats_is_hide_locked_account" v-model.trim="clvIsNewsDataHideLowVolatility" @change="toggleChangeForIsNewsDataHideLowVolatility">
								<span class="clv_checkbox_mark"></span>
								<span class="clv_checkbox_text" v-html="$store.getters.getLanguageText('1.8.2', 0)"></span>
							</label>
						</div>
					</div>
				</div>
			</div>
			<div class="clv_account_desktop_portlet" :class="{ blue: index }" v-for="(value, index) in clvNewsData" :key="index">
				<div class="clv_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.3', 0, { options: [index] })" v-if="index"></div>
				<div class="clv_account_desktop_portlet_content">
					<div class="clv_account_desktop_news_data" v-if="clvNewsData[index].data.length">
						<div class="clv_account_desktop_news_data_accordion">
							<div class="clv_account_desktop_news_data_accordion_item" v-for="(value2, index2) in clvNewsData[index].data" :key="index2">
								<div class="clv_account_desktop_news_data_accordion_item_date" v-if="(!index2 || (index2 && ((new Date(value2.event_timestamp_at * 1000).getDay()) !== (new Date(clvNewsData[index].data[(index2 - 1)].event_timestamp_at * 1000).getDay()))))">{{ (new Date(value2.event_timestamp_at * 1000).toLocaleString($store.state.clvLanguage, { day: 'numeric', month: 'long', year: 'numeric', weekday: 'long' })) }}</div>
								<div class="clv_account_desktop_news_data_accordion_item_block">
									<div class="clv_account_desktop_news_data_accordion_item_block_head">
										<div class="clv_account_desktop_news_data_accordion_item_block_head_toggle" @click="toggleNewsResults(index, index2)"></div>
										<div class="clv_account_desktop_news_data_accordion_item_block_head_content">
											<div class="clv_account_desktop_news_data_accordion_item_block_head_content_info">
												<div class="clv_account_desktop_news_data_accordion_item_block_head_content_info_date"><span class="sticker" v-html="$store.getters.getLanguageText('3.3', 3)" v-if="(!index && (((value2.event_timestamp_at * 1000) - (new Date().getTime())) < (12 * 60 * 60 * 1000)))"></span><span>{{ (new Date(value2.event_timestamp_at * 1000).toLocaleString($store.state.clvLanguage, { day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit' })) }}</span></div>
												<div class="clv_account_desktop_news_data_accordion_item_block_head_content_info_country" :class="value2.currency.toLowerCase()" v-tooltip.auto="{ content: value2.country, trigger: $store.state.clvIsMobile ? 'click' : 'click hover' }"></div>
												<div class="clv_account_desktop_news_data_accordion_item_block_head_content_info_symbol">{{ value2.symbol }}</div>
												<div class="clv_account_desktop_news_data_accordion_item_block_head_content_info_volatility" :class="[(value2.volatility > 2 ? 'red' : (value2.volatility > 1 ? 'orange' : 'grey'))]" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 7, { options: [(value2.volatility > 2 ? 2 : (value2.volatility > 1 ? 1 : 0))] }), trigger: $store.state.clvIsMobile ? 'click' : 'click hover' }"><font-awesome-icon :icon="['fas', 'exclamation-circle']" /></div>
												<div class="clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name"><div v-html="value2.event_name"></div><font-awesome-icon :icon="['fas', 'bullhorn']" v-if="value2.event_type == 1" /></div>
											</div>
											<div class="clv_account_desktop_news_data_accordion_item_block_head_content_tools" v-if="[0,1].includes($store.state.clvAccountData.group)">
												<div class="clv_account_desktop_news_data_accordion_item_block_head_content_tools_rating" :class="{ disabled: ($store.state.clvAccountData.group == 1), active: (Object.keys(value2.ratings).length && value2.ratings[$store.state.clvAccountData.id] !== undefined) }" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 8, { options: [($store.state.clvAccountData.group == 1 ? (Math.floor(value2.news_data_rating) > 0 ? (Math.floor(value2.news_data_rating) - 1) : 0) : clvNewsDataRatingTooltipOptionsIndex)] }), trigger: $store.state.clvIsMobile ? 'click' : 'click hover' }"><star-rating :increment="1" :max-rating="5" :star-size="17" :show-rating="false" :read-only="($store.state.clvAccountData.group == 1 ? true : false)" :animate="($store.state.clvAccountData.group == 1 ? false : true)" inactive-color="#CCCCCC" v-model="value2.news_data_rating" @rating-selected="setNewsRating(index, $event, index2)" @current-rating="(clvNewsDataRatingTooltipOptionsIndex = (Number($event) - 1))"></star-rating></div>
												<div class="clv_account_desktop_news_data_accordion_item_block_head_content_tools_is_movement" :class="{ disabled: ($store.state.clvAccountData.group == 1), active: (Object.keys(value2.is_movements).length && value2.is_movements[$store.state.clvAccountData.id] !== undefined && value2.news_data_is_movement) }" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 9, { options: [0] }), trigger: $store.state.clvIsMobile ? 'click' : 'click hover' }"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" @click="setNewsIsMovement(index, !value2.news_data_is_movement, index2)" /></div>
												<div class="clv_account_desktop_news_data_accordion_item_block_head_content_tools_br"></div>
												<div class="clv_account_desktop_news_data_accordion_item_block_head_content_tools_timeframe" :class="{ disabled: ($store.state.clvAccountData.group == 1), active: (Object.keys(value2.timeframes).length && value2.timeframes[$store.state.clvAccountData.id] !== undefined), open: ($store.state.clvCSelectId == ('timeframe_' + index + '_' + index2)) }" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 9, { options: [1] }), trigger: $store.state.clvIsMobile ? 'click' : 'click hover' }">
													<div class="clv_cselect" :class="{ open: ($store.state.clvCSelectId == ('timeframe_' + index + '_' + index2)) }" tabindex="-1" @blur="$parent.$parent.closeCSelect">
														<div class="clv_cselect_toggle" @click="($store.state.clvAccountData.group == 1 ? false : $parent.$parent.toggleCSelect('timeframe_' + index + '_' + index2))">
															<div class="clv_cselect_toggle_value">{{ value2.news_data_timeframe }}</div>
															<div class="clv_cselect_toggle_button"><font-awesome-icon :icon="['fas', 'chevron-' + (($store.state.clvCSelectId == ('timeframe_' + index + '_' + index2)) ? 'up' : 'down')]" /></div>
														</div>
														<div class="clv_cselect_options">
															<div class="clv_cselect_options_item" v-for="(value3, index3) in clvNewsDataTimeframes" :key="index3">
																<div class="clv_cselect_options_item_content">
																	<div class="clv_cselect_options_item_content_value" @click="setNewsTimeframe(index, value3, index2)">{{ value3 }}</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="clv_account_desktop_news_data_accordion_item_block_head_content_tools_distance" :class="{ disabled: ($store.state.clvAccountData.group == 1), active: (Object.keys(value2.distances).length && value2.distances[$store.state.clvAccountData.id] !== undefined), voted: ($store.state.clvAccountData.group == 0 && Object.keys(value2.distances).length && value2.distances[$store.state.clvAccountData.id] == undefined) }" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 9, { options: [2] }), trigger: $store.state.clvIsMobile ? 'click' : 'click hover' }"><input type="text" class="clv_input" v-model.trim="value2.news_data_new_distance" @keyup.13="setNewsDistance(index, value2.news_data_new_distance, index2)" autocomplete="off" :disabled="($store.state.clvAccountData.group == 1 ? true : false)"></div>
												<div class="clv_account_desktop_news_data_accordion_item_block_head_content_tools_control" v-if="JSON.stringify('distance_' + value2.news_data_new_distance) !== value2.news_data_old_distance">
													<div class="clv_account_desktop_news_data_accordion_item_block_head_content_tools_control_loading" :class="{ hide: !value2.news_data_control_is_loading }"><img src="@/assets/images/loading.gif"></div>
													<div class="clv_account_desktop_news_data_accordion_item_block_head_content_tools_control_buttons" :class="{ hide: value2.news_data_control_is_loading }"><button type="button" class="clv_button big green" @click="setNewsData(index, index2)"><font-awesome-icon :icon="['fas', 'check']" /></button></div>
												</div>
											</div>
										</div>
										<div class="clv_account_desktop_news_data_accordion_item_block_head_loading" v-if="value2.news_results_data_is_loading"><img src="@/assets/images/loading.gif"></div>
										<div class="clv_account_desktop_news_data_accordion_item_block_head_buttons" v-else>
											<font-awesome-icon :icon="['fas', 'angle-up']" v-if="value2.news_results_data_is_show" />
											<font-awesome-icon :icon="['fas', 'angle-down']" v-else />
										</div>
									</div>
									<div class="clv_account_desktop_news_data_accordion_item_block_body" :class="{ hide: !value2.news_results_data_is_show }">
										<div class="clv_account_desktop_news_data_accordion_item_block_body_content">
											<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles" v-if="value.results_data[index2] && value.results_data[index2].data.length">
												<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_row" :class="{ last: ((value.results_data[index2].count == (index3 + 1)) && !value.results_data[index2].limit_offset) }" v-for="(value3, index3) in value.results_data[index2].data" :key="index3">
													<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col">
														<highcharts class="clv_chart" :constructor-type="'stockChart'" :options="value3.temp_chart_options"></highcharts>
													</div>
													<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col">
														<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data">
															<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_label" v-html="$store.getters.getLanguageText('3.3', 4)"></div>
															<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_value">{{ value3.event_attr_id }}</div>
														</div>
														<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data">
															<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_label" v-html="$store.getters.getLanguageText('3.3', 5)"></div>
															<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_value">{{ (new Date(value3.event_timestamp_at * 1000).toLocaleString($store.state.clvLanguage)) }}</div>
														</div>
														<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data">
															<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_label" v-html="$store.getters.getLanguageText('3.3', 6)"></div>
															<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_value">{{ (new Date((value3.updated_at ? value3.updated_at : value3.created_at) * 1000).toLocaleString($store.state.clvLanguage)) }}</div>
														</div>
														<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data">
															<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_label" v-html="$store.getters.getLanguageText('3.3', 7)"></div>
															<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_value">{{ $parent.$parent.getFloorAmount((value3.temp_max_delta / 10), 0) }}</div>
														</div>
														<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data">
															<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_label" v-html="$store.getters.getLanguageText('3.3', 16)"></div>
															<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_value">{{ $parent.$parent.getFloorAmount(value3.temp_in_length, 8) }}</div>
														</div>
														<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data">
															<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_label" v-html="$store.getters.getLanguageText('3.3', 15)"></div>
															<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_value" v-html="value2.event_name"></div>
														</div>
														<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data" v-if="isNewsCheckCondition1(value3.temp_max_distances)">
															<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_label" v-html="$store.getters.getLanguageText('3.3', 9)"></div>
															<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_value red" v-html="$store.getters.getLanguageText('3.3', 10)"></div>
														</div>
														<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data" v-if="isNewsCheckCondition2(value3.temp_max_lengths, value3.temp_max_distances)">
															<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_label" v-html="$store.getters.getLanguageText('3.3', 11)"></div>
															<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_value green" v-html="$store.getters.getLanguageText('3.3', 12)"></div>
														</div>
														<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data" v-if="isNewsCheckCondition3(value3.temp_max_lengths, value3.temp_max_distances)">
															<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_label" v-html="$store.getters.getLanguageText('3.3', 13)"></div>
															<div class="clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_value red" v-html="$store.getters.getLanguageText('3.3', 14)"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div v-if="value.results_data[index2] && value.results_data[index2].limit_offset">
											<div class="clv_account_desktop_news_data_accordion_item_block_body_loading" :class="{ hide: !value.results_data[index2].is_loading }"><img src="@/assets/images/loading.gif"></div>
											<div class="clv_account_desktop_news_data_accordion_item_block_body_buttons" :class="{ hide: value.results_data[index2].is_loading }">
												<button type="button" class="clv_button big" @click="axios('getNewsResultsData', index, index2)"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 15)"></span></button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-html="$store.getters.getLanguageText('3.3', 2, { options: [index] })" v-else></div>
				</div>
				<div v-if="value.limit_offset">
					<div class="clv_account_desktop_portlet_loading" :class="{ hide: !value.is_loading }"><img src="@/assets/images/loading.gif"></div>
					<div class="clv_account_desktop_portlet_buttons" :class="{ hide: value.is_loading }">
						<button type="button" class="clv_button big" @click="axios('getNewsData', index)"><font-awesome-icon :icon="['fas', 'arrow-down']" /><span v-html="$store.getters.getLanguageText('1.1', 15)"></span></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import StarRating from 'vue-star-rating'
	import axios from 'axios'
	export default {
		data: () => ({
			clvNewsData: [],
			clvNewsDataRatingTooltipOptionsIndex: 0,
			clvNewsDataTimeframes: ['m1','m5','m15'],
			clvIsNewsDataHideLowVolatility: true,
			clvIsShowInstruction: false,
			clvIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.clvIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$cookies.get('clvIsNewsDataHideLowVolatility')) {
					this.clvIsNewsDataHideLowVolatility = (this.$cookies.get('clvIsNewsDataHideLowVolatility') == 'true' ? true : false);
				}
				this.$cookies.set('clvIsNewsDataHideLowVolatility', this.clvIsNewsDataHideLowVolatility, -1);
				this.getNewsData();
			},
			resetNewsData: function() {
				this.clvNewsData = [
					{ data: [], limit_offset: 0, limit_count: 1000, results_data: {}, is_loading: false },
					{ data: [], limit_offset: 0, limit_count: 20, results_data: {}, is_loading: false }
				];
			},
			getNewsData: function() {
				this.resetNewsData();
				this.axios('getNewsData');
			},
			axios: function(type, var1, var2, var3, var4, var5, var6) {

				var _this = this;
				if (localStorage.getItem('clvToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.clvApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('clvToken') }
					};

					if (type == 'getNewsData') {
						if (var1 !== undefined) {
							_this.clvNewsData[var1].is_loading = true;
							config.url = '/v2/account/' + (var1 ? 'past' : 'future') + '/news';
							config.params = { limit_offset: _this.clvNewsData[var1].limit_offset, limit_count: _this.clvNewsData[var1].limit_count, filter_conds: { is_hide_low_volatility: Number(_this.clvIsNewsDataHideLowVolatility) }};
						}
						else {
							_this.clvIsLoading = true;
							_this.$store.state.clvIsAccount = true;
							config.url = '/v2/account/news';
							config.params = { limit_offsets: JSON.stringify([_this.clvNewsData[0].limit_offset, _this.clvNewsData[1].limit_offset]), limit_counts: JSON.stringify([_this.clvNewsData[0].limit_count, _this.clvNewsData[1].limit_count]), filter_conds: JSON.stringify([{ is_hide_low_volatility: Number(_this.clvIsNewsDataHideLowVolatility) }, { is_hide_low_volatility: Number(_this.clvIsNewsDataHideLowVolatility) }]) };
						}
					}
					else if (type == 'getNewsResultsData') {
						_this.$set(_this.clvNewsData[var1].results_data[var2], 'is_loading', true);
						config.url = '/v2/account/past/news/results';
						config.params = { symbol: _this.clvNewsData[var1].data[var2].symbol, event_attr_id: _this.clvNewsData[var1].data[var2].event_attr_id, limit_offset: _this.clvNewsData[var1].results_data[var2].limit_offset, limit_count: _this.clvNewsData[var1].results_data[var2].limit_count };
					}
					else if (type == 'setNewsRating') {
						config.url = '/v2/account/news/rating';
						config.data = { symbol: _this.clvNewsData[var1].data[var3].symbol, event_attr_id: _this.clvNewsData[var1].data[var3].event_attr_id, event_timestamp_at: _this.clvNewsData[var1].data[var3].event_timestamp_at, rating: var2, distance: var4, timeframe: var5, is_movement: var6 };
						config.method = 'patch';
					}
					else if (type == 'setNewsDistance') {
						_this.$set(_this.clvNewsData[var1].data[var3], 'news_data_control_is_loading', true);
						config.url = '/v2/account/news/distance';
						config.data = { symbol: _this.clvNewsData[var1].data[var3].symbol, event_attr_id: _this.clvNewsData[var1].data[var3].event_attr_id, event_timestamp_at: _this.clvNewsData[var1].data[var3].event_timestamp_at, distance: var2 };
						config.method = 'patch';
					}
					else if (type == 'setNewsTimeframe') {
						config.url = '/v2/account/news/timeframe';
						config.data = { symbol: _this.clvNewsData[var1].data[var3].symbol, event_attr_id: _this.clvNewsData[var1].data[var3].event_attr_id, event_timestamp_at: _this.clvNewsData[var1].data[var3].event_timestamp_at, timeframe: var2 };
						config.method = 'patch';
					}
					else if (type == 'setNewsIsMovement') {
						config.url = '/v2/account/news/is_movement';
						config.data = { symbol: _this.clvNewsData[var1].data[var3].symbol, event_attr_id: _this.clvNewsData[var1].data[var3].event_attr_id, event_timestamp_at: _this.clvNewsData[var1].data[var3].event_timestamp_at, is_movement: var2 };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getNewsData') {
								_this.$parent.$parent.goToTop((!_this.clvNewsData[0].limit_offset && !_this.clvNewsData[1].limit_offset) ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.future_news_data !== undefined) {
									if (data.data.future_news_data.data.length) {
										for (var i in data.data.future_news_data.data) {
											_this.clvNewsData[0].data.push(_this.getNewsProcessedData(data.data.future_news_data.data[i]));
										}
									}
									_this.clvNewsData[0].limit_offset = data.data.future_news_data.count > _this.clvNewsData[0].limit_offset + _this.clvNewsData[0].limit_count ? _this.clvNewsData[0].limit_offset + _this.clvNewsData[0].limit_count : 0;
								}
								if (data.data.past_news_data !== undefined) {
									if (data.data.past_news_data.data.length) {
										for (var j in data.data.past_news_data.data) {
											_this.clvNewsData[1].data.push(_this.getNewsProcessedData(data.data.past_news_data.data[j]));
										}
									}
									_this.clvNewsData[1].limit_offset = data.data.past_news_data.count > _this.clvNewsData[1].limit_offset + _this.clvNewsData[1].limit_count ? _this.clvNewsData[1].limit_offset + _this.clvNewsData[1].limit_count : 0;
								}
							}
							else if (type == 'getNewsResultsData') {
								if (data.data.news_results_data !== undefined) {
									if (data.data.news_results_data.data.length) {
										for (var m in data.data.news_results_data.data) {
											var in_length = 0, max_delta = 0, max_lengths = {}, max_distances = {}, chart_color = '';
											var candles = data.data.news_results_data.data[m].collected_candles, series = [];
											if (candles.length) {
												for (var n in candles) {
													if (Number(candles[n].pos) == 0) {
														in_length = (candles[n].in > candles[n].out ? (candles[n].max - candles[n].in) : (candles[n].in - candles[n].min));
														max_delta = candles[n].mxd; chart_color = ((candles[n].out >= candles[n].in) ? '#1EC455' : '#F45B5B');
													}
													max_lengths[candles[n].pos] = Math.abs(candles[n].out - candles[n].in);
													max_distances[candles[n].pos] = Math.abs(candles[n].max - candles[n].min);
													series.push([(parseInt(Number(data.data.news_results_data.data[m].event_timestamp_at) + (15 * 60 * Number(candles[n].pos))) * 1000), candles[n].in, candles[n].max, candles[n].min, candles[n].out]);
												}
											}
											data.data.news_results_data.data[m].temp_in_length = in_length;
											data.data.news_results_data.data[m].temp_max_delta = max_delta;
											data.data.news_results_data.data[m].temp_max_lengths = max_lengths;
											data.data.news_results_data.data[m].temp_max_distances = max_distances;
											data.data.news_results_data.data[m].temp_chart_options = {
												rangeSelector: { enabled: false }, navigator: { enabled: false }, scrollbar: { enabled: false },
												xAxis: { plotLines: [{ color: chart_color, dashStyle: 'ShortDash', value: (parseInt(Number(data.data.news_results_data.data[m].event_timestamp_at)) * 1000), width: 1, zIndex: 3 }] },
												series: [{ type: 'candlestick', color: '#F45B5B', upColor: '#1EC455', data: series, tooltip: { pointFormat: '<div style="padding:10px;">Open: {point.open}<br>Close: {point.close}<br>Min: {point.low}<br>Max: {point.high}</div>' }}]
											};
											_this.clvNewsData[var1].results_data[var2].data.push(data.data.news_results_data.data[m]);
										}
									}
									_this.clvNewsData[var1].results_data[var2].count = data.data.news_results_data.count;
									_this.clvNewsData[var1].results_data[var2].limit_offset = data.data.news_results_data.count > _this.clvNewsData[var1].results_data[var2].limit_offset + _this.clvNewsData[var1].results_data[var2].limit_count ? _this.clvNewsData[var1].results_data[var2].limit_offset + _this.clvNewsData[var1].results_data[var2].limit_count : 0;
								}
							}
							else if (type == 'setNewsDistance') {
								_this.$set(_this.clvNewsData[var1].data[var3], 'news_data_old_distance', JSON.stringify('distance_' + _this.clvNewsData[var1].data[var3].news_data_new_distance));
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						if (type == 'getNewsData') {
							if (var1 !== undefined) {
								_this.clvNewsData[var1].is_loading = false;
							}
						}
						else if (type == 'getNewsResultsData') {
							_this.$set(_this.clvNewsData[var1].data[var2], 'news_results_data_is_loading', false);
							_this.$set(_this.clvNewsData[var1].data[var2], 'news_results_data_is_show', true);
							_this.$set(_this.clvNewsData[var1].results_data[var2], 'is_loading', false);
						}
						else if (type == 'setNewsDistance') {
							_this.$set(_this.clvNewsData[var1].data[var3], 'news_data_control_is_loading', false);
						}
						_this.clvIsLoading = false;
						_this.$store.state.clvIsAccount = false;
						_this.$store.state.clvIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			isNewsCheckCondition1: function(maxDistances) {
				var isCheck1 = false, isCheck2 = false, isCheck3 = false, isCheck4 = false;
				if (Object.keys(maxDistances).length && maxDistances[0] !== undefined && Number(maxDistances[0]) !== 0) {
					isCheck1 = ((maxDistances[-2] !== undefined && ((Number(maxDistances[-2]) * 100 / Number(maxDistances[0])) >= 80)) ? true : false);
					isCheck2 = ((maxDistances[-1] !== undefined && ((Number(maxDistances[-1]) * 100 / Number(maxDistances[0])) >= 80)) ? true : false);
					isCheck3 = ((maxDistances[1] !== undefined && ((Number(maxDistances[1]) * 100 / Number(maxDistances[0])) >= 80)) ? true : false);
					isCheck4 = ((maxDistances[2] !== undefined && ((Number(maxDistances[2]) * 100 / Number(maxDistances[0])) >= 80)) ? true : false);
				}
				return ((isCheck1 || isCheck2 || isCheck3 || isCheck4) ? true : false);
			},
			isNewsCheckCondition2: function(maxLengths, maxDistances) {
				var isCheck = false;
				if (!this.isNewsCheckCondition1(maxDistances)) {
					if (Object.keys(maxLengths).length && maxLengths[0] !== undefined) {
						if (Object.keys(maxDistances).length && maxDistances[0] !== undefined && Number(maxDistances[0]) !== 0) {
							if ((Number(maxLengths[0]) / Number(maxDistances[0])) >= 0.9) { isCheck = true; }
						}
					}
				}
				return isCheck;
			},
			isNewsCheckCondition3: function(maxLengths, maxDistances) {
				var isCheck = false;
				if (Object.keys(maxLengths).length && maxLengths[0] !== undefined) {
					if (Object.keys(maxDistances).length && maxDistances[0] !== undefined && Number(maxDistances[0]) !== 0) {
						if (((Number(maxLengths[0]) * 100) / Number(maxDistances[0])) <= 50) { isCheck = true; }
					}
				}
				return isCheck;
			},
			setNewsData: function(type, index) {
				if (!this.$store.state.clvAccountData.group) {
					if (JSON.stringify('distance_' + this.clvNewsData[type].data[index].news_data_new_distance) !== this.clvNewsData[type].data[index].news_data_old_distance) {
						this.setNewsDistance(type, this.clvNewsData[type].data[index].news_data_new_distance, index);
					}
				}
			},
			setNewsRating: function(type, value, index) {
				if (!this.$store.state.clvAccountData.group) {
					var rating = Number(value), distance = (this.clvNewsData[type].data[index].distances[this.$store.state.clvAccountData.id] !== undefined ? this.clvNewsData[type].data[index].distances[this.$store.state.clvAccountData.id] : this.clvNewsData[type].data[index].distance_), timeframe = (this.clvNewsData[type].data[index].timeframes[this.$store.state.clvAccountData.id] !== undefined ? this.clvNewsData[type].data[index].timeframes[this.$store.state.clvAccountData.id] : this.clvNewsDataTimeframes[(Number(this.clvNewsData[type].data[index].event_type) == 1 ? 2 : 0)]), is_movement = (this.clvNewsData[type].data[index].is_movements[this.$store.state.clvAccountData.id] !== undefined ? this.clvNewsData[type].data[index].is_movements[this.$store.state.clvAccountData.id] : 0);
					this.$set(this.clvNewsData[type].data[index].ratings, this.$store.state.clvAccountData.id, rating);
					this.$set(this.clvNewsData[type].data[index].distances, this.$store.state.clvAccountData.id, distance);
					this.$set(this.clvNewsData[type].data[index].timeframes, this.$store.state.clvAccountData.id, timeframe);
					this.$set(this.clvNewsData[type].data[index].is_movements, this.$store.state.clvAccountData.id, is_movement);
					this.$set(this.clvNewsData[type].data[index], 'news_data_rating', rating);
					this.$set(this.clvNewsData[type].data[index], 'news_data_new_distance', distance);
					this.$set(this.clvNewsData[type].data[index], 'news_data_old_distance', JSON.stringify('distance_' + this.clvNewsData[type].data[index].news_data_new_distance));
					this.$set(this.clvNewsData[type].data[index], 'news_data_timeframe', timeframe);
					this.$set(this.clvNewsData[type].data[index], 'news_data_is_movement', is_movement);
					this.axios('setNewsRating', type, Number(value), index, distance, timeframe, is_movement);
				}
			},
			setNewsDistance: function(type, value, index) {
				event.target.blur();
				if (!this.$store.state.clvAccountData.group) {
					if (!value || !this.$store.state.clvRegex_d.test(value) || Number(value) < 1) {
						value = 0;
					}
					else if (value < Number(this.$store.state.clvSystemSettings['news_distance_min'])) {
						value = Number(this.$store.state.clvSystemSettings['news_distance_min']);
					}
					else if (value > Number(this.$store.state.clvSystemSettings['news_distance_max'])) {
						value = Number(this.$store.state.clvSystemSettings['news_distance_max']);
					}
					this.$set(this.clvNewsData[type].data[index].distances, this.$store.state.clvAccountData.id, Number(value));
					this.$set(this.clvNewsData[type].data[index], 'news_data_new_distance', Number(value ? value : this.clvNewsData[type].data[index].distance_));
					this.axios('setNewsDistance', type, Number(value), index);
				}
			},
			setNewsTimeframe: function(type, value, index) {
				this.$parent.$parent.closeCSelect();
				if (!this.$store.state.clvAccountData.group) {
					this.$set(this.clvNewsData[type].data[index].timeframes, this.$store.state.clvAccountData.id, value);
					this.$set(this.clvNewsData[type].data[index], 'news_data_timeframe', value);
					this.axios('setNewsTimeframe', type, value, index);
				}
			},
			setNewsIsMovement: function(type, value, index) {
				if (!this.$store.state.clvAccountData.group) {
					this.$set(this.clvNewsData[type].data[index].is_movements, this.$store.state.clvAccountData.id, Number(value));
					this.$set(this.clvNewsData[type].data[index], 'news_data_is_movement', Number(value));
					this.axios('setNewsIsMovement', type, Number(value), index);
				}
			},
			getNewsProcessedData: function(data) {
				data.news_data_rating = (this.$store.state.clvAccountData.group == 1 ? Math.floor(data.rating) : (data.ratings[this.$store.state.clvAccountData.id] !== undefined ? data.ratings[this.$store.state.clvAccountData.id] : 0));
				data.news_data_new_distance = (this.$store.state.clvAccountData.group == 1 ? (data.distance ? data.distance : ((data.distance_ >= this.$store.state.clvSystemSettings['news_distance_rmin'] && data.distance_ <= this.$store.state.clvSystemSettings['news_distance_max']) ? data.distance_ : '')) : (data.distances[this.$store.state.clvAccountData.id] !== undefined ? data.distances[this.$store.state.clvAccountData.id] : ((data.distance_ >= this.$store.state.clvSystemSettings['news_distance_rmin'] && data.distance_ <= this.$store.state.clvSystemSettings['news_distance_max']) ? data.distance_ : '')));
				data.news_data_old_distance = JSON.stringify('distance_' + data.news_data_new_distance);
				data.news_data_timeframe = (this.$store.state.clvAccountData.group == 1 ? data.timeframe : (data.timeframes[this.$store.state.clvAccountData.id] !== undefined ? data.timeframes[this.$store.state.clvAccountData.id] : this.clvNewsDataTimeframes[(Number(data.event_type) == 1 ? 2 : 0)]));
				data.news_data_is_movement = (this.$store.state.clvAccountData.group == 1 ? data.is_movement : (data.is_movements[this.$store.state.clvAccountData.id] !== undefined ? data.is_movements[this.$store.state.clvAccountData.id] : 0));
				data.news_data_control_is_loading = false;
				data.news_results_data_is_show = false;
				data.news_results_data_is_loading = false;
				return data;
			},
			getNewsInstruction: function() {
				var instruction = this.$store.getters.getLanguageText('3.3', 1);
				return (this.clvIsShowInstruction ? instruction : instruction.match(/<p>(.*?)<\/p>/gi)[0]);
			},
			toggleNewsResults: function(type, index) {
				if (this.clvNewsData[type].data[index].news_results_data_is_show) {
					this.$set(this.clvNewsData[type].data[index], 'news_results_data_is_loading', false);
					this.$set(this.clvNewsData[type].data[index], 'news_results_data_is_show', false);
					delete this.clvNewsData[type].results_data[index];
				}
				else {
					this.$set(this.clvNewsData[type].data[index], 'news_results_data_is_loading', true);
					this.$set(this.clvNewsData[type].results_data, index, { count: 0, data: [], limit_offset: 0, limit_count: 5, is_loading: false });
					this.axios('getNewsResultsData', type, index);
				}
			},
			toggleChangeForIsNewsDataHideLowVolatility: function(event) {
				this.$cookies.set('clvIsNewsDataHideLowVolatility', event.target.checked, -1);
				this.clvIsNewsDataHideLowVolatility = event.target.checked;
				this.getNewsData();
			}
		},
		components: {
			StarRating
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.clv_account_desktop_news_instruction_button {
		margin: 5px 0 0 0;
		text-align: right;
	}
	.clv_account_desktop_news_data_accordion_item {
		margin: 0 0 5px 0;
	}
	.clv_account_desktop_news_data_accordion_item:last-child {
		margin: 0;
	}
	.clv_account_desktop_news_data_accordion_item_date {
		margin: 10px 0;
		border-radius: 5px;
		background: #FFFFFF;
		color: #333333;
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 11px;
		line-height: 18px;
		display: table;
		width: 100%;
		height: 20px;
	}
	.clv_account_desktop_news_data_accordion_item:first-child .clv_account_desktop_news_data_accordion_item_date {
		margin: 0 0 10px 0;
	}
	.clv_account_desktop_news_data_accordion_item_block {
		border: 1px solid #999999;
		border-radius: 10px;
		background: #444444E6;
	}
	.clv_account_desktop_news_data_accordion_item_block_head {
		position: relative;
		padding: 10px 50px 10px 20px;
		font-size: 12px;
		line-height: 20px;
		min-height: 40px;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_toggle {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		cursor: pointer;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content {
		display: table;
		width: 100%;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_info,
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_date,
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country,
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_symbol,
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_volatility,
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name,
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools,
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_rating,
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_distance,
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_timeframe,
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_is_movement {
		float: left;
		position: relative;
		margin: 0 0 0 10px;
		display: inline-block;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_info {
		margin: 0;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_date {
		margin: 0;
		color: #ABFFAB;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_date .sticker {
		position: relative;
		top: -2px;
		margin: 0 5px 0 0;
		padding: 0 5px;
		border-radius: 8px;
		background: #FF4040;
		color: #FFFFFF;
		font-size: 10px;
		line-height: 14px;
		display: inline-block;
		height: 16px;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country {
		margin: 3px 0 0 10px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		background: url('../../assets/images/flags.png') no-repeat;
		background-position: -34px -68px;
		cursor: pointer;
		width: 16px;
		height: 15px;
		z-index: 2;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.ars { background-position: 0 -204px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.aud { background-position: 0 -255px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.brl { background-position: 0 -527px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.cad { background-position: 0 -680px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.clp { background-position: 0 -765px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.cny { background-position: 0 -782px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.cop { background-position: 0 -799px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.czk { background-position: 0 -986px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.dkk { background-position: 0 -1003px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.eur { background-position: 0 -1224px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.hkd { background-position: 0 -1598px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.huf { background-position: 0 -1615px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.inr { background-position: 0 -1649px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.idr { background-position: 0 -1666px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.jpy { background-position: 0 -1802px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.myr { background-position: -17px -153px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.mxn { background-position: -17px -289px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.nzd { background-position: -17px -561px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.nok { background-position: -17px -680px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.pen { background-position: -17px -850px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.php { background-position: -17px -867px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.pln { background-position: -17px -884px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.rub { background-position: -17px -1003px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.sgd { background-position: -17px -1207px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.zar { background-position: -17px -1309px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.krw { background-position: -17px -1326px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.sek { background-position: -17px -1462px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.chf { background-position: -17px -1479px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.twd { background-position: -17px -2005px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.thb { background-position: -17px -1581px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.try { background-position: -17px -1683px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.usd { background-position: -17px -1751px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.uah { background-position: -17px -1785px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.gbp { background-position: -17px -1836px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.vef { background-position: -17px -1921px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_country.vnd { background-position: -17px -1938px; }
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_symbol {
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		min-width: 50px;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_volatility {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		cursor: pointer;
		z-index: 2;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_volatility.grey {
		color: #CCCCCC;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_volatility.orange {
		color: #FFD055;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_volatility.red {
		color: #FF9999;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name div {
		float: left;
		margin: 0 10px 0 0;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 600px;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name svg {
		color: #A5E5FF;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools {
		float: right;
		margin: -5px 0;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_br {
		display: none;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_rating,
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_distance,
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_timeframe,
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_is_movement,
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_control {
		margin: 0;
		padding: 0 5px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		height: 30px;
		z-index: 2;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_timeframe.disabled .clv_cselect_toggle,
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_is_movement.disabled {
		cursor: default;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_rating {
		padding: 0 5px 0 0;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_rating .vue-star-rating {
		margin: 2.5px 0 0 0;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_distance {
		padding: 0 0 0 5px;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_distance .clv_input {
		float: left;
		margin: 2px 0;
		padding: 0 10px 2px 10px;
		border: none;
		border-radius: 4px;
		background: #FFFFFF33;
		line-height: 26px;
		width: 50px;
		height: 26px;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_distance.active .clv_input {
		background: #FFD055;
		color: #4E4E4E;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_distance.voted .clv_input {
		border: 1px dashed #FFD055;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_timeframe.open {
		z-index: 3;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_timeframe.active .clv_cselect {
		background: #FFD055;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_timeframe.active .clv_cselect_toggle_value,
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_timeframe.active .clv_cselect_toggle_button {
		color: #4E4E4E;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_timeframe .clv_cselect {
		margin: 2px 0;
		border-radius: 4px;
		min-width: 66px;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_timeframe .clv_cselect.open {
		border-radius: 4px 4px 0 0;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_timeframe .clv_cselect_options_item_content_value {
		padding: 5px 10px;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_is_movement svg {
		margin: 6px 0;
		color: #CCCCCC;
		cursor: pointer;
		font-size: 18px;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_is_movement.active svg {
		color: #FFD055;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_control {
		position: absolute;
		top: 2px;
		left: 251px;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_control_loading,
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_control_buttons {
		display: table;
		width: 40px;
		height: 26px;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_control_loading {
		padding: 1px 0 0 0;
		background: #525252;
		text-align: center;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_control_buttons .clv_button.big {
		margin: 0;
		padding: 0;
		line-height: 22px;
		width: 100%;
		height: 26px;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_control_buttons .clv_button.big:hover {
		opacity: 1;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_content_tools_control_buttons .clv_button.big svg {
		margin: 0;
		font-size: 12px;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_loading,
	.clv_account_desktop_news_data_accordion_item_block_head_buttons {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 50px;
		height: 100%;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_loading img {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -6px 0 0 -8px;
	}
	.clv_account_desktop_news_data_accordion_item_block_head_buttons svg {
		position: absolute;
		top: 50%;
		right: 20px;
		margin: -10px 0 0 0;
		font-size: 20px;
	}
	.clv_account_desktop_news_data_accordion_item_block_body {
		margin: 0 20px 20px 20px;
		padding: 20px 0 0 0;
		border-top: 1px solid #999999;
	}
	.clv_account_desktop_news_data_accordion_item_block_body_content_candles_row {
		margin: 0 0 20px 0;
		padding: 0 0 20px 0;
		border-bottom: 1px solid #999999;
		display: flex;
	}
	.clv_account_desktop_news_data_accordion_item_block_body_content_candles_row.last {
		margin: 0;
		padding: 0;
		border: none;
	}
	.clv_account_desktop_news_data_accordion_item_block_body_content_candles_col:first-child {
		width: 600px;
	}
	.clv_account_desktop_news_data_accordion_item_block_body_content_candles_col:last-child {
		margin: -6px 0 0 0;
		padding: 0 0 0 20px;
		width: 100%;
	}
	.clv_account_desktop_news_data_accordion_item_block_body_content_candles_col .clv_chart {
		margin: 0;
	}
	.clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data {
		font-size: 12px;
		display: table;
		width: 100%;
	}
	.clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_label {
		float: left;
		margin: 0 8px 0 0;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_value.green {
		color: #00FF00;
	}
	.clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_value.red {
		color: #FFBBBB;
	}
	.clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data_value .clv_button {
		position: relative;
		top: -1px;
	}
	.clv_account_desktop_news_data_accordion_item_block_body_loading,
	.clv_account_desktop_news_data_accordion_item_block_body_buttons {
		margin: -5px 0 0 0;
		text-align: right;
	}
	.clv_account_desktop_news_data_accordion_item_block_body_loading {
		line-height: 35px;
		height: 35px;
	}

	@media (max-width: 1650px) {
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name div {
			max-width: 550px;
		}
	}
	@media (max-width: 1600px) {
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name div {
			max-width: 500px;
		}
	}
	@media (max-width: 1550px) {
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name div {
			max-width: 450px;
		}
	}
	@media (max-width: 1500px) {
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name div {
			max-width: 400px;
		}
	}
	@media (max-width: 1435px) {
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name div {
			max-width: 360px;
		}
	}
	@media (max-width: 1400px) {
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name div {
			max-width: 275px;
		}
	}
	@media (max-width: 1300px) {
		.clv_account_desktop_news_data_accordion_item_block_head_content_info,
		.clv_account_desktop_news_data_accordion_item_block_head_content_tools {
			float: none;
			margin: 5px 0 0 0;
			width: 100%;
		}
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name div {
			max-width: 430px;
		}
		.clv_account_desktop_news_data_accordion_item_block_body_content_candles_col:first-child {
			width: 300px;
		}
	}
	@media (max-width: 1200px) {
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name div {
			max-width: 330px;
		}
	}
	@media (max-width: 1100px) {
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name div {
			max-width: 230px;
		}
	}
	@media (max-width: 1000px) {
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name div {
			max-width: 460px;
		}
	}
	@media (max-width: 900px) {
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name div {
			max-width: 360px;
		}
	}
	@media (max-width: 800px) {
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name div {
			max-width: 260px;
		}
	}
	@media (max-width: 700px) {
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_symbol {
			min-width: 0;
		}
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name {
			margin: 5px 0 0 0;
		}
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name div {
			margin: 0;
			white-space: normal;
			display: contents;
			max-width: none;
		}
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name div:after {
			content: '';
			margin: 0 0 0 10px;
		}
		.clv_account_desktop_news_data_accordion_item_block_body_content_candles_row {
			display: inline-block;
			width: 100%;
		}
		.clv_account_desktop_news_data_accordion_item_block_body_content_candles_col {
			width: 100% !important;
		}
		.clv_account_desktop_news_data_accordion_item_block_body_content_candles_col:last-child {
			margin: 12px 0 -5px 0;
			padding: 0;
		}
		.clv_account_desktop_news_data_accordion_item_block_body_content_candles_col_data {
			font-size: 11px;
			line-height: 18px;
		}
	}
	@media (max-width: 550px) {
		.clv_account_desktop_news_data_accordion_item_block_head {
			font-size: 11px;
		}
	}
	@media (max-width: 400px) {
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name {
			margin: 5px 0 -5px 0;
		}
		.clv_account_desktop_news_data_accordion_item_block_head_content_tools_br {
			float: left;
			margin: 5px 0 0 0;
			display: inline-block;
			width: 100%;
		}
		.clv_account_desktop_news_data_accordion_item_block_head_content_tools_timeframe {
			padding: 0 5px 0 0;
		}
	}
	@media (max-width: 390px) {
		.clv_account_desktop_news_data_accordion_item_block_head_content_info {
			min-width: 240px;
		}
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_date {
			font-size: 10px;
		}
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_date .sticker {
			top: -1px;
			border-radius: 7px;
			font-size: 9px;
			line-height: 12px;
			height: 14px;
		}
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_country {
			margin: 3px 0 0 5px;
		}
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_symbol,
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_volatility {
			margin: 0 0 0 5px;
			font-size: 10px;
		}
		.clv_account_desktop_news_data_accordion_item_block_head_content_info_event_name {
			padding: 0 50px 0 0;
			font-size: 10px;
			line-height: 15px;
		}
	}
</style>
