<template>
	<div class="clv_account_desktop_page">
		<div class="clv_account_desktop_page_loading" v-if="clvIsLoading"><div class="clv_loading"></div></div>
		<div class="clv_account_desktop_page_content" v-else>
			<div class="clv_account_desktop_portlet">
				<div class="clv_account_desktop_portlet_title" v-html="$store.getters.getLanguageText('3.2', 0)"></div>
				<div class="clv_account_desktop_portlet_content">
					<div class="clv_account_desktop_portlet_content_row">
						<div class="clv_account_desktop_portlet_content_label">
							<span v-html="$store.getters.getLanguageText('3.2', 1)"></span>
							<font-awesome-icon class="clv_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 0), trigger: $store.state.clvIsMobile ? 'click' : 'click hover' }" />
						</div>
						<div class="clv_account_desktop_portlet_content_value">
							<div class="clv_account_desktop_portlet_content_value_content">{{ $store.state.clvAccountData.id }}</div>
						</div>
					</div>
					<div class="clv_account_desktop_portlet_content_row">
						<div class="clv_account_desktop_portlet_content_label">
							<span v-html="$store.getters.getLanguageText('3.2', 2)"></span>
							<font-awesome-icon class="clv_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 1), trigger: $store.state.clvIsMobile ? 'click' : 'click hover' }" />
						</div>
						<div class="clv_account_desktop_portlet_content_value">
							<div class="clv_account_desktop_portlet_content_value_content">{{ $store.state.clvAccountData.email }}</div>
						</div>
					</div>
					<div class="clv_account_desktop_portlet_content_row">
						<div class="clv_account_desktop_portlet_content_label">
							<span v-html="$store.getters.getLanguageText('3.2', 3)"></span>
							<font-awesome-icon class="clv_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 2), trigger: $store.state.clvIsMobile ? 'click' : 'click hover' }" />
						</div>
						<div class="clv_account_desktop_portlet_content_value">
							<div class="clv_account_desktop_portlet_content_value_editing" v-if="clvSettings.username_is_editing">
								<div class="clv_account_desktop_portlet_content_value_editing_block">
									<form>
										<input type="text" class="clv_input" v-model.trim="clvSettings.username_new" :placeholder="$store.getters.getLanguageText('3.2', 3).toLowerCase()" autocomplete="off">
										<span class="clv_account_desktop_portlet_content_value_loading" :class="{ hide: !clvSettings.username_is_loading }"><img src="@/assets/images/loading.gif"></span>
										<span class="clv_account_desktop_portlet_content_value_buttons" :class="{ hide: clvSettings.username_is_loading }">
											<button type="button" class="clv_button clv_is_desktop red" @click="editSettings('cancel', 'username')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
											<button type="button" class="clv_button clv_is_mobile red" @click="editSettings('cancel', 'username')"><font-awesome-icon :icon="['fas', 'times']" /></button>
											<button type="submit" class="clv_button clv_is_desktop last green" @click="submit($event, 'editSettings', 'username')" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
											<button type="submit" class="clv_button clv_is_mobile last green" @click="submit($event, 'editSettings', 'username')"><font-awesome-icon :icon="['fas', 'check']" /></button>
										</span>
									</form>
								</div>
								<div class="clv_account_desktop_portlet_content_value_editing_alert" v-html="clvSettings.username_alert"></div>
							</div>
							<div class="clv_account_desktop_portlet_content_value_content" v-else>
								<span class="clv_value" v-if="clvSettings.username_old">{{ clvSettings.username_old }}</span>
								<span class="clv_value uppercase" v-else v-html="$store.getters.getLanguageText('3.2', 9)"></span>
								<button type="button" class="clv_button clv_is_desktop last blue" @click="editSettings('edit', 'username')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
								<button type="button" class="clv_button clv_is_mobile last blue" @click="editSettings('edit', 'username')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
							</div>
						</div>
					</div>
					<div class="clv_account_desktop_portlet_content_row">
						<div class="clv_account_desktop_portlet_content_label">
							<span v-html="$store.getters.getLanguageText('3.2', 4)"></span>
							<font-awesome-icon class="clv_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 3), trigger: $store.state.clvIsMobile ? 'click' : 'click hover' }" />
						</div>
						<div class="clv_account_desktop_portlet_content_value">
							<div class="clv_account_desktop_portlet_content_value_editing" v-if="clvSettings.phone_is_editing">
								<div class="clv_account_desktop_portlet_content_value_editing_block">
									<form>
										<input type="text" class="clv_input" v-model.trim="clvSettings.phone_new" :placeholder="$store.getters.getLanguageText('3.2', 4).toLowerCase()" autocomplete="off">
										<span class="clv_account_desktop_portlet_content_value_loading" :class="{ hide: !clvSettings.phone_is_loading }"><img src="@/assets/images/loading.gif"></span>
										<span class="clv_account_desktop_portlet_content_value_buttons" :class="{ hide: clvSettings.phone_is_loading }">
											<button type="button" class="clv_button clv_is_desktop red" @click="editSettings('cancel', 'phone')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
											<button type="button" class="clv_button clv_is_mobile red" @click="editSettings('cancel', 'phone')"><font-awesome-icon :icon="['fas', 'times']" /></button>
											<button type="submit" class="clv_button clv_is_desktop last green" @click="submit($event, 'editSettings', 'phone')" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
											<button type="submit" class="clv_button clv_is_mobile last green" @click="submit($event, 'editSettings', 'phone')"><font-awesome-icon :icon="['fas', 'check']" /></button>
										</span>
									</form>
								</div>
								<div class="clv_account_desktop_portlet_content_value_editing_alert" v-html="clvSettings.phone_alert"></div>
							</div>
							<div class="clv_account_desktop_portlet_content_value_content" v-else>
								<span class="clv_value" v-if="clvSettings.phone_old">{{ clvSettings.phone_old }}</span>
								<span class="clv_value uppercase" v-else v-html="$store.getters.getLanguageText('3.2', 9)"></span>
								<button type="button" class="clv_button clv_is_desktop last blue" @click="editSettings('edit', 'phone')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
								<button type="button" class="clv_button clv_is_mobile last blue" @click="editSettings('edit', 'phone')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
							</div>
						</div>
					</div>
					<div class="clv_account_desktop_portlet_content_row">
						<div class="clv_account_desktop_portlet_content_label">
							<span v-html="$store.getters.getLanguageText('3.2', 5)"></span>
							<font-awesome-icon class="clv_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 4), trigger: $store.state.clvIsMobile ? 'click' : 'click hover' }" />
						</div>
						<div class="clv_account_desktop_portlet_content_value">
							<div class="clv_account_desktop_portlet_content_value_editing" v-if="clvSettings.password_is_editing">
								<div class="clv_account_desktop_portlet_content_value_editing_block">
									<form>
										<input type="password" class="clv_input" v-model.trim="clvSettings.password_old" :placeholder="$store.getters.getLanguageText('3.2', 11).toLowerCase()" autocomplete="off"><br>
										<input type="password" class="clv_input" v-model.trim="clvSettings.password_new" :placeholder="$store.getters.getLanguageText('3.2', 12).toLowerCase()" autocomplete="off"><br>
										<input type="password" class="clv_input" v-model.trim="clvSettings.password_new_repeat" :placeholder="$store.getters.getLanguageText('3.2', 13).toLowerCase()" autocomplete="off">
										<span class="clv_account_desktop_portlet_content_value_loading" :class="{ hide: !clvSettings.password_is_loading }"><img src="@/assets/images/loading.gif"></span>
										<span class="clv_account_desktop_portlet_content_value_buttons" :class="{ hide: clvSettings.password_is_loading }">
											<button type="button" class="clv_button clv_is_desktop red" @click="editSettings('cancel', 'password')" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
											<button type="button" class="clv_button clv_is_mobile red" @click="editSettings('cancel', 'password')"><font-awesome-icon :icon="['fas', 'times']" /></button>
											<button type="submit" class="clv_button clv_is_desktop last green" @click="submit($event, 'editSettings', 'password')" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
											<button type="submit" class="clv_button clv_is_mobile last green" @click="submit($event, 'editSettings', 'password')"><font-awesome-icon :icon="['fas', 'check']" /></button>
										</span>
									</form>
								</div>
								<div class="clv_account_desktop_portlet_content_value_editing_alert" v-html="clvSettings.password_alert"></div>
							</div>
							<div class="clv_account_desktop_portlet_content_value_content" v-else>
								<span class="clv_value">&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;</span>
								<button type="button" class="clv_button clv_is_desktop last blue" @click="editSettings('edit', 'password')" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
								<button type="button" class="clv_button clv_is_mobile last blue" @click="editSettings('edit', 'password')"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
							</div>
						</div>
					</div>
					<div class="clv_account_desktop_portlet_content_row">
						<div class="clv_account_desktop_portlet_content_label">
							<span v-html="$store.getters.getLanguageText('3.2', 7)"></span>
							<font-awesome-icon class="clv_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 5), trigger: $store.state.clvIsMobile ? 'click' : 'click hover' }" />
						</div>
						<div class="clv_account_desktop_portlet_content_value">
							<div class="clv_account_desktop_portlet_content_value_content">
								<span class="clv_value" v-if="$store.state.clvAccountData.country">{{ $store.state.clvAccountData.country }} ({{ clvCountries[$store.state.clvAccountData.country]['name_' + $store.state.clvLanguage] }})</span>
								<span class="clv_value uppercase" v-else v-html="$store.getters.getLanguageText('3.2', 10)"></span>
							</div>
						</div>
					</div>
					<div class="clv_account_desktop_portlet_content_row">
						<div class="clv_account_desktop_portlet_content_label">
							<span v-html="$store.getters.getLanguageText('3.2', 8)"></span>
							<font-awesome-icon class="clv_tooltip_icon" :icon="['fas', 'question-circle']" v-tooltip.auto="{ content: $store.getters.getLanguageText('1.2', 6), trigger: $store.state.clvIsMobile ? 'click' : 'click hover' }" />
						</div>
						<div class="clv_account_desktop_portlet_content_value">
							<div class="clv_account_desktop_portlet_content_value_content">{{ $parent.$parent.getNumberFormatForDollar($store.state.clvAccountData.balance, 2) }}</div>
						</div>
					</div>
				</div>
				<div class="clv_account_desktop_portlet_alert" v-html="clvAlert"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			clvTemp: '',
			clvAlert: '',
			clvSettings: {},
			clvCountries: {},
			clvIsLoading: true,
			clvIsError: false
		}),
		methods: {
			isReady: function() {
				var _this = this;
				if (_this.$store.state.clvIsAccount) {
					setTimeout(_this.isReady, 0); return false;
				}
				setTimeout(function() {
					_this.clvCountries = _this.$parent.$parent.getCountries();
					_this.axios('getAccountData');
				}, 0);
			},
			initData: function() {

				this.$set(this.clvSettings, 'username_old', this.$store.state.clvAccountData.username);
				this.$set(this.clvSettings, 'username_new', this.$store.state.clvAccountData.username);
				this.$set(this.clvSettings, 'username_is_editing', false);
				this.$set(this.clvSettings, 'username_is_loading', false);
				this.$set(this.clvSettings, 'username_alert', '');
				this.$set(this.clvSettings, 'password_old', '');
				this.$set(this.clvSettings, 'password_new', '');
				this.$set(this.clvSettings, 'password_new_repeat', '');
				this.$set(this.clvSettings, 'password_is_editing', false);
				this.$set(this.clvSettings, 'password_is_loading', false);
				this.$set(this.clvSettings, 'password_alert', '');
				this.$set(this.clvSettings, 'phone_old', this.$store.state.clvAccountData.phone);
				this.$set(this.clvSettings, 'phone_new', this.$store.state.clvAccountData.phone);
				this.$set(this.clvSettings, 'phone_is_editing', false);
				this.$set(this.clvSettings, 'phone_is_loading', false);
				this.$set(this.clvSettings, 'phone_alert', '');
			},
			resetAlerts: function() {
				this.clvAlert = '';
			},
			getError: function(i) {

				this.clvIsError = true;

				if      (i == 1) { this.clvTemp = this.$store.getters.getLanguageText('1.3.1', 12); }
				else if (i == 2) { this.clvTemp = this.$store.getters.getLanguageText('1.3.1', 13); }
				else if (i == 3) { this.clvTemp = this.$store.getters.getLanguageText('1.3.1', 25); }
				else if (i == 4) { this.clvTemp = this.$store.getters.getLanguageText('1.3.1', 26); }
				else if (i == 5) { this.clvTemp = this.$store.getters.getLanguageText('1.3.1', 17); }
				else if (i == 6) { this.clvTemp = this.$store.getters.getLanguageText('1.3.1', 27); }
				else if (i == 7) { this.clvTemp = this.$store.getters.getLanguageText('1.3.1', 28); }
				else if (i == 8) { this.clvTemp = this.$store.getters.getLanguageText('1.3.1', 29); }
				else if (i == 9) { this.clvTemp = this.$store.getters.getLanguageText('1.3.1', 30); }
				else if (i == 10) { this.clvTemp = this.$store.getters.getLanguageText('1.3.1', 31); }
				else if (i == 11) { this.clvTemp = this.$store.getters.getLanguageText('1.3.1', 8); }
				else if (i == 12) { this.clvTemp = this.$store.getters.getLanguageText('1.3.1', 9); }
				else              { this.clvTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.clvTemp + '</div>';
			},
			submit: function(event, type, var1) {
				event.preventDefault(); this.axios(type, var1);
			},
			axios: function(type, var1) {

				var _this = this;
				if (localStorage.getItem('clvToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.clvApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('clvToken') }
					};

					_this.resetAlerts();
					_this.clvIsError = false;

					if (type == 'getAccountData') {
						_this.clvIsLoading = true;
						_this.$store.state.clvIsAccount = true;
						config.url = '/v2/account';
					}
					else if (type == 'editSettings') {
						if (['username', 'password', 'phone'].includes(var1)) {
							_this.clvSettings[var1 + '_alert'] = '';
							if (var1 == 'username') {
								if (!_this.clvSettings.username_new) {
									_this.clvSettings[var1 + '_alert'] = _this.getError(1);
								}
								else if (!_this.$store.state.clvRegexUsername.test(_this.clvSettings.username_new)) {
									_this.clvSettings[var1 + '_alert'] = _this.getError(2);
								}
								else if (_this.clvSettings.username_new == _this.clvSettings.username_old) {
									_this.editSettings('cancel', var1); return false;
								}
								if (!_this.clvIsError) {
									_this.clvSettings[var1 + '_is_loading'] = true;
									config.url = '/v2/account/users/username';
									config.data = { username: _this.clvSettings.username_new };
									config.method = 'patch';
								}
							}
							else if (var1 == 'password') {
								if (!_this.clvSettings.password_old) {
									_this.clvSettings[var1 + '_alert'] = _this.getError(3);
								}
								else if (!_this.$store.state.clvRegexPassword.test(_this.clvSettings.password_old)) {
									_this.clvSettings[var1 + '_alert'] = _this.getError(4);
								}
								else if (!_this.clvSettings.password_new) {
									_this.clvSettings[var1 + '_alert'] = _this.getError(5);
								}
								else if (!_this.$store.state.clvRegexPassword.test(_this.clvSettings.password_new) || _this.clvSettings.password_new.search(_this.$store.state.clvRegexSearchENRULetters) < 0) {
									_this.clvSettings[var1 + '_alert'] = _this.getError(6);
								}
								else if (!_this.clvSettings.password_new_repeat) {
									_this.clvSettings[var1 + '_alert'] = _this.getError(8);
								}
								else if (_this.clvSettings.password_new && _this.clvSettings.password_new_repeat && _this.clvSettings.password_new != _this.clvSettings.password_new_repeat) {
									_this.clvSettings[var1 + '_alert'] = _this.getError(9);
								}
								else if (_this.clvSettings.password_new && _this.clvSettings.password_old && _this.clvSettings.password_new == _this.clvSettings.password_old) {
									_this.clvSettings[var1 + '_alert'] = _this.getError(10);
								}
								if (!_this.clvIsError) {
									_this.clvSettings[var1 + '_is_loading'] = true;
									config.url = '/v2/account/users/password';
									config.data = { old_password: _this.clvSettings.password_old, new_password: _this.clvSettings.password_new };
									config.method = 'patch';
								}
							}
							else if (var1 == 'phone') {
								if (!_this.clvSettings.phone_new) {
									_this.clvSettings[var1 + '_alert'] = _this.getError(11);
								}
								else if (!_this.$store.state.clvRegexPhone.test(_this.clvSettings.phone_new)) {
									_this.clvSettings[var1 + '_alert'] = _this.getError(12);
								}
								else if (_this.clvSettings.phone_new == _this.clvSettings.phone_old) {
									_this.editSettings('cancel', var1); return false;
								}
								if (!_this.clvIsError) {
									_this.clvSettings[var1 + '_is_loading'] = true;
									config.url = '/v2/account/users/phone';
									config.data = { phone: _this.clvSettings.phone_new };
									config.method = 'patch';
								}
							}
						}
					}

					if (!_this.clvIsError) {
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'getAccountData') {
									_this.$parent.$parent.goToTop(true);
									_this.$parent.$parent.checkAppVersion(data.headers);
									if (data.data.account_data !== undefined) {
										_this.$parent.$parent.initAccountData(data.data.account_data); _this.initData();
									}
									if (data.data.system_settings !== undefined) {
										_this.$parent.$parent.initSystemSettings(data.data.system_settings);
									}
								}
								else if (type == 'editSettings') {
									if (['username', 'password', 'phone'].includes(var1)) {
										if (var1 == 'password') {
											_this.clvSettings[var1 + '_old'] = '';
											_this.clvSettings[var1 + '_new'] = '';
											_this.clvSettings[var1 + '_new_repeat'] = '';
										}
										else {
											_this.$store.state.clvAccountData[var1] = _this.clvSettings[var1 + '_new'];
											_this.clvSettings[var1 + '_old'] = _this.clvSettings[var1 + '_new'];
										}
										_this.editSettings('save', var1);
									}
								}
							}
						}).catch(function(data) {
							if (type == 'editSettings') {
								if (data.response !== undefined) {
									if (data.response.status == 400) {
										if      (data.response.data.error == 'USERNAME_IS_EMPTY') { _this.clvSettings[var1 + '_alert'] = _this.getError(1); }
										else if (data.response.data.error == 'USERNAME_NOT_VALID') { _this.clvSettings[var1 + '_alert'] = _this.getError(2); }
										else if (data.response.data.error == 'OLD_PASSWORD_IS_EMPTY') { _this.clvSettings[var1 + '_alert'] = _this.getError(3); }
										else if (data.response.data.error == 'OLD_PASSWORD_NOT_VALID') { _this.clvSettings[var1 + '_alert'] = _this.getError(4); }
										else if (data.response.data.error == 'NEW_PASSWORD_IS_EMPTY') { _this.clvSettings[var1 + '_alert'] = _this.getError(5); }
										else if (data.response.data.error == 'NEW_PASSWORD_NOT_VALID') { _this.clvSettings[var1 + '_alert'] = _this.getError(6); }
										else if (data.response.data.error == 'PHONE_IS_EMPTY') { _this.clvSettings[var1 + '_alert'] = _this.getError(11); }
										else if (data.response.data.error == 'PHONE_NOT_VALID') { _this.clvSettings[var1 + '_alert'] = _this.getError(12); }
									}
									else if (data.response.status == 404) {
										if (data.response.data.error == 'OLD_PASSWORD_NOT_FOUND') { _this.clvSettings[var1 + '_alert'] = _this.getError(7); }
									}
									else if (data.response.status == 409) {
										if (data.response.data.error == 'PASSWORDS_MATCH') { _this.clvSettings[var1 + '_alert'] = _this.getError(10); }
									}
								}
								if (!_this.clvIsError) {
									_this.clvAlert = _this.getError(0);
								}
							}
							else {
								_this.$parent.$parent.exit();
							}
						}).finally(function() {
							_this.clvIsLoading = false;
							_this.$store.state.clvIsAccount = false;
							_this.$store.state.clvIsLoading = false;
							if (type == 'editSettings') { _this.clvSettings[var1 + '_is_loading'] = false; }
						});
					}
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			editSettings: function(type, stype) {
				this.clvSettings[stype + '_new'] = this.clvSettings[stype + '_old'];
				this.clvSettings[stype + '_is_editing'] = (type == 'edit' ? true : false);
				this.clvSettings[stype + '_is_loading'] = false;
				this.clvSettings[stype + '_alert'] = '';
				this.clvAlert = '';
			}
		},
		created: function() {
			this.isReady();
		}
	}
</script>
