import Vue from 'vue'
import VModal from 'vue-js-modal'
import VSelect from 'vue-select'
import VTooltip from 'v-tooltip'
import VueSlider from 'vue-slider-component'
import Lightbox from 'vue-easy-lightbox'
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsStockInit from 'highcharts/modules/stock'
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge'
import ToggleButton from 'vue-js-toggle-button'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
	faAngleUp, faAngleDown, faArrowDown, faAsterisk, faBars, faBullhorn, faChartLine, faCheck, faChevronDown,
	faChevronUp, faCog, faCompass, faExclamationCircle, faExclamationTriangle, faEye, faEyeSlash, faFileAlt,
	faFlask, faPlayCircle, faPlus, faPencilAlt, faQuestionCircle, faSave, faSearch, faSignOutAlt, faSyncAlt,
	faTimes
} from '@fortawesome/free-solid-svg-icons'
import {
	faTelegram
} from '@fortawesome/free-brands-svg-icons'
import 'vue-select/dist/vue-select.css'
import 'vue-slider-component/theme/antd.css'

Vue.use(VModal, { dynamic: true })
Vue.use(Lightbox)
Vue.use(VTooltip)
Vue.use(ToggleButton)
Vue.use(HighchartsVue)
HighchartsMore(Highcharts)
HighchartsStockInit(Highcharts)
HighchartsSolidGauge(Highcharts)
Vue.component('v-select', VSelect)
Vue.component('vue-slider', VueSlider)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Highcharts.setOptions({
	title: { text: '', useHTML: true },
	chart: { type: 'line', height: 200, zoomType: 'x' },
	xAxis: { type: 'datetime', gridLineWidth: 1 },
	yAxis: { title: false, tickPixelInterval: 100 },
	global: { useUTC: false },
	legend: { enabled: false },
	tooltip: { padding: 1, useHTML: true, backgroundColor: 'rgba(255,255,255,1)' }
})
library.add(
	faAngleUp, faAngleDown, faArrowDown, faAsterisk, faBars, faBullhorn, faChartLine, faCheck, faChevronDown,
	faChevronUp, faCog, faCompass, faExclamationCircle, faExclamationTriangle, faEye, faEyeSlash, faFileAlt,
	faFlask, faPlayCircle, faPlus, faPencilAlt, faQuestionCircle, faSave, faSearch, faSignOutAlt, faSyncAlt,
	faTelegram, faTimes
)

export default {}
