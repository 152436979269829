import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		clvRegex_w: /^\w+$/,
		clvRegex_d: /^\d+$/,
		clvRegexEmail: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/,
		clvRegexPhone: /^\+[1-9]{1,3}[0-9]{7,10}$/,
		clvRegexCountry: /^[A-Z]{2}$/,
		clvRegexLanguage: /^[a-z]{2}$/,
		clvRegexUsername: /^[a-zа-яё0-9\-_. ]{4,30}$/ui,
		clvRegexPassword: /^[a-zа-яё0-9]{6,30}$/ui,
		clvRegexBalance: /^(-)?\d{1,}(\.\d{1,})?$/,
		clvRegexSymbols: /^[a-z0-9#\-_]{1,10}$/i,
		clvRegexENRULetters: /^[a-zа-яё]+$/ui,
		clvRegexENRULettersAndNumbers: /^[a-zа-яё0-9]+$/ui,
		clvRegexENRULettersAndNumbersAndSpace: /^[a-zа-яё0-9 ]+$/ui,
		clvRegexENRULettersAndNumbersAndMarks: /^[a-zа-яё0-9№%!?.,:;=*+−\-/@_«»()[\]\r\n ]+$/ui,
		clvRegexIsMobile1Part: /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i,
		clvRegexIsMobile2Part: /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i,
		clvRegexSearchENRULetters: /[a-zа-яё]/ui,
		clvRegexSearchRULetters: /[а-яё]/ui,
		clvAppVersion: '1.3.2',
		clvLanguage: 'ru',
		clvLanguages: {},
		clvLang: 'ru',
		clvLangs: ['ru', 'en'],
		clvApiBaseURL: 'https://api.ifclv.com',
		clvSiteName: 'CandleLight Voting',
		clvRecaptchaSiteKey: '6LfuAVMeAAAAAPuo7jqWukmhDokuRXF4P55sqf4n',
		clvCSelectId: '',
		clvIsAccount: false,
		clvIsLoading: false,
		clvIsMobile: false,
		clvIsMobileMenu: false,
		clvIsLanguageRU: false,
		clvIsLanguageEN: false,
		clvAccountData: {},
		clvSystemSettings: {}
	},
	getters: {
		getLanguageText: state => function(type, index, data, language) {
			language = ((language !== undefined && state.clvLangs.includes(language)) ? language : state.clvLanguage);
			var text = state.clvLanguages[language][type][index];
			if (data !== undefined && Object.keys(data).length) {
				if (data.conditions !== undefined) { text = this.getLanguageTextForDetailsConditions(text, data.conditions); }
				if (data.data_arrays !== undefined) { text = this.getLanguageTextForDetailsDataArrays(text, data.data_arrays); }
				if (data.data_lists !== undefined) { text = this.getLanguageTextForDetailsDataLists(text, data.data_lists); }
				if (data.options !== undefined) { text = this.getLanguageTextForDetailsOptions(text, data.options); }
				text = this.getLanguageTextForDetailsVars(text, data);
			}
			return text;
		},
		getLanguageTextForDetailsVars: state => function(text, details) {
			if (Object.keys(state).length) {
				if (Object.keys(details).length) {
					for (var i in details) {
						if (!['index', 'options', 'conditions', 'data_lists', 'data_arrays'].includes(i)) {
							if (Array.isArray(details[i])) { details[i] = details[i].join(', '); }
							text = text.replace(new RegExp('{{ ' + i + ' }}', 'g'), details[i]);
						}
					}
				}
			}
			return text;
		},
		getLanguageTextForDetailsOptions: state => function(text, details) {
			if (Object.keys(state).length) {
				var template = text.match(/\[\[ (.*?) \]\]/g);
				if (template && template.length && details.length) {
					for (var i in template) {
						template[i] = template[i].replace('[[ ', '');
						template[i] = template[i].replace(' ]]', '');
						template[i] = template[i].split('|');
						if (template[i][details[i]] !== undefined) {
							text = text.replace(/\[\[ (.*?) \]\]/, template[i][details[i]]);
						}
					}
				}
			}
			return text;
		},
		getLanguageTextForDetailsConditions: state => function(text, details) {
			if (Object.keys(state).length) {
				var template = text.match(/\(\( (.*?) \)\)/g);
				if (template && template.length && details.length) {
					for (var i in template) {
						template[i] = template[i].replace('(( ', '');
						template[i] = template[i].replace(' ))', '');
						if (details[i] !== undefined) {
							text = text.replace(/\(\( (.*?) \)\)/, (Number(details[i]) ? template[i] : ''));
						}
					}
				}
			}
			return text;
		},
		getLanguageTextForDetailsDataArrays: state => function(text, details) {
			if (Object.keys(state).length) {
				var template = text.match(/\[ARRAY\] (.*?) \[ARRAY\]/g);
				if (template && template.length && details.length) {
					for (var i in template) {
						template[i] = template[i].replace('[ARRAY] ', '');
						template[i] = template[i].replace(' [ARRAY]', '');
						if (details[i] !== undefined && Object.keys(details[i]).length) {
							if (details[i].indexes !== undefined && details[i].indexes.length) {
								if (details[i].separator !== undefined && details[i].separator !== '') {
									var temp = []; template[i] = String(template[i]).split('|');
									if (template[i].length && template[i].length == details[i].indexes.length) {
										for (var j in details[i].indexes) {
											if (details[i].indexes[j]) { temp.push(template[i][j]); }
										}
									}
									text = text.replace(/\[ARRAY\] (.*?) \[ARRAY\]/, (temp.length ? temp.join(details[i].separator) : ''));
								}
							}
						}
					}
				}
			}
			return text;
		},
		getLanguageTextForDetailsDataLists: state => function(text, details) {
			if (Object.keys(state).length) {
				var template = text.match(/\[LIST\] (.*?) \[LIST\]/g);
				if (template && template.length && details.length) {
					for (var i in template) {
						template[i] = template[i].replace('[LIST] ', '');
						template[i] = template[i].replace(' [LIST]', '');
						if (details[i] !== undefined && Object.keys(details[i]).length) {
							if (details[i].data !== undefined && Object.keys(details[i].data).length) {
								if (details[i].count !== undefined && Number(details[i].count) > 0) {
									var temp = '';
									for (var j = 0; j < Number(details[i].count); j++) {
										var temp2 = template[i];
										for (var k in details[i].data) {
											temp2 = (k == 'odata1' ? this.getLanguageTextForDetailsOptions(temp2, [details[i].data[k][j]]) : temp2.replace((new RegExp('{{ ' + k + ' }}', 'g')), details[i].data[k][j]));
										}
										temp += temp2;
									}
									text = text.replace(/\[LIST\] (.*?) \[LIST\]/, temp);
								}
							}
						}
					}
				}
			}
			return text;
		}
	}
})
