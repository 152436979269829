<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  import Highcharts from 'highcharts';
  export default {
    methods: {
      isMobile: function() {
        var _this = this;
        (function(userAgent) {
          if (_this.$store.state.clvRegexIsMobile1Part.test(userAgent) ||
              _this.$store.state.clvRegexIsMobile2Part.test(userAgent.substr(0,4))) {
              _this.$store.state.clvIsMobile = true;
          }
        })(navigator.userAgent || navigator.vendor || window.opera);
      },
      isEventStorage: function() {
        window.addEventListener('storage', this.initEventStorage);
      },
      initEventStorage: function(event) {
        if (event.key == 'clvToken') {
          this.$router.push({ path: (localStorage.getItem('clvToken') ? '/account' : '/login') });
        }
      },
      initAccountData: function(data) {
        if (data !== undefined) {
          this.$store.state.clvAccountData = data;
          this.$store.state.clvAccountData.phone = (data.phone !== '' ? '+' : '') + data.phone;
          if (this.$store.state.clvAccountData.language !== this.$store.state.clvLanguage) {
            this.setLanguage(this.$store.state.clvAccountData.language);
          }
        }
      },
      initSystemSettings: function(settings) {
        if (settings !== undefined) {
          for (var i in settings) {
            this.$store.state.clvSystemSettings[settings[i].key] = settings[i].value;
          }
        }
      },
      initRecaptcha: function() {
        var script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit&hl=' + this.$store.state.clvLanguage; script.async = true; script.defer = true;
        document.body.append(script);
      },
      initLanguage: function() {
        var language = this.$cookies.get('clvLanguage');
        if (!language) { language = (navigator.language || navigator.systemLanguage || navigator.userLanguage).substring(0,2).toLowerCase(); }
        if (!this.$store.state.clvLangs.includes(language)) { language = this.$store.state.clvLang; }
        this.setLanguage(language);
      },
      setLanguage: function(language) {
        this.$store.state.clvLanguage = language;
        this.$store.state.clvLanguages = window.clvLanguages;
        this.$cookies.set('clvLanguage', language, -1);
        Highcharts.setOptions({ lang: this.getChartLang() });
        document.documentElement.setAttribute('lang', language);
      },
      getNumberPad: function(value) {
        return (Number(value) < 0 ? '−' : '') + (Math.abs(value) < 10 ? '0' : '') + Math.abs(value);
      },
      getNumberFormat: function(value, precision) {
        return (Number(value) < 0 ? '−' : '') + parseFloat(Math.abs(value)).toFixed(precision);
      },
      getNumberFormatForDollar: function(value, precision) {
        return (Number(value) < 0 ? '− ' : '') + '$' + parseFloat(Math.abs(value)).toFixed(precision);
      },
      getNumberFormatForPercent: function(value, precision) {
        return (Number(value) < 0 ? '− ' : '') + parseFloat(Math.abs(value)).toFixed(precision) + '%';
      },
      getFloorAmount: function(value, precision) {
        value = value.toString().split('.');
        return Number(value[0] + (value.length > 1 ? '.' + value[1].substr(0, precision) : ''));
      },
      getDeclension: function(value) {
        value = Math.abs(value);
        return ((value % 100 > 4 && value % 100 < 20) ? 2 : [2,0,1,1,1,2][(value % 10 < 5) ? value % 10 : 5]);
      },
      getTimeSeconds: function() {
        return (new Date().getTime()) / 1000;
      },
      getDaysPassed: function(time) {
        return this.getNumberPad(parseInt((this.getTimeSeconds() - Number(time)) / (24 * 60 * 60)));
      },
      getChartLang: function() {
        return {
          loading: this.$store.getters.getLanguageText('1.6', 38),
          resetZoom: this.$store.getters.getLanguageText('1.6', 37),
          resetZoomTitle: this.$store.getters.getLanguageText('1.6', 39),
          months: [
            this.$store.getters.getLanguageText('1.6', 0),
            this.$store.getters.getLanguageText('1.6', 1),
            this.$store.getters.getLanguageText('1.6', 2),
            this.$store.getters.getLanguageText('1.6', 3),
            this.$store.getters.getLanguageText('1.6', 4),
            this.$store.getters.getLanguageText('1.6', 5),
            this.$store.getters.getLanguageText('1.6', 6),
            this.$store.getters.getLanguageText('1.6', 7),
            this.$store.getters.getLanguageText('1.6', 8),
            this.$store.getters.getLanguageText('1.6', 9),
            this.$store.getters.getLanguageText('1.6', 10),
            this.$store.getters.getLanguageText('1.6', 11)
          ],
          shortMonths: [
            this.$store.getters.getLanguageText('1.6', 12),
            this.$store.getters.getLanguageText('1.6', 13),
            this.$store.getters.getLanguageText('1.6', 14),
            this.$store.getters.getLanguageText('1.6', 15),
            this.$store.getters.getLanguageText('1.6', 16),
            this.$store.getters.getLanguageText('1.6', 17),
            this.$store.getters.getLanguageText('1.6', 18),
            this.$store.getters.getLanguageText('1.6', 19),
            this.$store.getters.getLanguageText('1.6', 20),
            this.$store.getters.getLanguageText('1.6', 21),
            this.$store.getters.getLanguageText('1.6', 22),
            this.$store.getters.getLanguageText('1.6', 23)
          ],
          weekdays: [
            this.$store.getters.getLanguageText('1.6', 30),
            this.$store.getters.getLanguageText('1.6', 24),
            this.$store.getters.getLanguageText('1.6', 25),
            this.$store.getters.getLanguageText('1.6', 26),
            this.$store.getters.getLanguageText('1.6', 27),
            this.$store.getters.getLanguageText('1.6', 28),
            this.$store.getters.getLanguageText('1.6', 29)
          ],
          numericSymbols: [
            this.$store.getters.getLanguageText('1.6', 31),
            this.$store.getters.getLanguageText('1.6', 32),
            this.$store.getters.getLanguageText('1.6', 33),
            this.$store.getters.getLanguageText('1.6', 34),
            this.$store.getters.getLanguageText('1.6', 35),
            this.$store.getters.getLanguageText('1.6', 36)
          ]
        };
      },
      getCountries: function() {
        return {
          'AF': { name_ru: this.getLanguageText('1.5.1', 0, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 0, {}, 'en') },
          'AX': { name_ru: this.getLanguageText('1.5.1', 1, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 1, {}, 'en') },
          'AL': { name_ru: this.getLanguageText('1.5.1', 2, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 2, {}, 'en') },
          'DZ': { name_ru: this.getLanguageText('1.5.1', 3, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 3, {}, 'en') },
          'AS': { name_ru: this.getLanguageText('1.5.1', 4, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 4, {}, 'en') },
          'AD': { name_ru: this.getLanguageText('1.5.1', 5, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 5, {}, 'en') },
          'AO': { name_ru: this.getLanguageText('1.5.1', 6, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 6, {}, 'en') },
          'AI': { name_ru: this.getLanguageText('1.5.1', 7, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 7, {}, 'en') },
          'AQ': { name_ru: this.getLanguageText('1.5.1', 8, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 8, {}, 'en') },
          'AG': { name_ru: this.getLanguageText('1.5.1', 9, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 9, {}, 'en') },
          'AR': { name_ru: this.getLanguageText('1.5.1', 10, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 10, {}, 'en') },
          'AM': { name_ru: this.getLanguageText('1.5.1', 11, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 11, {}, 'en') },
          'AW': { name_ru: this.getLanguageText('1.5.1', 12, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 12, {}, 'en') },
          'AU': { name_ru: this.getLanguageText('1.5.1', 13, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 13, {}, 'en') },
          'AT': { name_ru: this.getLanguageText('1.5.1', 14, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 14, {}, 'en') },
          'AZ': { name_ru: this.getLanguageText('1.5.1', 15, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 15, {}, 'en') },
          'BS': { name_ru: this.getLanguageText('1.5.1', 16, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 16, {}, 'en') },
          'BH': { name_ru: this.getLanguageText('1.5.1', 17, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 17, {}, 'en') },
          'BD': { name_ru: this.getLanguageText('1.5.1', 18, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 18, {}, 'en') },
          'BB': { name_ru: this.getLanguageText('1.5.1', 19, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 19, {}, 'en') },
          'BY': { name_ru: this.getLanguageText('1.5.1', 20, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 20, {}, 'en') },
          'BE': { name_ru: this.getLanguageText('1.5.1', 21, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 21, {}, 'en') },
          'BZ': { name_ru: this.getLanguageText('1.5.1', 22, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 22, {}, 'en') },
          'BJ': { name_ru: this.getLanguageText('1.5.1', 23, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 23, {}, 'en') },
          'BM': { name_ru: this.getLanguageText('1.5.1', 24, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 24, {}, 'en') },
          'BT': { name_ru: this.getLanguageText('1.5.1', 25, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 25, {}, 'en') },
          'BO': { name_ru: this.getLanguageText('1.5.1', 26, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 26, {}, 'en') },
          'BQ': { name_ru: this.getLanguageText('1.5.1', 27, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 27, {}, 'en') },
          'BA': { name_ru: this.getLanguageText('1.5.1', 28, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 28, {}, 'en') },
          'BW': { name_ru: this.getLanguageText('1.5.1', 29, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 29, {}, 'en') },
          'BV': { name_ru: this.getLanguageText('1.5.1', 30, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 30, {}, 'en') },
          'BR': { name_ru: this.getLanguageText('1.5.1', 31, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 31, {}, 'en') },
          'IO': { name_ru: this.getLanguageText('1.5.1', 32, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 32, {}, 'en') },
          'BN': { name_ru: this.getLanguageText('1.5.1', 33, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 33, {}, 'en') },
          'BG': { name_ru: this.getLanguageText('1.5.1', 34, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 34, {}, 'en') },
          'BF': { name_ru: this.getLanguageText('1.5.1', 35, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 35, {}, 'en') },
          'BI': { name_ru: this.getLanguageText('1.5.1', 36, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 36, {}, 'en') },
          'KH': { name_ru: this.getLanguageText('1.5.1', 37, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 37, {}, 'en') },
          'CM': { name_ru: this.getLanguageText('1.5.1', 38, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 38, {}, 'en') },
          'CA': { name_ru: this.getLanguageText('1.5.1', 39, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 39, {}, 'en') },
          'CV': { name_ru: this.getLanguageText('1.5.1', 40, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 40, {}, 'en') },
          'KY': { name_ru: this.getLanguageText('1.5.1', 41, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 41, {}, 'en') },
          'CF': { name_ru: this.getLanguageText('1.5.1', 42, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 42, {}, 'en') },
          'TD': { name_ru: this.getLanguageText('1.5.1', 43, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 43, {}, 'en') },
          'CL': { name_ru: this.getLanguageText('1.5.1', 44, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 44, {}, 'en') },
          'CN': { name_ru: this.getLanguageText('1.5.1', 45, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 45, {}, 'en') },
          'CX': { name_ru: this.getLanguageText('1.5.1', 46, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 46, {}, 'en') },
          'CC': { name_ru: this.getLanguageText('1.5.1', 47, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 47, {}, 'en') },
          'CO': { name_ru: this.getLanguageText('1.5.1', 48, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 48, {}, 'en') },
          'KM': { name_ru: this.getLanguageText('1.5.1', 49, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 49, {}, 'en') },
          'CG': { name_ru: this.getLanguageText('1.5.1', 50, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 50, {}, 'en') },
          'CD': { name_ru: this.getLanguageText('1.5.1', 51, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 51, {}, 'en') },
          'CK': { name_ru: this.getLanguageText('1.5.1', 52, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 52, {}, 'en') },
          'CR': { name_ru: this.getLanguageText('1.5.1', 53, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 53, {}, 'en') },
          'CI': { name_ru: this.getLanguageText('1.5.1', 54, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 54, {}, 'en') },
          'HR': { name_ru: this.getLanguageText('1.5.1', 55, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 55, {}, 'en') },
          'CU': { name_ru: this.getLanguageText('1.5.1', 56, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 56, {}, 'en') },
          'CW': { name_ru: this.getLanguageText('1.5.1', 57, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 57, {}, 'en') },
          'CY': { name_ru: this.getLanguageText('1.5.1', 58, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 58, {}, 'en') },
          'CZ': { name_ru: this.getLanguageText('1.5.1', 59, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 59, {}, 'en') },
          'DK': { name_ru: this.getLanguageText('1.5.1', 60, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 60, {}, 'en') },
          'DJ': { name_ru: this.getLanguageText('1.5.1', 61, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 61, {}, 'en') },
          'DM': { name_ru: this.getLanguageText('1.5.1', 62, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 62, {}, 'en') },
          'DO': { name_ru: this.getLanguageText('1.5.1', 63, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 63, {}, 'en') },
          'EC': { name_ru: this.getLanguageText('1.5.1', 64, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 64, {}, 'en') },
          'EG': { name_ru: this.getLanguageText('1.5.1', 65, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 65, {}, 'en') },
          'SV': { name_ru: this.getLanguageText('1.5.1', 66, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 66, {}, 'en') },
          'GQ': { name_ru: this.getLanguageText('1.5.1', 67, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 67, {}, 'en') },
          'ER': { name_ru: this.getLanguageText('1.5.1', 68, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 68, {}, 'en') },
          'EE': { name_ru: this.getLanguageText('1.5.1', 69, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 69, {}, 'en') },
          'ET': { name_ru: this.getLanguageText('1.5.1', 70, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 70, {}, 'en') },
          'FK': { name_ru: this.getLanguageText('1.5.1', 71, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 71, {}, 'en') },
          'FO': { name_ru: this.getLanguageText('1.5.1', 72, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 72, {}, 'en') },
          'FJ': { name_ru: this.getLanguageText('1.5.1', 73, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 73, {}, 'en') },
          'FI': { name_ru: this.getLanguageText('1.5.1', 74, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 74, {}, 'en') },
          'FR': { name_ru: this.getLanguageText('1.5.1', 75, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 75, {}, 'en') },
          'GF': { name_ru: this.getLanguageText('1.5.1', 76, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 76, {}, 'en') },
          'PF': { name_ru: this.getLanguageText('1.5.1', 77, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 77, {}, 'en') },
          'TF': { name_ru: this.getLanguageText('1.5.1', 78, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 78, {}, 'en') },
          'GA': { name_ru: this.getLanguageText('1.5.1', 79, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 79, {}, 'en') },
          'GM': { name_ru: this.getLanguageText('1.5.1', 80, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 80, {}, 'en') },
          'GE': { name_ru: this.getLanguageText('1.5.1', 81, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 81, {}, 'en') },
          'DE': { name_ru: this.getLanguageText('1.5.1', 82, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 82, {}, 'en') },
          'GH': { name_ru: this.getLanguageText('1.5.1', 83, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 83, {}, 'en') },
          'GI': { name_ru: this.getLanguageText('1.5.1', 84, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 84, {}, 'en') },
          'GR': { name_ru: this.getLanguageText('1.5.1', 85, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 85, {}, 'en') },
          'GL': { name_ru: this.getLanguageText('1.5.1', 86, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 86, {}, 'en') },
          'GD': { name_ru: this.getLanguageText('1.5.1', 87, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 87, {}, 'en') },
          'GP': { name_ru: this.getLanguageText('1.5.1', 88, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 88, {}, 'en') },
          'GU': { name_ru: this.getLanguageText('1.5.1', 89, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 89, {}, 'en') },
          'GT': { name_ru: this.getLanguageText('1.5.1', 90, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 90, {}, 'en') },
          'GG': { name_ru: this.getLanguageText('1.5.1', 91, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 91, {}, 'en') },
          'GN': { name_ru: this.getLanguageText('1.5.1', 92, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 92, {}, 'en') },
          'GW': { name_ru: this.getLanguageText('1.5.1', 93, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 93, {}, 'en') },
          'GY': { name_ru: this.getLanguageText('1.5.1', 94, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 94, {}, 'en') },
          'HT': { name_ru: this.getLanguageText('1.5.1', 95, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 95, {}, 'en') },
          'HM': { name_ru: this.getLanguageText('1.5.1', 96, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 96, {}, 'en') },
          'VA': { name_ru: this.getLanguageText('1.5.1', 97, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 97, {}, 'en') },
          'HN': { name_ru: this.getLanguageText('1.5.1', 98, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 98, {}, 'en') },
          'HK': { name_ru: this.getLanguageText('1.5.1', 99, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 99, {}, 'en') },
          'HU': { name_ru: this.getLanguageText('1.5.1', 100, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 100, {}, 'en') },
          'IS': { name_ru: this.getLanguageText('1.5.1', 101, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 101, {}, 'en') },
          'IN': { name_ru: this.getLanguageText('1.5.1', 102, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 102, {}, 'en') },
          'ID': { name_ru: this.getLanguageText('1.5.1', 103, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 103, {}, 'en') },
          'IR': { name_ru: this.getLanguageText('1.5.1', 104, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 104, {}, 'en') },
          'IQ': { name_ru: this.getLanguageText('1.5.1', 105, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 105, {}, 'en') },
          'IE': { name_ru: this.getLanguageText('1.5.1', 106, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 106, {}, 'en') },
          'IM': { name_ru: this.getLanguageText('1.5.1', 107, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 107, {}, 'en') },
          'IL': { name_ru: this.getLanguageText('1.5.1', 108, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 108, {}, 'en') },
          'IT': { name_ru: this.getLanguageText('1.5.1', 109, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 109, {}, 'en') },
          'JM': { name_ru: this.getLanguageText('1.5.1', 110, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 110, {}, 'en') },
          'JP': { name_ru: this.getLanguageText('1.5.1', 111, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 111, {}, 'en') },
          'JE': { name_ru: this.getLanguageText('1.5.1', 112, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 112, {}, 'en') },
          'JO': { name_ru: this.getLanguageText('1.5.1', 113, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 113, {}, 'en') },
          'KZ': { name_ru: this.getLanguageText('1.5.1', 114, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 114, {}, 'en') },
          'KE': { name_ru: this.getLanguageText('1.5.1', 115, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 115, {}, 'en') },
          'KI': { name_ru: this.getLanguageText('1.5.1', 116, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 116, {}, 'en') },
          'KP': { name_ru: this.getLanguageText('1.5.1', 117, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 117, {}, 'en') },
          'KR': { name_ru: this.getLanguageText('1.5.1', 118, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 118, {}, 'en') },
          'KW': { name_ru: this.getLanguageText('1.5.1', 119, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 119, {}, 'en') },
          'KG': { name_ru: this.getLanguageText('1.5.1', 120, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 120, {}, 'en') },
          'LA': { name_ru: this.getLanguageText('1.5.1', 121, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 121, {}, 'en') },
          'LV': { name_ru: this.getLanguageText('1.5.1', 122, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 122, {}, 'en') },
          'LB': { name_ru: this.getLanguageText('1.5.1', 123, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 123, {}, 'en') },
          'LS': { name_ru: this.getLanguageText('1.5.1', 124, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 124, {}, 'en') },
          'LR': { name_ru: this.getLanguageText('1.5.1', 125, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 125, {}, 'en') },
          'LY': { name_ru: this.getLanguageText('1.5.1', 126, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 126, {}, 'en') },
          'LI': { name_ru: this.getLanguageText('1.5.1', 127, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 127, {}, 'en') },
          'LT': { name_ru: this.getLanguageText('1.5.1', 128, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 128, {}, 'en') },
          'LU': { name_ru: this.getLanguageText('1.5.1', 129, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 129, {}, 'en') },
          'MO': { name_ru: this.getLanguageText('1.5.1', 130, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 130, {}, 'en') },
          'MK': { name_ru: this.getLanguageText('1.5.1', 131, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 131, {}, 'en') },
          'MG': { name_ru: this.getLanguageText('1.5.1', 132, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 132, {}, 'en') },
          'MW': { name_ru: this.getLanguageText('1.5.1', 133, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 133, {}, 'en') },
          'MY': { name_ru: this.getLanguageText('1.5.1', 134, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 134, {}, 'en') },
          'MV': { name_ru: this.getLanguageText('1.5.1', 135, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 135, {}, 'en') },
          'ML': { name_ru: this.getLanguageText('1.5.1', 136, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 136, {}, 'en') },
          'MT': { name_ru: this.getLanguageText('1.5.1', 137, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 137, {}, 'en') },
          'MH': { name_ru: this.getLanguageText('1.5.1', 138, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 138, {}, 'en') },
          'MQ': { name_ru: this.getLanguageText('1.5.1', 139, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 139, {}, 'en') },
          'MR': { name_ru: this.getLanguageText('1.5.1', 140, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 140, {}, 'en') },
          'MU': { name_ru: this.getLanguageText('1.5.1', 141, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 141, {}, 'en') },
          'YT': { name_ru: this.getLanguageText('1.5.1', 142, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 142, {}, 'en') },
          'MX': { name_ru: this.getLanguageText('1.5.1', 143, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 143, {}, 'en') },
          'FM': { name_ru: this.getLanguageText('1.5.1', 144, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 144, {}, 'en') },
          'MD': { name_ru: this.getLanguageText('1.5.1', 145, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 145, {}, 'en') },
          'MC': { name_ru: this.getLanguageText('1.5.1', 146, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 146, {}, 'en') },
          'MN': { name_ru: this.getLanguageText('1.5.1', 147, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 147, {}, 'en') },
          'ME': { name_ru: this.getLanguageText('1.5.1', 148, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 148, {}, 'en') },
          'MS': { name_ru: this.getLanguageText('1.5.1', 149, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 149, {}, 'en') },
          'MA': { name_ru: this.getLanguageText('1.5.1', 150, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 150, {}, 'en') },
          'MZ': { name_ru: this.getLanguageText('1.5.1', 151, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 151, {}, 'en') },
          'MM': { name_ru: this.getLanguageText('1.5.1', 152, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 152, {}, 'en') },
          'NA': { name_ru: this.getLanguageText('1.5.1', 153, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 153, {}, 'en') },
          'NR': { name_ru: this.getLanguageText('1.5.1', 154, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 154, {}, 'en') },
          'NP': { name_ru: this.getLanguageText('1.5.1', 155, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 155, {}, 'en') },
          'NL': { name_ru: this.getLanguageText('1.5.1', 156, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 156, {}, 'en') },
          'NC': { name_ru: this.getLanguageText('1.5.1', 157, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 157, {}, 'en') },
          'NZ': { name_ru: this.getLanguageText('1.5.1', 158, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 158, {}, 'en') },
          'NI': { name_ru: this.getLanguageText('1.5.1', 159, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 159, {}, 'en') },
          'NE': { name_ru: this.getLanguageText('1.5.1', 160, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 160, {}, 'en') },
          'NG': { name_ru: this.getLanguageText('1.5.1', 161, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 161, {}, 'en') },
          'NU': { name_ru: this.getLanguageText('1.5.1', 162, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 162, {}, 'en') },
          'NF': { name_ru: this.getLanguageText('1.5.1', 163, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 163, {}, 'en') },
          'MP': { name_ru: this.getLanguageText('1.5.1', 164, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 164, {}, 'en') },
          'NO': { name_ru: this.getLanguageText('1.5.1', 165, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 165, {}, 'en') },
          'OM': { name_ru: this.getLanguageText('1.5.1', 166, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 166, {}, 'en') },
          'PK': { name_ru: this.getLanguageText('1.5.1', 167, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 167, {}, 'en') },
          'PW': { name_ru: this.getLanguageText('1.5.1', 168, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 168, {}, 'en') },
          'PS': { name_ru: this.getLanguageText('1.5.1', 169, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 169, {}, 'en') },
          'PA': { name_ru: this.getLanguageText('1.5.1', 170, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 170, {}, 'en') },
          'PG': { name_ru: this.getLanguageText('1.5.1', 171, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 171, {}, 'en') },
          'PY': { name_ru: this.getLanguageText('1.5.1', 172, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 172, {}, 'en') },
          'PE': { name_ru: this.getLanguageText('1.5.1', 173, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 173, {}, 'en') },
          'PH': { name_ru: this.getLanguageText('1.5.1', 174, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 174, {}, 'en') },
          'PN': { name_ru: this.getLanguageText('1.5.1', 175, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 175, {}, 'en') },
          'PL': { name_ru: this.getLanguageText('1.5.1', 176, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 176, {}, 'en') },
          'PT': { name_ru: this.getLanguageText('1.5.1', 177, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 177, {}, 'en') },
          'PR': { name_ru: this.getLanguageText('1.5.1', 178, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 178, {}, 'en') },
          'QA': { name_ru: this.getLanguageText('1.5.1', 179, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 179, {}, 'en') },
          'RE': { name_ru: this.getLanguageText('1.5.1', 180, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 180, {}, 'en') },
          'RO': { name_ru: this.getLanguageText('1.5.1', 181, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 181, {}, 'en') },
          'RU': { name_ru: this.getLanguageText('1.5.1', 182, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 182, {}, 'en') },
          'RW': { name_ru: this.getLanguageText('1.5.1', 183, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 183, {}, 'en') },
          'BL': { name_ru: this.getLanguageText('1.5.1', 184, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 184, {}, 'en') },
          'SH': { name_ru: this.getLanguageText('1.5.1', 185, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 185, {}, 'en') },
          'KN': { name_ru: this.getLanguageText('1.5.1', 186, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 186, {}, 'en') },
          'LC': { name_ru: this.getLanguageText('1.5.1', 187, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 187, {}, 'en') },
          'MF': { name_ru: this.getLanguageText('1.5.1', 188, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 188, {}, 'en') },
          'PM': { name_ru: this.getLanguageText('1.5.1', 189, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 189, {}, 'en') },
          'VC': { name_ru: this.getLanguageText('1.5.1', 190, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 190, {}, 'en') },
          'WS': { name_ru: this.getLanguageText('1.5.1', 191, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 191, {}, 'en') },
          'SM': { name_ru: this.getLanguageText('1.5.1', 192, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 192, {}, 'en') },
          'ST': { name_ru: this.getLanguageText('1.5.1', 193, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 193, {}, 'en') },
          'SA': { name_ru: this.getLanguageText('1.5.1', 194, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 194, {}, 'en') },
          'SN': { name_ru: this.getLanguageText('1.5.1', 195, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 195, {}, 'en') },
          'RS': { name_ru: this.getLanguageText('1.5.1', 196, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 196, {}, 'en') },
          'SC': { name_ru: this.getLanguageText('1.5.1', 197, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 197, {}, 'en') },
          'SL': { name_ru: this.getLanguageText('1.5.1', 198, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 198, {}, 'en') },
          'SG': { name_ru: this.getLanguageText('1.5.1', 199, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 199, {}, 'en') },
          'SX': { name_ru: this.getLanguageText('1.5.1', 200, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 200, {}, 'en') },
          'SK': { name_ru: this.getLanguageText('1.5.1', 201, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 201, {}, 'en') },
          'SI': { name_ru: this.getLanguageText('1.5.1', 202, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 202, {}, 'en') },
          'SB': { name_ru: this.getLanguageText('1.5.1', 203, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 203, {}, 'en') },
          'SO': { name_ru: this.getLanguageText('1.5.1', 204, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 204, {}, 'en') },
          'ZA': { name_ru: this.getLanguageText('1.5.1', 205, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 205, {}, 'en') },
          'GS': { name_ru: this.getLanguageText('1.5.1', 206, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 206, {}, 'en') },
          'SS': { name_ru: this.getLanguageText('1.5.1', 207, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 207, {}, 'en') },
          'ES': { name_ru: this.getLanguageText('1.5.1', 208, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 208, {}, 'en') },
          'LK': { name_ru: this.getLanguageText('1.5.1', 209, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 209, {}, 'en') },
          'SD': { name_ru: this.getLanguageText('1.5.1', 210, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 210, {}, 'en') },
          'SR': { name_ru: this.getLanguageText('1.5.1', 211, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 211, {}, 'en') },
          'SJ': { name_ru: this.getLanguageText('1.5.1', 212, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 212, {}, 'en') },
          'SZ': { name_ru: this.getLanguageText('1.5.1', 213, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 213, {}, 'en') },
          'SE': { name_ru: this.getLanguageText('1.5.1', 214, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 214, {}, 'en') },
          'CH': { name_ru: this.getLanguageText('1.5.1', 215, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 215, {}, 'en') },
          'SY': { name_ru: this.getLanguageText('1.5.1', 216, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 216, {}, 'en') },
          'TW': { name_ru: this.getLanguageText('1.5.1', 217, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 217, {}, 'en') },
          'TJ': { name_ru: this.getLanguageText('1.5.1', 218, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 218, {}, 'en') },
          'TZ': { name_ru: this.getLanguageText('1.5.1', 219, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 219, {}, 'en') },
          'TH': { name_ru: this.getLanguageText('1.5.1', 220, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 220, {}, 'en') },
          'TL': { name_ru: this.getLanguageText('1.5.1', 221, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 221, {}, 'en') },
          'TG': { name_ru: this.getLanguageText('1.5.1', 222, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 222, {}, 'en') },
          'TK': { name_ru: this.getLanguageText('1.5.1', 223, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 223, {}, 'en') },
          'TO': { name_ru: this.getLanguageText('1.5.1', 224, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 224, {}, 'en') },
          'TT': { name_ru: this.getLanguageText('1.5.1', 225, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 225, {}, 'en') },
          'TN': { name_ru: this.getLanguageText('1.5.1', 226, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 226, {}, 'en') },
          'TR': { name_ru: this.getLanguageText('1.5.1', 227, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 227, {}, 'en') },
          'TM': { name_ru: this.getLanguageText('1.5.1', 228, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 228, {}, 'en') },
          'TC': { name_ru: this.getLanguageText('1.5.1', 229, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 229, {}, 'en') },
          'TV': { name_ru: this.getLanguageText('1.5.1', 230, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 230, {}, 'en') },
          'UG': { name_ru: this.getLanguageText('1.5.1', 231, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 231, {}, 'en') },
          'UA': { name_ru: this.getLanguageText('1.5.1', 232, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 232, {}, 'en') },
          'AE': { name_ru: this.getLanguageText('1.5.1', 233, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 233, {}, 'en') },
          'GB': { name_ru: this.getLanguageText('1.5.1', 234, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 234, {}, 'en') },
          'US': { name_ru: this.getLanguageText('1.5.1', 235, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 235, {}, 'en') },
          'UM': { name_ru: this.getLanguageText('1.5.1', 236, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 236, {}, 'en') },
          'UY': { name_ru: this.getLanguageText('1.5.1', 237, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 237, {}, 'en') },
          'UZ': { name_ru: this.getLanguageText('1.5.1', 238, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 238, {}, 'en') },
          'VU': { name_ru: this.getLanguageText('1.5.1', 239, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 239, {}, 'en') },
          'VE': { name_ru: this.getLanguageText('1.5.1', 240, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 240, {}, 'en') },
          'VN': { name_ru: this.getLanguageText('1.5.1', 241, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 241, {}, 'en') },
          'VG': { name_ru: this.getLanguageText('1.5.1', 242, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 242, {}, 'en') },
          'VI': { name_ru: this.getLanguageText('1.5.1', 243, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 243, {}, 'en') },
          'WF': { name_ru: this.getLanguageText('1.5.1', 244, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 244, {}, 'en') },
          'EH': { name_ru: this.getLanguageText('1.5.1', 245, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 245, {}, 'en') },
          'YE': { name_ru: this.getLanguageText('1.5.1', 246, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 246, {}, 'en') },
          'ZM': { name_ru: this.getLanguageText('1.5.1', 247, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 247, {}, 'en') },
          'ZW': { name_ru: this.getLanguageText('1.5.1', 248, {}, 'ru'), name_en: this.getLanguageText('1.5.1', 248, {}, 'en') }
        };
      },
      getLanguageText: function(type, index, data, language) {
        return this.$store.getters.getLanguageText(type, index, data, language);
      },
      getSelectOptions: function(type) {
        if (type == 1) {
          return [
            { code: 'AF', label_ru: this.getLanguageText('1.5.1', 0, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 0, {}, 'en') },
            { code: 'AX', label_ru: this.getLanguageText('1.5.1', 1, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 1, {}, 'en') },
            { code: 'AL', label_ru: this.getLanguageText('1.5.1', 2, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 2, {}, 'en') },
            { code: 'DZ', label_ru: this.getLanguageText('1.5.1', 3, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 3, {}, 'en') },
            { code: 'AS', label_ru: this.getLanguageText('1.5.1', 4, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 4, {}, 'en') },
            { code: 'AD', label_ru: this.getLanguageText('1.5.1', 5, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 5, {}, 'en') },
            { code: 'AO', label_ru: this.getLanguageText('1.5.1', 6, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 6, {}, 'en') },
            { code: 'AI', label_ru: this.getLanguageText('1.5.1', 7, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 7, {}, 'en') },
            { code: 'AQ', label_ru: this.getLanguageText('1.5.1', 8, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 8, {}, 'en') },
            { code: 'AG', label_ru: this.getLanguageText('1.5.1', 9, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 9, {}, 'en') },
            { code: 'AR', label_ru: this.getLanguageText('1.5.1', 10, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 10, {}, 'en') },
            { code: 'AM', label_ru: this.getLanguageText('1.5.1', 11, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 11, {}, 'en') },
            { code: 'AW', label_ru: this.getLanguageText('1.5.1', 12, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 12, {}, 'en') },
            { code: 'AU', label_ru: this.getLanguageText('1.5.1', 13, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 13, {}, 'en') },
            { code: 'AT', label_ru: this.getLanguageText('1.5.1', 14, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 14, {}, 'en') },
            { code: 'AZ', label_ru: this.getLanguageText('1.5.1', 15, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 15, {}, 'en') },
            { code: 'BS', label_ru: this.getLanguageText('1.5.1', 16, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 16, {}, 'en') },
            { code: 'BH', label_ru: this.getLanguageText('1.5.1', 17, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 17, {}, 'en') },
            { code: 'BD', label_ru: this.getLanguageText('1.5.1', 18, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 18, {}, 'en') },
            { code: 'BB', label_ru: this.getLanguageText('1.5.1', 19, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 19, {}, 'en') },
            { code: 'BY', label_ru: this.getLanguageText('1.5.1', 20, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 20, {}, 'en') },
            { code: 'BE', label_ru: this.getLanguageText('1.5.1', 21, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 21, {}, 'en') },
            { code: 'BZ', label_ru: this.getLanguageText('1.5.1', 22, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 22, {}, 'en') },
            { code: 'BJ', label_ru: this.getLanguageText('1.5.1', 23, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 23, {}, 'en') },
            { code: 'BM', label_ru: this.getLanguageText('1.5.1', 24, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 24, {}, 'en') },
            { code: 'BT', label_ru: this.getLanguageText('1.5.1', 25, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 25, {}, 'en') },
            { code: 'BO', label_ru: this.getLanguageText('1.5.1', 26, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 26, {}, 'en') },
            { code: 'BQ', label_ru: this.getLanguageText('1.5.1', 27, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 27, {}, 'en') },
            { code: 'BA', label_ru: this.getLanguageText('1.5.1', 28, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 28, {}, 'en') },
            { code: 'BW', label_ru: this.getLanguageText('1.5.1', 29, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 29, {}, 'en') },
            { code: 'BV', label_ru: this.getLanguageText('1.5.1', 30, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 30, {}, 'en') },
            { code: 'BR', label_ru: this.getLanguageText('1.5.1', 31, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 31, {}, 'en') },
            { code: 'IO', label_ru: this.getLanguageText('1.5.1', 32, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 32, {}, 'en') },
            { code: 'BN', label_ru: this.getLanguageText('1.5.1', 33, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 33, {}, 'en') },
            { code: 'BG', label_ru: this.getLanguageText('1.5.1', 34, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 34, {}, 'en') },
            { code: 'BF', label_ru: this.getLanguageText('1.5.1', 35, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 35, {}, 'en') },
            { code: 'BI', label_ru: this.getLanguageText('1.5.1', 36, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 36, {}, 'en') },
            { code: 'KH', label_ru: this.getLanguageText('1.5.1', 37, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 37, {}, 'en') },
            { code: 'CM', label_ru: this.getLanguageText('1.5.1', 38, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 38, {}, 'en') },
            { code: 'CA', label_ru: this.getLanguageText('1.5.1', 39, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 39, {}, 'en') },
            { code: 'CV', label_ru: this.getLanguageText('1.5.1', 40, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 40, {}, 'en') },
            { code: 'KY', label_ru: this.getLanguageText('1.5.1', 41, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 41, {}, 'en') },
            { code: 'CF', label_ru: this.getLanguageText('1.5.1', 42, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 42, {}, 'en') },
            { code: 'TD', label_ru: this.getLanguageText('1.5.1', 43, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 43, {}, 'en') },
            { code: 'CL', label_ru: this.getLanguageText('1.5.1', 44, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 44, {}, 'en') },
            { code: 'CN', label_ru: this.getLanguageText('1.5.1', 45, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 45, {}, 'en') },
            { code: 'CX', label_ru: this.getLanguageText('1.5.1', 46, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 46, {}, 'en') },
            { code: 'CC', label_ru: this.getLanguageText('1.5.1', 47, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 47, {}, 'en') },
            { code: 'CO', label_ru: this.getLanguageText('1.5.1', 48, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 48, {}, 'en') },
            { code: 'KM', label_ru: this.getLanguageText('1.5.1', 49, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 49, {}, 'en') },
            { code: 'CG', label_ru: this.getLanguageText('1.5.1', 50, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 50, {}, 'en') },
            { code: 'CD', label_ru: this.getLanguageText('1.5.1', 51, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 51, {}, 'en') },
            { code: 'CK', label_ru: this.getLanguageText('1.5.1', 52, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 52, {}, 'en') },
            { code: 'CR', label_ru: this.getLanguageText('1.5.1', 53, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 53, {}, 'en') },
            { code: 'CI', label_ru: this.getLanguageText('1.5.1', 54, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 54, {}, 'en') },
            { code: 'HR', label_ru: this.getLanguageText('1.5.1', 55, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 55, {}, 'en') },
            { code: 'CU', label_ru: this.getLanguageText('1.5.1', 56, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 56, {}, 'en') },
            { code: 'CW', label_ru: this.getLanguageText('1.5.1', 57, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 57, {}, 'en') },
            { code: 'CY', label_ru: this.getLanguageText('1.5.1', 58, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 58, {}, 'en') },
            { code: 'CZ', label_ru: this.getLanguageText('1.5.1', 59, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 59, {}, 'en') },
            { code: 'DK', label_ru: this.getLanguageText('1.5.1', 60, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 60, {}, 'en') },
            { code: 'DJ', label_ru: this.getLanguageText('1.5.1', 61, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 61, {}, 'en') },
            { code: 'DM', label_ru: this.getLanguageText('1.5.1', 62, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 62, {}, 'en') },
            { code: 'DO', label_ru: this.getLanguageText('1.5.1', 63, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 63, {}, 'en') },
            { code: 'EC', label_ru: this.getLanguageText('1.5.1', 64, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 64, {}, 'en') },
            { code: 'EG', label_ru: this.getLanguageText('1.5.1', 65, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 65, {}, 'en') },
            { code: 'SV', label_ru: this.getLanguageText('1.5.1', 66, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 66, {}, 'en') },
            { code: 'GQ', label_ru: this.getLanguageText('1.5.1', 67, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 67, {}, 'en') },
            { code: 'ER', label_ru: this.getLanguageText('1.5.1', 68, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 68, {}, 'en') },
            { code: 'EE', label_ru: this.getLanguageText('1.5.1', 69, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 69, {}, 'en') },
            { code: 'ET', label_ru: this.getLanguageText('1.5.1', 70, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 70, {}, 'en') },
            { code: 'FK', label_ru: this.getLanguageText('1.5.1', 71, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 71, {}, 'en') },
            { code: 'FO', label_ru: this.getLanguageText('1.5.1', 72, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 72, {}, 'en') },
            { code: 'FJ', label_ru: this.getLanguageText('1.5.1', 73, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 73, {}, 'en') },
            { code: 'FI', label_ru: this.getLanguageText('1.5.1', 74, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 74, {}, 'en') },
            { code: 'FR', label_ru: this.getLanguageText('1.5.1', 75, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 75, {}, 'en') },
            { code: 'GF', label_ru: this.getLanguageText('1.5.1', 76, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 76, {}, 'en') },
            { code: 'PF', label_ru: this.getLanguageText('1.5.1', 77, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 77, {}, 'en') },
            { code: 'TF', label_ru: this.getLanguageText('1.5.1', 78, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 78, {}, 'en') },
            { code: 'GA', label_ru: this.getLanguageText('1.5.1', 79, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 79, {}, 'en') },
            { code: 'GM', label_ru: this.getLanguageText('1.5.1', 80, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 80, {}, 'en') },
            { code: 'GE', label_ru: this.getLanguageText('1.5.1', 81, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 81, {}, 'en') },
            { code: 'DE', label_ru: this.getLanguageText('1.5.1', 82, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 82, {}, 'en') },
            { code: 'GH', label_ru: this.getLanguageText('1.5.1', 83, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 83, {}, 'en') },
            { code: 'GI', label_ru: this.getLanguageText('1.5.1', 84, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 84, {}, 'en') },
            { code: 'GR', label_ru: this.getLanguageText('1.5.1', 85, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 85, {}, 'en') },
            { code: 'GL', label_ru: this.getLanguageText('1.5.1', 86, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 86, {}, 'en') },
            { code: 'GD', label_ru: this.getLanguageText('1.5.1', 87, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 87, {}, 'en') },
            { code: 'GP', label_ru: this.getLanguageText('1.5.1', 88, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 88, {}, 'en') },
            { code: 'GU', label_ru: this.getLanguageText('1.5.1', 89, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 89, {}, 'en') },
            { code: 'GT', label_ru: this.getLanguageText('1.5.1', 90, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 90, {}, 'en') },
            { code: 'GG', label_ru: this.getLanguageText('1.5.1', 91, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 91, {}, 'en') },
            { code: 'GN', label_ru: this.getLanguageText('1.5.1', 92, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 92, {}, 'en') },
            { code: 'GW', label_ru: this.getLanguageText('1.5.1', 93, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 93, {}, 'en') },
            { code: 'GY', label_ru: this.getLanguageText('1.5.1', 94, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 94, {}, 'en') },
            { code: 'HT', label_ru: this.getLanguageText('1.5.1', 95, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 95, {}, 'en') },
            { code: 'HM', label_ru: this.getLanguageText('1.5.1', 96, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 96, {}, 'en') },
            { code: 'VA', label_ru: this.getLanguageText('1.5.1', 97, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 97, {}, 'en') },
            { code: 'HN', label_ru: this.getLanguageText('1.5.1', 98, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 98, {}, 'en') },
            { code: 'HK', label_ru: this.getLanguageText('1.5.1', 99, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 99, {}, 'en') },
            { code: 'HU', label_ru: this.getLanguageText('1.5.1', 100, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 100, {}, 'en') },
            { code: 'IS', label_ru: this.getLanguageText('1.5.1', 101, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 101, {}, 'en') },
            { code: 'IN', label_ru: this.getLanguageText('1.5.1', 102, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 102, {}, 'en') },
            { code: 'ID', label_ru: this.getLanguageText('1.5.1', 103, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 103, {}, 'en') },
            { code: 'IR', label_ru: this.getLanguageText('1.5.1', 104, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 104, {}, 'en') },
            { code: 'IQ', label_ru: this.getLanguageText('1.5.1', 105, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 105, {}, 'en') },
            { code: 'IE', label_ru: this.getLanguageText('1.5.1', 106, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 106, {}, 'en') },
            { code: 'IM', label_ru: this.getLanguageText('1.5.1', 107, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 107, {}, 'en') },
            { code: 'IL', label_ru: this.getLanguageText('1.5.1', 108, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 108, {}, 'en') },
            { code: 'IT', label_ru: this.getLanguageText('1.5.1', 109, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 109, {}, 'en') },
            { code: 'JM', label_ru: this.getLanguageText('1.5.1', 110, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 110, {}, 'en') },
            { code: 'JP', label_ru: this.getLanguageText('1.5.1', 111, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 111, {}, 'en') },
            { code: 'JE', label_ru: this.getLanguageText('1.5.1', 112, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 112, {}, 'en') },
            { code: 'JO', label_ru: this.getLanguageText('1.5.1', 113, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 113, {}, 'en') },
            { code: 'KZ', label_ru: this.getLanguageText('1.5.1', 114, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 114, {}, 'en') },
            { code: 'KE', label_ru: this.getLanguageText('1.5.1', 115, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 115, {}, 'en') },
            { code: 'KI', label_ru: this.getLanguageText('1.5.1', 116, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 116, {}, 'en') },
            { code: 'KP', label_ru: this.getLanguageText('1.5.1', 117, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 117, {}, 'en') },
            { code: 'KR', label_ru: this.getLanguageText('1.5.1', 118, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 118, {}, 'en') },
            { code: 'KW', label_ru: this.getLanguageText('1.5.1', 119, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 119, {}, 'en') },
            { code: 'KG', label_ru: this.getLanguageText('1.5.1', 120, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 120, {}, 'en') },
            { code: 'LA', label_ru: this.getLanguageText('1.5.1', 121, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 121, {}, 'en') },
            { code: 'LV', label_ru: this.getLanguageText('1.5.1', 122, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 122, {}, 'en') },
            { code: 'LB', label_ru: this.getLanguageText('1.5.1', 123, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 123, {}, 'en') },
            { code: 'LS', label_ru: this.getLanguageText('1.5.1', 124, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 124, {}, 'en') },
            { code: 'LR', label_ru: this.getLanguageText('1.5.1', 125, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 125, {}, 'en') },
            { code: 'LY', label_ru: this.getLanguageText('1.5.1', 126, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 126, {}, 'en') },
            { code: 'LI', label_ru: this.getLanguageText('1.5.1', 127, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 127, {}, 'en') },
            { code: 'LT', label_ru: this.getLanguageText('1.5.1', 128, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 128, {}, 'en') },
            { code: 'LU', label_ru: this.getLanguageText('1.5.1', 129, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 129, {}, 'en') },
            { code: 'MO', label_ru: this.getLanguageText('1.5.1', 130, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 130, {}, 'en') },
            { code: 'MK', label_ru: this.getLanguageText('1.5.1', 131, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 131, {}, 'en') },
            { code: 'MG', label_ru: this.getLanguageText('1.5.1', 132, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 132, {}, 'en') },
            { code: 'MW', label_ru: this.getLanguageText('1.5.1', 133, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 133, {}, 'en') },
            { code: 'MY', label_ru: this.getLanguageText('1.5.1', 134, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 134, {}, 'en') },
            { code: 'MV', label_ru: this.getLanguageText('1.5.1', 135, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 135, {}, 'en') },
            { code: 'ML', label_ru: this.getLanguageText('1.5.1', 136, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 136, {}, 'en') },
            { code: 'MT', label_ru: this.getLanguageText('1.5.1', 137, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 137, {}, 'en') },
            { code: 'MH', label_ru: this.getLanguageText('1.5.1', 138, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 138, {}, 'en') },
            { code: 'MQ', label_ru: this.getLanguageText('1.5.1', 139, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 139, {}, 'en') },
            { code: 'MR', label_ru: this.getLanguageText('1.5.1', 140, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 140, {}, 'en') },
            { code: 'MU', label_ru: this.getLanguageText('1.5.1', 141, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 141, {}, 'en') },
            { code: 'YT', label_ru: this.getLanguageText('1.5.1', 142, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 142, {}, 'en') },
            { code: 'MX', label_ru: this.getLanguageText('1.5.1', 143, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 143, {}, 'en') },
            { code: 'FM', label_ru: this.getLanguageText('1.5.1', 144, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 144, {}, 'en') },
            { code: 'MD', label_ru: this.getLanguageText('1.5.1', 145, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 145, {}, 'en') },
            { code: 'MC', label_ru: this.getLanguageText('1.5.1', 146, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 146, {}, 'en') },
            { code: 'MN', label_ru: this.getLanguageText('1.5.1', 147, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 147, {}, 'en') },
            { code: 'ME', label_ru: this.getLanguageText('1.5.1', 148, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 148, {}, 'en') },
            { code: 'MS', label_ru: this.getLanguageText('1.5.1', 149, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 149, {}, 'en') },
            { code: 'MA', label_ru: this.getLanguageText('1.5.1', 150, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 150, {}, 'en') },
            { code: 'MZ', label_ru: this.getLanguageText('1.5.1', 151, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 151, {}, 'en') },
            { code: 'MM', label_ru: this.getLanguageText('1.5.1', 152, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 152, {}, 'en') },
            { code: 'NA', label_ru: this.getLanguageText('1.5.1', 153, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 153, {}, 'en') },
            { code: 'NR', label_ru: this.getLanguageText('1.5.1', 154, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 154, {}, 'en') },
            { code: 'NP', label_ru: this.getLanguageText('1.5.1', 155, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 155, {}, 'en') },
            { code: 'NL', label_ru: this.getLanguageText('1.5.1', 156, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 156, {}, 'en') },
            { code: 'NC', label_ru: this.getLanguageText('1.5.1', 157, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 157, {}, 'en') },
            { code: 'NZ', label_ru: this.getLanguageText('1.5.1', 158, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 158, {}, 'en') },
            { code: 'NI', label_ru: this.getLanguageText('1.5.1', 159, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 159, {}, 'en') },
            { code: 'NE', label_ru: this.getLanguageText('1.5.1', 160, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 160, {}, 'en') },
            { code: 'NG', label_ru: this.getLanguageText('1.5.1', 161, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 161, {}, 'en') },
            { code: 'NU', label_ru: this.getLanguageText('1.5.1', 162, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 162, {}, 'en') },
            { code: 'NF', label_ru: this.getLanguageText('1.5.1', 163, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 163, {}, 'en') },
            { code: 'MP', label_ru: this.getLanguageText('1.5.1', 164, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 164, {}, 'en') },
            { code: 'NO', label_ru: this.getLanguageText('1.5.1', 165, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 165, {}, 'en') },
            { code: 'OM', label_ru: this.getLanguageText('1.5.1', 166, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 166, {}, 'en') },
            { code: 'PK', label_ru: this.getLanguageText('1.5.1', 167, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 167, {}, 'en') },
            { code: 'PW', label_ru: this.getLanguageText('1.5.1', 168, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 168, {}, 'en') },
            { code: 'PS', label_ru: this.getLanguageText('1.5.1', 169, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 169, {}, 'en') },
            { code: 'PA', label_ru: this.getLanguageText('1.5.1', 170, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 170, {}, 'en') },
            { code: 'PG', label_ru: this.getLanguageText('1.5.1', 171, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 171, {}, 'en') },
            { code: 'PY', label_ru: this.getLanguageText('1.5.1', 172, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 172, {}, 'en') },
            { code: 'PE', label_ru: this.getLanguageText('1.5.1', 173, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 173, {}, 'en') },
            { code: 'PH', label_ru: this.getLanguageText('1.5.1', 174, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 174, {}, 'en') },
            { code: 'PN', label_ru: this.getLanguageText('1.5.1', 175, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 175, {}, 'en') },
            { code: 'PL', label_ru: this.getLanguageText('1.5.1', 176, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 176, {}, 'en') },
            { code: 'PT', label_ru: this.getLanguageText('1.5.1', 177, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 177, {}, 'en') },
            { code: 'PR', label_ru: this.getLanguageText('1.5.1', 178, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 178, {}, 'en') },
            { code: 'QA', label_ru: this.getLanguageText('1.5.1', 179, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 179, {}, 'en') },
            { code: 'RE', label_ru: this.getLanguageText('1.5.1', 180, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 180, {}, 'en') },
            { code: 'RO', label_ru: this.getLanguageText('1.5.1', 181, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 181, {}, 'en') },
            { code: 'RU', label_ru: this.getLanguageText('1.5.1', 182, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 182, {}, 'en') },
            { code: 'RW', label_ru: this.getLanguageText('1.5.1', 183, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 183, {}, 'en') },
            { code: 'BL', label_ru: this.getLanguageText('1.5.1', 184, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 184, {}, 'en') },
            { code: 'SH', label_ru: this.getLanguageText('1.5.1', 185, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 185, {}, 'en') },
            { code: 'KN', label_ru: this.getLanguageText('1.5.1', 186, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 186, {}, 'en') },
            { code: 'LC', label_ru: this.getLanguageText('1.5.1', 187, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 187, {}, 'en') },
            { code: 'MF', label_ru: this.getLanguageText('1.5.1', 188, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 188, {}, 'en') },
            { code: 'PM', label_ru: this.getLanguageText('1.5.1', 189, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 189, {}, 'en') },
            { code: 'VC', label_ru: this.getLanguageText('1.5.1', 190, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 190, {}, 'en') },
            { code: 'WS', label_ru: this.getLanguageText('1.5.1', 191, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 191, {}, 'en') },
            { code: 'SM', label_ru: this.getLanguageText('1.5.1', 192, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 192, {}, 'en') },
            { code: 'ST', label_ru: this.getLanguageText('1.5.1', 193, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 193, {}, 'en') },
            { code: 'SA', label_ru: this.getLanguageText('1.5.1', 194, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 194, {}, 'en') },
            { code: 'SN', label_ru: this.getLanguageText('1.5.1', 195, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 195, {}, 'en') },
            { code: 'RS', label_ru: this.getLanguageText('1.5.1', 196, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 196, {}, 'en') },
            { code: 'SC', label_ru: this.getLanguageText('1.5.1', 197, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 197, {}, 'en') },
            { code: 'SL', label_ru: this.getLanguageText('1.5.1', 198, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 198, {}, 'en') },
            { code: 'SG', label_ru: this.getLanguageText('1.5.1', 199, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 199, {}, 'en') },
            { code: 'SX', label_ru: this.getLanguageText('1.5.1', 200, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 200, {}, 'en') },
            { code: 'SK', label_ru: this.getLanguageText('1.5.1', 201, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 201, {}, 'en') },
            { code: 'SI', label_ru: this.getLanguageText('1.5.1', 202, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 202, {}, 'en') },
            { code: 'SB', label_ru: this.getLanguageText('1.5.1', 203, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 203, {}, 'en') },
            { code: 'SO', label_ru: this.getLanguageText('1.5.1', 204, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 204, {}, 'en') },
            { code: 'ZA', label_ru: this.getLanguageText('1.5.1', 205, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 205, {}, 'en') },
            { code: 'GS', label_ru: this.getLanguageText('1.5.1', 206, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 206, {}, 'en') },
            { code: 'SS', label_ru: this.getLanguageText('1.5.1', 207, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 207, {}, 'en') },
            { code: 'ES', label_ru: this.getLanguageText('1.5.1', 208, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 208, {}, 'en') },
            { code: 'LK', label_ru: this.getLanguageText('1.5.1', 209, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 209, {}, 'en') },
            { code: 'SD', label_ru: this.getLanguageText('1.5.1', 210, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 210, {}, 'en') },
            { code: 'SR', label_ru: this.getLanguageText('1.5.1', 211, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 211, {}, 'en') },
            { code: 'SJ', label_ru: this.getLanguageText('1.5.1', 212, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 212, {}, 'en') },
            { code: 'SZ', label_ru: this.getLanguageText('1.5.1', 213, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 213, {}, 'en') },
            { code: 'SE', label_ru: this.getLanguageText('1.5.1', 214, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 214, {}, 'en') },
            { code: 'CH', label_ru: this.getLanguageText('1.5.1', 215, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 215, {}, 'en') },
            { code: 'SY', label_ru: this.getLanguageText('1.5.1', 216, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 216, {}, 'en') },
            { code: 'TW', label_ru: this.getLanguageText('1.5.1', 217, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 217, {}, 'en') },
            { code: 'TJ', label_ru: this.getLanguageText('1.5.1', 218, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 218, {}, 'en') },
            { code: 'TZ', label_ru: this.getLanguageText('1.5.1', 219, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 219, {}, 'en') },
            { code: 'TH', label_ru: this.getLanguageText('1.5.1', 220, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 220, {}, 'en') },
            { code: 'TL', label_ru: this.getLanguageText('1.5.1', 221, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 221, {}, 'en') },
            { code: 'TG', label_ru: this.getLanguageText('1.5.1', 222, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 222, {}, 'en') },
            { code: 'TK', label_ru: this.getLanguageText('1.5.1', 223, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 223, {}, 'en') },
            { code: 'TO', label_ru: this.getLanguageText('1.5.1', 224, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 224, {}, 'en') },
            { code: 'TT', label_ru: this.getLanguageText('1.5.1', 225, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 225, {}, 'en') },
            { code: 'TN', label_ru: this.getLanguageText('1.5.1', 226, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 226, {}, 'en') },
            { code: 'TR', label_ru: this.getLanguageText('1.5.1', 227, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 227, {}, 'en') },
            { code: 'TM', label_ru: this.getLanguageText('1.5.1', 228, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 228, {}, 'en') },
            { code: 'TC', label_ru: this.getLanguageText('1.5.1', 229, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 229, {}, 'en') },
            { code: 'TV', label_ru: this.getLanguageText('1.5.1', 230, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 230, {}, 'en') },
            { code: 'UG', label_ru: this.getLanguageText('1.5.1', 231, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 231, {}, 'en') },
            { code: 'UA', label_ru: this.getLanguageText('1.5.1', 232, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 232, {}, 'en') },
            { code: 'AE', label_ru: this.getLanguageText('1.5.1', 233, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 233, {}, 'en') },
            { code: 'GB', label_ru: this.getLanguageText('1.5.1', 234, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 234, {}, 'en') },
            { code: 'US', label_ru: this.getLanguageText('1.5.1', 235, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 235, {}, 'en') },
            { code: 'UM', label_ru: this.getLanguageText('1.5.1', 236, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 236, {}, 'en') },
            { code: 'UY', label_ru: this.getLanguageText('1.5.1', 237, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 237, {}, 'en') },
            { code: 'UZ', label_ru: this.getLanguageText('1.5.1', 238, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 238, {}, 'en') },
            { code: 'VU', label_ru: this.getLanguageText('1.5.1', 239, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 239, {}, 'en') },
            { code: 'VE', label_ru: this.getLanguageText('1.5.1', 240, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 240, {}, 'en') },
            { code: 'VN', label_ru: this.getLanguageText('1.5.1', 241, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 241, {}, 'en') },
            { code: 'VG', label_ru: this.getLanguageText('1.5.1', 242, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 242, {}, 'en') },
            { code: 'VI', label_ru: this.getLanguageText('1.5.1', 243, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 243, {}, 'en') },
            { code: 'WF', label_ru: this.getLanguageText('1.5.1', 244, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 244, {}, 'en') },
            { code: 'EH', label_ru: this.getLanguageText('1.5.1', 245, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 245, {}, 'en') },
            { code: 'YE', label_ru: this.getLanguageText('1.5.1', 246, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 246, {}, 'en') },
            { code: 'ZM', label_ru: this.getLanguageText('1.5.1', 247, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 247, {}, 'en') },
            { code: 'ZW', label_ru: this.getLanguageText('1.5.1', 248, {}, 'ru'), label_en: this.getLanguageText('1.5.1', 248, {}, 'en') }
          ];
        }
      },
      checkAppVersion: function(headers) {
        if (headers['x-client-application-version'] !== undefined) {
          if (String(headers['x-client-application-version']) !== this.$store.state.clvAppVersion) {
            document.location.reload(true);
          }
        }
      },
      toggleMobileMenu: function() {
        document.body.style.overflow = this.$store.state.clvIsMobileMenu ? 'visible' : 'hidden';
        this.$store.state.clvIsMobileMenu = !this.$store.state.clvIsMobileMenu;
      },
      toggleCSelect: function(id) {
        this.$store.state.clvCSelectId = (this.$store.state.clvCSelectId == id ? this.$store.state.clvCSelectId : '');
        this.$store.state.clvCSelectId = (!this.$store.state.clvCSelectId ? id : '');
      },
      closeCSelect: function() {
        this.$store.state.clvCSelectId = '';
      },
      sortDataByName(data, language) {
        return data.sort((a, b) => (a['name' + (language ? '_' + language : '')] > b['name' + (language ? '_' + language : '')] ? 1 : -1));
      },
      sortDataByLabel(data, language) {
        return data.sort((a, b) => (a['label' + (language ? '_' + language : '')] > b['label' + (language ? '_' + language : '')] ? 1 : -1));
      },
      goToTop: function(isExecute) {
        if (isExecute) { window.scrollTo(0, 0); }
      },
      exit: function() {
        localStorage.removeItem('clvToken');
        this.$router.push({ path: '/login' });
      }
    },
    created: function() {
      this.isMobile();
      this.isEventStorage();
      this.initLanguage();
    }
  }
</script>

<style>
  @font-face {
    font-family: 'OpenSansRegular';
    src: url('../assets/fonts/OpenSans/OpenSansRegular/OpenSansRegular.eot');
    src: url('../assets/fonts/OpenSans/OpenSansRegular/OpenSansRegular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/OpenSans/OpenSansRegular/OpenSansRegular.woff') format('woff'),
    url('../assets/fonts/OpenSans/OpenSansRegular/OpenSansRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'OpenSansSemiBold';
    src: url('../assets/fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.eot');
    src: url('../assets/fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.woff') format('woff'),
    url('../assets/fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  html, body, #app, .clv {
    height: 100%;
  }
  a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas,
  caption, center, cite, code, dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset,
  figcaption, figure, font, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, html, i,
  iframe, img, ins, kbd, label, legend, li, mark, menu, meter, nav, object, ol, output, p, pre,
  progress, q, rp, rt, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup,
  table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video, xmp {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
  }
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-focus-ring-color: rgba(255, 255, 255, 0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    background: #FFFFFF;
    color: #333333;
    cursor: default;
    font-family: 'OpenSansRegular', Arial, sans-serif;
    font-size: 12px;
    line-height: normal;
  }
  b, strong {
    font-family: 'OpenSansSemiBold', Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
  }
  ol, ul, ul li {
    margin: 0;
    padding: 0;
    list-style: none;
    list-style-type: none;
  }
  li {
    display: list-item;
  }
  p {
    margin: 0 0 10px 0;
  }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
    font-size: 0;
  }
  img, audio, video {
    max-width: 100%;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }
  th, td, caption {
    text-align: left;
    vertical-align: middle;
    font-weight: normal;
    line-height: 16px;
  }
  td a {
    word-break: break-all;
    word-break: break-word;
  }
  .hide,
  .hide_span span {
    display: none !important;
  }
  .center {
    text-align: center;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .margin_top_10 {
    margin-top: 10px;
  }
  .margin_top_15 {
    margin-top: 15px;
  }
  .margin_top_20 {
    margin-top: 20px;
  }
  .margin_left_10 {
    margin-left: 10px;
  }
  .margin_right_5 {
    margin-right: 5px;
  }
  .margin_right_10 {
    margin-right: 10px;
  }
  .margin_bottom_10 {
    margin-bottom: 10px;
  }
  .margin_bottom_15 {
    margin-bottom: 15px;
  }
  .margin_bottom_20 {
    margin-bottom: 20px;
  }
  .rotate_45 {
    transform: rotate(45deg);
  }
  .rotate_135 {
    transform: rotate(135deg);
  }
  .clv_input {
    padding: 0;
    border: none;
    border-bottom: 1px solid #CCCCCC;
    border-radius: 0;
    background: transparent;
    color: #FFFFFF;
    outline: none;
    text-align: center;
    font-family: 'OpenSansRegular', Arial, sans-serif;
    font-size: 12px;
    line-height: 30px;
    width: 100%;
    height: 30px;
  }
  .clv_textarea {
    padding: 5px 0;
    border: none;
    border-bottom: 1px solid #CCCCCC;
    border-radius: 0;
    background: transparent;
    color: #FFFFFF;
    outline: none;
    resize: none;
    font-family: 'OpenSansRegular', Arial, sans-serif;
    font-size: 12px;
    line-height: 20px;
    width: 100%;
    min-height: 200px;
  }
  .clv_textarea.small {
    min-height: 100px;
  }
  .clv_textarea.mini {
    min-height: 70px;
  }
  .clv_select .vs__dropdown-toggle {
    padding: 7px 0 4px 0;
    border: none;
    border-bottom: 1px solid #CCCCCC;
    border-radius: 0;
  }
  .clv_select .vs__selected-options {
    padding: 0;
  }
  .clv_select .vs__selected {
    margin: 0;
    padding: 0;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 18px;
  }
  .clv_select .vs__search,
  .clv_select .vs__search:focus {
    margin: 0;
    padding: 0;
    color: #AAAAAA;
    font-family: 'OpenSansRegular', Arial, sans-serif;
    font-size: 12px;
    line-height: 16px;
  }
  .clv_select .vs__actions {
    padding: 0 0 0 5px;
    cursor: pointer;
  }
  .clv_select .vs__actions .vs__clear {
    display: none;
  }
  .clv_select .vs__actions .vs__clear,
  .clv_select .vs__actions .vs__open-indicator {
    fill: #AAAAAA;
  }
  .clv_select .vs__dropdown-menu {
    min-width: 100%;
  }
  .clv_cselect {
    float: left;
    position: relative;
    margin: 2px 0;
    border-radius: 13px;
    background: #FFFFFF33;
    color: #FFFFFF;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    outline: none;
    font-size: 12px;
    display: table;
    width: 100%;
    height: 26px;
  }
  .clv_cselect.open {
    border-radius: 4px 4px 0 0;
  }
  .clv_cselect.open .clv_cselect_options {
    display: block;
  }
  .clv_cselect_toggle {
    padding: 0 30px 0 10px;
    cursor: pointer;
    line-height: 24px;
  }
  .clv_cselect_toggle_value {
    padding: 4px 0;
    line-height: 16px;
  }
  .clv_cselect_toggle_placeholder {
    color: #CCCCCC;
  }
  .clv_cselect_toggle_button {
    position: absolute;
    top: 0;
    right: 7px;
    color: #CCCCCC;
    text-align: center;
    font-size: 17px;
    width: 20px;
    height: 100%;
  }
  .clv_cselect_options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 5px 0;
    border: 1px solid #3C3C3C42;
    border-top-style: none;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 3px 6px 0 #00000026;
    background: #FFFFFF;
    display: none;
    z-index: 1000;
  }
  .clv_cselect_options_item_content {
    position: relative;
    color: #333333;
    cursor: pointer;
  }
  .clv_cselect_options_item_content:hover {
    background: #5897FB;
    color: #FFFFFF;
  }
  .clv_cselect_options_item_content_value {
    padding: 5px 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 16px;
  }
  .clv_cselect_options_item_content:hover .clv_cselect_options_item_content_value {
    color: #FFFFFF !important;
  }
  .clv_cselect_options_item_content.buttons_1 .clv_cselect_options_item_content_value {
    padding: 5px 35px 5px 20px;
  }
  .clv_cselect_options_item_content.buttons_2 .clv_cselect_options_item_content_value {
    padding: 5px 55px 5px 20px;
  }
  .clv_cselect_options_item_content_icon,
  .clv_cselect_options_item_content_button {
    position: absolute;
    top: 4px;
    right: 14px;
    border: 1px solid transparent;
    border-radius: 50%;
    text-align: center;
    font-size: 10px;
    line-height: 16px;
    width: 18px;
    height: 18px;
  }
  .clv_cselect_options_item_content_icon {
    color: #595959;
    font-size: 10px;
  }
  .clv_cselect_options_item_content .clv_cselect_options_item_content_icon.red {
    color: #FF4040;
  }
  .clv_cselect_options_item_content:hover .clv_cselect_options_item_content_icon {
    color: #FFFFFF;
  }
  .clv_cselect_options_item_content_button:hover {
    border-color: #FFFFFF;
    color: #FFFFFF;
    opacity: 0.8;
  }
  .clv_cselect_options_item_content_button:nth-child(2) {
    right: 34px;
    font-size: 8px;
  }
  .clv_cselect_options_item_divline {
    margin: 2px 20px;
    border-bottom: 1px solid #DDDDDD;
  }
  .clv_cselect_options_item:last-child .clv_cselect_options_item_divline {
    display: none;
  }
  .clv_radio,
  .clv_checkbox {
    position: relative;
    margin: 0 0 6px 0;
    padding: 0 0 0 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    text-align: left;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    display: block;
  }
  .clv_radio.disabled,
  .clv_checkbox.disabled {
    opacity: 0.5;
  }
  .clv_checkbox.tooltips {
    cursor: default;
  }
  .clv_radio input,
  .clv_checkbox input {
    position: absolute;
    cursor: pointer;
    width: 0;
    height: 0;
    opacity: 0;
  }
  .clv_radio_mark,
  .clv_checkbox_mark {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2px;
    background: #FFFFFF33;
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
  .clv_radio_mark {
    border-radius: 50%;
  }
  .clv_checkbox_mark:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 2px;
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    display: none;
    width: 3px;
    height: 7px;
  }
  .clv_radio_mark:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 50%;
    background: #FFFFFF;
    display: none;
    width: 6px;
    height: 6px;
  }
  .clv_radio input:checked ~ .clv_radio_mark,
  .clv_checkbox input:checked ~ .clv_checkbox_mark,
  .clv_radio.disabled:hover input:checked ~ .clv_radio_mark,
  .clv_checkbox.disabled:hover input:checked ~ .clv_checkbox_mark {
    background: #00FF0080;
  }
  .clv_radio input:checked ~ .clv_radio_mark:after,
  .clv_checkbox input:checked ~ .clv_checkbox_mark:after {
    display: block;
  }
  .clv_p_radios,
  .clv_p_checkboxes {
    margin: 15px 0 10px 0;
  }
  .clv_button {
    padding: 0;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    background: transparent;
    color: #FFFFFF;
    outline: none;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'OpenSansSemiBold', Arial, sans-serif;
    font-size: 13px;
  }
  .clv_button.big,
  .clv_button.small,
  .clv_button.micro {
    margin: 5px 0 0 10px;
    padding: 0 12px;
    border: 2px solid #999999;
    border-radius: 5px;
    background: #999999;
    color: #FFFFFF;
    overflow: hidden;
    font-size: 12px;
    line-height: 26px;
    height: 30px;
  }
  .clv_button.small {
    margin: 3px 0 0 5px;
    padding: 0 8px;
    font-size: 9px;
    line-height: 21px;
    height: 25px;
  }
  .clv_button.micro {
    margin: 0 0 0 5px;
    padding: 0 5px;
    border-radius: 9px;
    font-size: 8px;
    line-height: 14px;
    height: 18px;
  }
  .clv_button.common:before,
  .clv_button.clv_is_desktop:before {
    content: '\[';
    padding: 0 1px 0 0;
  }
  .clv_button.common:after,
  .clv_button.clv_is_desktop:after {
    content: '\]';
    padding: 0 0 0 1px;
  }
  .clv_button.common:before,
  .clv_button.common:after,
  .clv_button.clv_is_desktop:before,
  .clv_button.clv_is_desktop:after {
    position: relative;
    top: -1px;
  }
  .clv_button.big:hover,
  .clv_button.small:hover,
  .clv_button.micro:hover,
  .clv_button.common:hover,
  .clv_button.clv_is_icon:hover,
  .clv_button.clv_is_mobile:hover,
  .clv_button.clv_is_desktop:hover {
    opacity: 0.8;
  }
  .clv_button.big:first-child {
    margin: 5px 0 0 0;
  }
  .clv_button.small:first-child {
    margin: 3px 0 0 0;
  }
  .clv_button.micro:first-child {
    margin: 0;
  }
  .clv_button.big.full {
    margin: 5px 0 0 0;
    width: 100%;
  }
  .clv_button.big.blue,
  .clv_button.small.blue,
  .clv_button.micro.blue {
    border-color: #5093CB;
    background: #5093CB;
    color: #FFFFFF;
  }
  .clv_button.big.green,
  .clv_button.small.green,
  .clv_button.micro.green {
    border-color: #48B849;
    background: #48B849;
    color: #FFFFFF;
  }
  .clv_button.big.orange,
  .clv_button.small.orange,
  .clv_button.micro.orange {
    border-color: #E1B673;
    background: #E1B673;
    color: #FFFFFF;
  }
  .clv_button.big.red,
  .clv_button.small.red,
  .clv_button.micro.red {
    border-color: #E26868;
    background: #E26868;
    color: #FFFFFF;
  }
  .clv_button.big.violet,
  .clv_button.small.violet,
  .clv_button.micro.violet {
    border-color: #AC70AC;
    background: #AC70AC;
    color: #FFFFFF;
  }
  .clv_button.big.white,
  .clv_button.small.white,
  .clv_button.micro.white {
    border-color: #FFFFFF;
    background: #FFFFFF;
    color: #333333B3;
  }
  .clv_button.big.white_red,
  .clv_button.small.white_red,
  .clv_button.micro.white_red {
    border-color: #FFFFFF;
    background: #FFFFFF;
    color: #FF5555;
  }
  .clv_button.big.white_violet,
  .clv_button.small.white_violet,
  .clv_button.micro.white_violet {
    border-color: #FFFFFF;
    background: #FFFFFF;
    color: #AC70AC;
  }
  .clv_button.big.light_blue,
  .clv_button.small.light_blue,
  .clv_button.micro.light_blue {
    border-color: #03A9F4;
    background: #03A9F4;
    color: #FFFFFF;
  }
  .clv_button.big.light_red,
  .clv_button.small.light_red,
  .clv_button.micro.light_red {
    border-color: #FA8072;
    background: #FA8072;
    color: #FFFFFF;
  }
  .clv_button.big.transparent,
  .clv_button.small.transparent,
  .clv_button.micro.transparent {
    background: transparent;
  }
  .clv_button.big.transparent_white,
  .clv_button.small.transparent_white,
  .clv_button.micro.transparent_white {
    border-color: #FFFFFF;
    background: transparent;
    color: #FFFFFF;
  }
  .clv_button.big svg {
    margin: 0 8px 0 0;
    font-size: 10px;
  }
  .clv_button.small svg {
    margin: 0 5px 0 0;
    font-size: 9px;
  }
  .clv_button.micro svg {
    margin: 0 5px 0 0;
    font-size: 8px;
  }
  .clv_button.clv_is_icon,
  .clv_button.clv_is_mobile {
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    background: transparent;
    color: #FFFFFF;
    font-size: 10px;
    line-height: 18px;
    width: 20px;
    height: 20px;
  }
  .clv_button.blue.clv_is_icon,
  .clv_button.blue.clv_is_mobile {
    border-color: #A5E5FF;
    color: #A5E5FF;
  }
  .clv_button.green.clv_is_icon,
  .clv_button.green.clv_is_mobile {
    border-color: #00FF00;
    color: #00FF00;
  }
  .clv_button.orange.clv_is_icon,
  .clv_button.orange.clv_is_mobile {
    border-color: #FFC660;
    color: #FFC660;
  }
  .clv_button.red.clv_is_icon,
  .clv_button.red.clv_is_mobile {
    border-color: #FFBBBB;
    color: #FFBBBB;
  }
  .clv_link.icon,
  .clv_button.icon {
    line-height: 20px;
    display: inline-block;
    width: 20px;
    height: 20px;
  }
  .clv_button.icon:hover {
    opacity: 0.8;
  }
  .clv_button.icon img {
    margin: -4px 0 0 0;
  }
  .clv_link.blue,
  .clv_button.blue {
    color: #A5E5FF;
  }
  .clv_link.green,
  .clv_button.green {
    color: #00FF00;
  }
  .clv_link.orange,
  .clv_button.orange {
    color: #FFC660;
  }
  .clv_link.red,
  .clv_button.red {
    color: #FFBBBB;
  }
  .clv_button.disabled {
    color: #CCCCCC;
    cursor: default;
    text-decoration: line-through;
  }
  .clv_range_slider .vue-slider-dot-handle {
    border: none;
  }
  .clv_range_slider .vue-slider-dot-tooltip-inner {
    padding: 0 5px 1px 5px;
    border-radius: 5px;
    color: #FFFFFF;
    text-align: center;
    font-size: 10px;
    line-height: 18px;
    min-width: 30px;
  }
  .clv_toggle_button {
    float: left;
    margin: 2px 0;
  }
  .clv_tabs_mobile {
    margin: 0 0 15px 0;
    padding: 0 15px;
    border-radius: 15px;
    background: #111111B3;
    display: none;
    width: 100%;
    height: 30px;
  }
  .clv_tabs_mobile .clv_select .vs__dropdown-toggle {
    padding: 6px 0 7px 0;
    border: none;
  }
  .clv_tab_menu_input {
    display: none;
  }
  .clv_tab_menu_label {
    padding: 0 20px;
    border-right: 1px solid #70707033;
    border-radius: 10px 10px 0 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    background: #33333380;
    color: #FFFFFF;
    cursor: pointer;
    text-align: center;
    font-family: 'OpenSansSemiBold', Arial, sans-serif;
    font-size: 12px;
    line-height: 30px;
    display: inline-block;
    min-width: 65px;
  }
  .clv_tab_menu_input:checked + .clv_tab_menu_label {
    border-color: transparent;
    background: #333333B3;
  }
  .clv_tab_block {
    display: none;
  }
  #clv_tab_menu_1:checked ~ #clv_tab_block_1,
  #clv_tab_menu_2:checked ~ #clv_tab_block_2,
  #clv_tab_menu_3:checked ~ #clv_tab_block_3,
  #clv_tab_menu_4:checked ~ #clv_tab_block_4,
  #clv_tab_menu_5:checked ~ #clv_tab_block_5,
  #clv_tab_menu_6:checked ~ #clv_tab_block_6 {
    display: block;
  }
  .clv_table tr th,
  .clv_table tr td {
    padding: 8px 12px;
    border: 1px solid #999999;
    text-align: center;
    font-size: 12px;
  }
  .clv_table tr th {
    font-family: 'OpenSansSemiBold', Arial, sans-serif;
    font-size: 13px;
  }
  .clv_table tr th svg {
    font-size: 11px;
    width: 20px !important;
  }
  .clv_table tr td.bg_green,
  .clv_table tr td.available {
    background: #00800080;
  }
  .clv_table tr td.bg_red,
  .clv_table tr td.unavailable {
    background: #FF000080;
  }
  .clv_table tr td a {
    color: #FFFFFF;
  }
  .clv_table tr td a:hover {
    opacity: 0.8;
  }
  .clv_table input,
  .clv_table button {
    margin: -8px 0;
    border: none;
  }
  .clv_table .clv_toggle_button {
    float: right;
    margin: -4px 0;
  }
  .clv_table .blue {
    color: #A5E5FF;
  }
  .clv_table .green {
    color: #00FF00;
  }
  .clv_table .grey {
    color: #CCCCCC;
  }
  .clv_table .orange {
    color: #FFC660;
  }
  .clv_table .red {
    color: #FFAAAA;
  }
  .clv_chart {
    margin: 0 0 15px 0;
    padding: 10px;
    border: 3px solid #E6E6E6;
    border-radius: 5px;
    background: #FFFFFF;
    color: #333333;
  }
  .clv_chart .highcharts-title {
    text-align: center;
    font-size: 13px !important;
  }
  .clv_chart .highcharts-title a {
    color: #333333;
    text-decoration: none;
    font-family: 'OpenSansSemiBold', Arial, sans-serif;
  }
  .clv_chart .highcharts-credits {
    display: none;
  }
  .clv_chart .highcharts-tooltip > span {
    top: 1px !important;
    left: 1px !important;
    padding: 8px;
    background: #FFFFFF;
  }
  .clv_chart .clv_chart_description {
    padding: 5px 10px 10px 10px;
    text-align: center;
    font-size: 13px;
    line-height: 20px;
  }
  .clv_chart.columns {
    display: table;
  }
  .clv_chart.columns .clv_chart_highcharts {
    float: left;
    width: 50%;
  }
  .clv_chart.columns .clv_chart_description {
    float: right;
    padding: 0;
    width: 50%;
  }
  .clv_chart.columns .clv_chart_description div {
    vertical-align: middle;
    display: table-cell;
    height: 200px;
  }
  .clv_lightbox .vel-img {
    border: 5px solid #F1F1F1;
    border-radius: 5px;
  }
  .clv_tooltip_icon {
    margin: 0 0 0 10px;
    cursor: pointer;
  }
  .clv_tooltip_icon.orange {
    color: #FFC660;
  }
  .clv_loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -53px 0 0 -53px;
    text-align: center;
    display: block;
    width: 106px;
    height: 106px;
  }
  .clv_loading:before {
    content: 'CLV';
    color: #555555;
    font-family: 'OpenSansSemiBold', Arial, sans-serif;
    font-size: 20px;
    line-height: 106px;
  }
  .clv_loading:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border: 3px solid transparent;
    border-top: 3px solid #555555;
    border-radius: 100%;
    animation: loading .7s infinite linear;
    background: transparent;
    width: 100px;
    height: 100px;
  }
  .clv_bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: -1;
  }
  .clv_bg img {
    position: absolute;
    top: -5%;
    left: -5%;
    max-width: 110%;
    width: 110%;
    height: 110%;
    opacity: .3;
  }
  .tooltip {
    display: block !important;
    z-index: 10000;
  }
  .tooltip .tooltip-inner {
    padding: 10px 12px 12px 12px;
    border-radius: 10px;
    background: #222222;
    color: #FFFFFF;
    text-align: center;
    font-size: 11px;
    max-width: 290px;
  }
  .tooltip .tooltip-arrow {
    position: absolute;
    margin: 5px;
    border-style: solid;
    border-color: #222222;
    width: 0;
    height: 0;
    z-index: 1;
  }
  .tooltip[x-placement^='top'] {
    margin-bottom: 8px;
  }
  .tooltip[x-placement^='top'] .tooltip-arrow {
    left: calc(50% - 5px);
    bottom: -5px;
    margin-top: 0;
    margin-bottom: 0;
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
  }
  .tooltip[x-placement^='bottom'] {
    margin-top: 8px;
  }
  .tooltip[x-placement^='bottom'] .tooltip-arrow {
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
    border-width: 0 5px 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
  }
  .tooltip[x-placement^='right'] {
    margin-left: 8px;
  }
  .tooltip[x-placement^='right'] .tooltip-arrow {
    top: calc(50% - 5px);
    left: -5px;
    margin-left: 0;
    margin-right: 0;
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
  }
  .tooltip[x-placement^='left'] {
    margin-right: 8px;
  }
  .tooltip[x-placement^='left'] .tooltip-arrow {
    top: calc(50% - 5px);
    right: -5px;
    margin-left: 0;
    margin-right: 0;
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
  }
  .tooltip.popover .popover-inner {
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(black, .1);
    background: #F9F9F9;
    color: #000000;
  }
  .tooltip.popover .popover-arrow {
    border-color: #F9F9F9;
  }
  .tooltip[aria-hidden='true'] {
    transition: opacity .15s, visibility .15s;
    visibility: hidden;
    opacity: 0;
  }
  .tooltip[aria-hidden='false'] {
    transition: opacity .15s;
    visibility: visible;
    opacity: 1;
  }
  .clv .v--modal {
    padding: 15px;
    box-shadow: none;
    background: transparent;
    overflow: visible !important;
  }
  .clv .v--modal-overlay {
    background: #FFFFFFE6;
  }
  .v--modal-block-scroll {
    width: auto !important;
  }
  .clv_modal {
    position: relative;
    padding: 20px 30px 30px 30px;
    border-radius: 15px;
    background: #333333CC;
    color: #FFFFFF;
    font-size: 13px;
    line-height: 20px;
    width: 100%;
    height: 100%;
  }
  .clv_modal_open_loading {
    height: 120px;
  }
  .clv_modal_open_loading .clv_loading:before {
    color: #FFFFFF;
  }
  .clv_modal_open_loading .clv_loading:after {
    border-top-color: #FFFFFF;
  }
  .clv_modal_close {
    position: absolute;
    top: 20px;
    right: 30px;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    font-size: 10px;
    line-height: 18px;
    width: 22px;
    height: 22px;
  }
  .clv_modal_close:hover {
    border-color: #FFAAAA;
    color: #FFAAAA;
  }
  .clv_modal_title {
    margin: 0 0 20px 0;
    padding: 0 30px 20px 30px;
    border-bottom: 2px solid #FFFFFF;
    text-align: center;
    text-transform: uppercase;
    font-family: 'OpenSansSemiBold', Arial, sans-serif;
    font-size: 14px;
  }
  .clv_modal_body .alert {
    position: relative;
    margin: 10px 0 5px 0;
    padding: 6px 10px 8px 10px;
    border: 1px solid #FFFFFF;
    border-radius: 15px;
    background: #FFFFFF;
    color: #333333;
    text-align: center;
    font-size: 11px;
    line-height: 16px;
  }
  .clv_modal_info a {
    color: #A5E5FF;
    word-break: break-all;
    word-break: break-word;
  }
  .clv_modal_info input {
    text-align: left;
  }
  .clv_modal_info .blue {
    color: #A5E5FF;
  }
  .clv_modal_info .green {
    color: #00FF00;
  }
  .clv_modal_info .red {
    color: #FFAAAA;
  }
  .clv_modal_info .alert:before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 100%;
    margin-left: -8px;
    border: 8px solid transparent;
    border-bottom-color: #FFFFFF;
    pointer-events: none;
    width: 0;
    height: 0;
  }
  .clv_modal_info_row {
    display: table;
    width: 100%;
  }
  .clv_modal_info_row.last .alert {
    margin: 10px 0 0 0;
  }
  .clv_modal_info_row_label {
    float: left;
    line-height: 30px;
    width: 32%;
  }
  .clv_modal_info_row_label.blue {
    color: #A5E5FF;
  }
  .clv_modal_info_row_label.green {
    color: #00FF00;
  }
  .clv_modal_info_row_value {
    float: right;
    position: relative;
    width: 68%;
  }
  .clv_modal_info_row_value .vue-js-switch {
    margin: 4px 0 0 0;
  }
  .clv_modal_info_row_value_text {
    padding: 5px 0;
    word-break: break-all;
    word-break: break-word;
  }
  .clv_modal_info_CLVHelp_text {
    margin: 0 0 25px 0;
    display: table;
    width: 100%;
  }
  .clv_modal_info_CLVHelp_text:last-child,
  .clv_modal_info_CLVHelp_text p:last-child {
    margin: 0;
  }
  .clv_modal_info_CLVHelp_text img {
    border: 3px solid #FFFFFF;
    border-radius: 5px;
    box-shadow: 0 0 5px #333333;
    width: 100%;
  }
  .clv_modal_info_CLVHelp_text img.image_left {
    float: left;
    margin: 5px 20px 10px 0;
    width: 100px;
  }
  .clv_modal_info_CLVHelp_text ul li {
    margin: 0 0 0 20px;
    list-style: inside;
  }
  .clv_modal_alert .alert {
    margin: 15px 0 0 0;
    padding: 8px 10px 10px 10px;
  }
  .clv_modal_loading,
  .clv_modal_buttons {
    margin: 20px 0 0 0;
    text-align: right;
    line-height: 20px;
    min-height: 20px;
  }
  .clv_modal_buttons button {
    margin: 0 0 0 10px;
  }
  .clv_modal_buttons button:first-child {
    margin: 0;
  }
  .clv_modal_buttons button:hover {
    opacity: 0.8;
  }
  .clv_is_mobile {
    display: none;
  }

  ::-webkit-input-placeholder { color: #AAAAAA; }
  ::-moz-placeholder          { color: #AAAAAA; }
  :-moz-placeholder           { color: #AAAAAA; }
  :-ms-input-placeholder      { color: #AAAAAA; }
  input[placeholder]          { text-overflow: ellipsis; }
  input::-moz-placeholder     { text-overflow: ellipsis; }
  input:-moz-placeholder      { text-overflow: ellipsis; }
  input:-ms-input-placeholder { text-overflow: ellipsis; }

  @keyframes loading {
    from { transform: rotate(0); }
    to   { transform: rotate(359deg); }
  }
  @media (max-width: 1200px) {
    .clv_table thead {
      display: none;
    }
    .clv_table tr {
      margin: 0 0 10px 0;
      border-bottom: 1px solid #999999;
      display: block;
    }
    .clv_table tr:last-child {
      margin: 0;
    }
    .clv_table tr td {
      padding: 7px 12px;
      border-bottom: 0;
      text-align: right;
      font-size: 11px;
      display: block;
      width: auto !important;
      min-height: 32px;
    }
    .clv_table tr td:before {
      content: attr(data-label);
      float: left;
      padding: 0 5px 0 0;
      font-family: 'OpenSansSemiBold', Arial, sans-serif;
    }
    .clv_table tr td .clv_input {
      text-align: right;
      width: 60%;
    }
    .clv_table tr td .clv_select,
    .clv_table tr td .clv_toggle_button,
    .clv_table tr td .vue-js-switch {
      margin: -4px 0 -8px 0;
      vertical-align: baseline;
    }
  }
  @media (max-width: 1000px) {
    .clv_button.big {
      font-size: 11px;
    }
    .clv_tabs_mobile {
      margin: 0 0 10px 0;
    }
    .clv_chart .highcharts-title {
      font-size: 12px !important;
    }
    .clv_chart .clv_chart_description {
      font-size: 12px;
      line-height: 18px;
    }
    .clv .v--modal {
      padding: 55px 15px 15px 15px;
    }
  }
  @media (max-width: 600px) {
    .clv_chart .clv_chart_description {
      font-size: 11px;
      line-height: 16px;
    }
    .clv_chart.columns {
      display: block;
    }
    .clv_chart.columns .clv_chart_highcharts {
      float: none;
      width: auto;
    }
    .clv_chart.columns .clv_chart_description {
      float: none;
      padding: 5px 10px 10px 10px;
      width: auto;
    }
    .clv_chart.columns .clv_chart_description div {
      display: block;
      height: auto;
    }
    .clv_modal_info_row .clv_modal_info_row_label {
      float: none;
      text-align: left;
      line-height: 20px;
      width: 100%;
    }
    .clv_modal_info_row .clv_modal_info_row_value {
      float: none;
      margin: 0 0 20px 0;
      padding: 0;
      width: 100%;
    }
    .clv_modal_info_row.margin_top_20,
    .clv_modal_info_row.last .clv_modal_info_row_value {
      margin: 0;
    }
  }
  @media (max-width: 550px) {
    .clv_button.big {
      padding: 0 8px;
      font-size: 10px;
    }
    .clv_button.big svg {
      margin: 0 5px 0 0;
      font-size: 9px;
    }
    .clv_is_desktop {
      display: none;
    }
    .clv_is_mobile {
      display: initial;
    }
  }
  @media (max-width: 400px) {
    .clv_tab_menu_label {
      padding: 0 10px;
      min-width: 55px;
    }
  }
  @media (max-width: 350px) {
    .tooltip .tooltip-inner {
      max-width: 270px;
    }
    .clv_modal_buttons button {
      font-size: 12px;
    }
  }
</style>
