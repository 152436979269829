<template>
	<div class="clv">
		<div class="clv_bg"><img src="@/assets/images/bg.png"></div>
		<div class="clv_loading" :class="{ hide: !$store.state.clvIsLoading }"></div>
		<div class="clv_account" :class="{ hide: $store.state.clvIsLoading }">
			<div class="clv_account_sidebar">
				<div class="clv_account_sidebar_logo">
					<router-link to="/account">
						<div><img src="@/assets/images/logo.png"></div>
						<div>{{ $store.state.clvSiteName }}</div>
					</router-link>
				</div>
				<div class="clv_account_sidebar_menu">
					<router-link to="/account" class="account">CLV</router-link>
					<router-link to="/account/news">Новости</router-link>
					<router-link to="/account/settings">Настройки</router-link>
					<router-link to="/account/users" class="red" v-if="$store.state.clvAccountData.group == 1">Пользователи</router-link>
					<router-link to="/account/transliteration" class="red" v-if="$store.state.clvAccountData.group == 1">Транслитерация</router-link>
					<router-link to="/account/clover_params" class="red" v-if="$store.state.clvAccountData.group == 1">Настройки clover</router-link>
					<router-link to="/account/system_settings" class="red" v-if="$store.state.clvAccountData.group == 1">Настройки системы</router-link>
					<router-link to="/account/symbol_settings" class="red" v-if="$store.state.clvAccountData.group == 1">Настройки символов</router-link>
				</div>
			</div>
			<div class="clv_account_sidebar_mobile">
				<div class="clv_account_sidebar_mobile_title">
					<router-link to="/account"><img src="@/assets/images/mobile_logo.png"><span>CLV</span></router-link>
					<div v-html="$store.getters.getLanguageText('2', 0)"></div>
				</div>
				<div class="clv_account_sidebar_mobile_button" @click="$parent.toggleMobileMenu">
					<font-awesome-icon :icon="['fas', 'times']" v-if="$store.state.clvIsMobileMenu" />
					<font-awesome-icon :icon="['fas', 'bars']" v-else />
				</div>
				<div class="clv_account_sidebar_mobile_menu" :class="{ hidden: !$store.state.clvIsMobileMenu }">
					<div class="clv_account_sidebar_mobile_menu_content">
						<div class="clv_account_sidebar_mobile_menu_user_data">
							<div class="clv_account_sidebar_mobile_menu_user_id">
								<div><span v-html="$store.getters.getLanguageText('2', 1)"></span><span>: {{ $store.state.clvAccountData.id }}</span><span v-if="$store.state.clvAccountData.username"> ({{ $store.state.clvAccountData.username }})</span></div>
							</div>
						</div>
						<div class="clv_account_sidebar_mobile_menu_links">
							<div><router-link to="/account" class="account">CLV</router-link></div>
							<div><router-link to="/account/news">Новости</router-link></div>
							<div><router-link to="/account/settings">Настройки</router-link></div>
							<div v-if="$store.state.clvAccountData.group == 1"><router-link to="/account/users" class="red">Пользователи</router-link></div>
							<div v-if="$store.state.clvAccountData.group == 1"><router-link to="/account/transliteration" class="red">Транслитерация</router-link></div>
							<div v-if="$store.state.clvAccountData.group == 1"><router-link to="/account/clover_params" class="red">Настройки clover</router-link></div>
							<div v-if="$store.state.clvAccountData.group == 1"><router-link to="/account/system_settings" class="red">Настройки системы</router-link></div>
							<div v-if="$store.state.clvAccountData.group == 1"><router-link to="/account/symbol_settings" class="red">Настройки символов</router-link></div>
							<div><a class="red" @click="$parent.exit">Выход</a></div>
						</div>
						<div class="clv_account_sidebar_mobile_menu_language">
							<div class="clv_account_sidebar_mobile_menu_language_name" :class="{ hide: !$store.state.clvIsLanguageRU }"><img src="@/assets/images/loading.gif"></div>
							<div class="clv_account_sidebar_mobile_menu_language_name text" :class="{ hide: $store.state.clvIsLanguageRU, active: $store.state.clvLanguage == 'ru' }" @click="selectLanguage('ru')"><span v-html="$store.getters.getLanguageText('2', 2, { options: [0] })"></span></div>
							<div class="clv_account_sidebar_mobile_menu_language_name" :class="{ hide: !$store.state.clvIsLanguageEN }"><img src="@/assets/images/loading.gif"></div>
							<div class="clv_account_sidebar_mobile_menu_language_name text" :class="{ hide: $store.state.clvIsLanguageEN, active: $store.state.clvLanguage == 'en' }" @click="selectLanguage('en')"><span v-html="$store.getters.getLanguageText('2', 2, { options: [1] })"></span></div>
						</div>
					</div>
				</div>
			</div>
			<div class="clv_account_desktop">
				<div class="clv_account_desktop_header">
					<div class="clv_account_desktop_header_item">
						<div class="clv_account_desktop_header_item_language">
							<div class="clv_account_desktop_header_item_language_name" :class="{ hide: !$store.state.clvIsLanguageRU }"><img src="@/assets/images/loading.gif"></div>
							<div class="clv_account_desktop_header_item_language_name text" :class="{ hide: $store.state.clvIsLanguageRU, active: $store.state.clvLanguage == 'ru' }" @click="selectLanguage('ru')" v-html="$store.getters.getLanguageText('2', 2, { options: [0] })"></div>
							<div class="clv_account_desktop_header_item_language_name" :class="{ hide: !$store.state.clvIsLanguageEN }"><img src="@/assets/images/loading.gif"></div>
							<div class="clv_account_desktop_header_item_language_name text" :class="{ hide: $store.state.clvIsLanguageEN, active: $store.state.clvLanguage == 'en' }" @click="selectLanguage('en')" v-html="$store.getters.getLanguageText('2', 2, { options: [1] })"></div>
						</div>
					</div>
					<div class="clv_account_desktop_header_item menu icon red" @click="$parent.exit"><font-awesome-icon :icon="['fas', 'sign-out-alt']" /></div>
					<router-link to="/account/settings" class="clv_account_desktop_header_item menu icon blue"><font-awesome-icon :icon="['fas', 'cog']" /></router-link>
					<div class="clv_account_desktop_header_item text"><span v-html="$store.getters.getLanguageText('2', 1)"></span>: <span>{{ $store.state.clvAccountData.id }}</span></div>
				</div>
				<div class="clv_account_desktop_content"><router-view></router-view></div>
				<div class="clv_account_desktop_footer">
					<div class="clv_account_desktop_footer_copyright">
						<div>2019 − {{ new Date().getFullYear() }} © {{ $store.state.clvSiteName }}</div>
						<div v-html="$store.getters.getLanguageText('2', 4, { version: $store.state.clvAppVersion })"></div>
						<div v-html="$store.getters.getLanguageText('2', 3)"></div>
					</div>
				</div>
			</div>
			<div class="clv_account_preload">
				<img src="@/assets/images/CLVHelp1.png">
				<img src="@/assets/images/CLVHelp2.png">
				<img src="@/assets/images/CLVHelp3.png">
				<img src="@/assets/images/CLVHelp4.png">
				<img src="@/assets/images/CLVHelp5.png">
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		methods: {
			isReady: function() {
				this.axios('getAccountData');
			},
			axios: function(type, var1) {

				var _this = this;
				if (localStorage.getItem('clvToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.clvApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('clvToken') }
					};

					if (type == 'getAccountData') {
						_this.$store.state.clvIsAccount = true;
						_this.$store.state.clvIsLoading = true;
						config.url = '/v2/account';
					}
					else if (type == 'selectLanguage') {
						config.url = '/v2/account/users/language';
						config.data = { language: var1 };
						config.method = 'patch';
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							_this.$parent.goToTop(true);
							_this.$parent.checkAppVersion(data.headers);
							if (type == 'getAccountData') {
								if (data.data.account_data !== undefined) {
									_this.$parent.initAccountData(data.data.account_data);
								}
								if (data.data.system_settings !== undefined) {
									_this.$parent.initSystemSettings(data.data.system_settings);
								}
							}
						}
					}).catch(function() {
						_this.$parent.exit();
					}).finally(function() {
						_this.$store.state.clvIsAccount = false;
					});
				}
				else {
					_this.$parent.exit();
				}
			},
			selectLanguage: function(language) {
				if (this.$store.state.clvLanguage !== language && this.$store.state.clvLangs.includes(language)) {
					this.$parent.setLanguage(language);
					this.axios('selectLanguage', language);
				}
			}
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.clv_account {
		color: #FFFFFF;
	}
	.clv_account_sidebar {
		float: left;
		padding: 30px;
		text-align: center;
		width: 300px;
	}
	.clv_account_sidebar_logo {
		margin: 5px 0 30px 0;
	}
	.clv_account_sidebar_logo a {
		text-decoration: none;
	}
	.clv_account_sidebar_logo img {
		margin: 0 0 -5px 0;
		height: 60px;
		opacity: 0.4;
	}
	.clv_account_sidebar_logo div {
		color: #555555;
		display: inline-block;
		width: 60%;
	}
	.clv_account_sidebar_logo div:last-child {
		margin: 10px 0 0 0;
		font-size: 15px;
	}
	.clv_account_sidebar_menu a {
		position: relative;
		margin: 8px 0;
		border-radius: 16px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		background: #33333380;
		color: #FFFFFF;
		text-decoration: none;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 32px;
		display: block;
	}
	.clv_account_sidebar_menu a:hover,
	.clv_account_sidebar_menu a.router-link-exact-active,
	.clv_account_sidebar_menu a.router-link-active:not(.account) {
		background: #333333B3;
	}
	.clv_account_sidebar_menu a.orange {
		background: #CC840099;
	}
	.clv_account_sidebar_menu a.orange:hover,
	.clv_account_sidebar_menu a.orange.router-link-active {
		background: #CC8400CC;
	}
	.clv_account_sidebar_menu a.red {
		background: #FF000080;
	}
	.clv_account_sidebar_menu a.red:hover,
	.clv_account_sidebar_menu a.red.router-link-active {
		background: #FF0000B3;
	}
	.clv_account_sidebar_menu a:last-child {
		margin: 8px 0 0 0;
	}
	.clv_account_sidebar_mobile {
		position: fixed;
		background: #444444;
		text-align: center;
		display: none;
		width: 100%;
		height: 40px;
		z-index: 100000;
	}
	.clv_account_sidebar_mobile_title {
		float: left;
		margin: 0 0 0 15px;
		text-align: left;
	}
	.clv_account_sidebar_mobile_title a {
		color: #FFFFFF;
		text-decoration: none;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 16px;
		line-height: 38px;
		display: block;
	}
	.clv_account_sidebar_mobile_title a img {
		margin: -2px 2px 0 0;
		height: 13px;
	}
	.clv_account_sidebar_mobile_title div {
		position: absolute;
		top: 11px;
		right: 40px;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 12px;
	}
	.clv_account_sidebar_mobile_button {
		position: fixed;
		top: 0;
		right: 0;
		padding: 0 15px 0 0;
		cursor: pointer;
		text-align: right;
		font-size: 15px;
		line-height: 38px;
		width: 95px;
		height: 40px;
		z-index: 1;
	}
	.clv_account_sidebar_mobile_menu {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		-webkit-transform: translate(0);
		-moz-transform: translate(0);
		-ms-transform: translate(0);
		-o-transform: translate(0);
		transform: translate(0);
		-webkit-transition: all .1s linear 0s;
		-moz-transition: all .1s linear 0s;
		-o-transition: all .1s linear 0s;
		transition: all .1s linear 0s;
		background: #555555F2;
		flex-direction: column;
		justify-content: center;
		display: flex;
		width: 100%;
	}
	.clv_account_sidebar_mobile_menu.hidden {
		-webkit-transform: translate(100%);
		-moz-transform: translate(100%);
		-ms-transform: translate(100%);
		-o-transform: translate(100%);
		transform: translate(100%);
	}
	.clv_account_sidebar_mobile_menu_content {
		padding: 40px 10px;
		overflow-y: auto;
	}
	.clv_account_sidebar_mobile_menu_content a {
		margin: 5px 0;
		border-bottom: 1px solid transparent;
		color: #FFFFFF;
		text-decoration: none;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 14px;
		line-height: 22px;
		display: inline-block;
	}
	.clv_account_sidebar_mobile_menu_content a:hover,
	.clv_account_sidebar_mobile_menu_content a.router-link-exact-active,
	.clv_account_sidebar_mobile_menu_content a.router-link-active:not(.account) {
		border-bottom: 1px solid;
	}
	.clv_account_sidebar_mobile_menu_content a.orange {
		color: #FFC660;
	}
	.clv_account_sidebar_mobile_menu_content a.red {
		color: #FFAAAA;
	}
	.clv_account_sidebar_mobile_menu_user_data {
		margin: 0 0 20px 0;
		color: #F5F5DC;
		overflow: hidden;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 11px;
		line-height: 28px;
	}
	.clv_account_sidebar_mobile_menu_user_id {
		text-transform: none;
	}
	.clv_account_sidebar_mobile_menu_login {
		margin: 20px 0 -20px 0;
	}
	.clv_account_sidebar_mobile_menu_login div {
		padding: 0 8px;
		text-align: right;
		display: inline-block;
		width: 50%;
	}
	.clv_account_sidebar_mobile_menu_login div:last-child {
		text-align: left;
	}
	.clv_account_sidebar_mobile_menu_login div a {
		font-size: 11px;
	}
	.clv_account_sidebar_mobile_menu_language {
		margin: 20px 0 5px 0;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 11px;
	}
	.clv_account_sidebar_mobile_menu_language_name {
		margin: 0 6px;
		line-height: 15px;
		display: inline-block;
		width: 24px;
	}
	.clv_account_sidebar_mobile_menu_language_name img {
		margin: -2px 0 0 0;
	}
	.clv_account_sidebar_mobile_menu_language_name.text {
		cursor: pointer;
	}
	.clv_account_sidebar_mobile_menu_language_name.text.active {
		border-bottom: 1px solid #FFFFFF;
		cursor: default;
	}
	.clv_account_desktop {
		padding: 30px 30px 30px 300px;
		width:100%;
	}
	.clv_account_desktop_header {
		margin: 0 0 22px 0;
		display: table;
		width: 100%;
	}
	.clv_account_desktop_header_item {
		float: right;
		position: relative;
		margin: 0 0 8px 8px;
		padding: 0 15px;
		border-radius: 15px;
		background: #33333380;
		color: #FFFFFF;
		text-align: center;
		text-decoration: none;
		line-height: 30px;
	}
	.clv_account_desktop_header_item:first-child {
		padding: 0 10px;
	}
	.clv_account_desktop_header_item:last-child {
		margin: 0 0 8px 0;
	}
	.clv_account_desktop_header_item.menu {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		cursor: pointer;
	}
	.clv_account_desktop_header_item.menu:hover,
	.clv_account_desktop_header_item.menu.router-link-active {
		background: #333333B3;
	}
	.clv_account_desktop_header_item.menu.blue {
		background: #0066BB99;
	}
	.clv_account_desktop_header_item.menu.blue:hover,
	.clv_account_desktop_header_item.menu.blue.active,
	.clv_account_desktop_header_item.menu.blue.icon_active,
	.clv_account_desktop_header_item.menu.blue.router-link-active {
		background: #0066BBCC;
	}
	.clv_account_desktop_header_item.menu.red {
		background: #FF000080;
	}
	.clv_account_desktop_header_item.menu.red:hover,
	.clv_account_desktop_header_item.menu.red.active,
	.clv_account_desktop_header_item.menu.red.icon_active,
	.clv_account_desktop_header_item.menu.red.router-link-active {
		background: #FF0000B3;
	}
	.clv_account_desktop_header_item.text {
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
	}
	.clv_account_desktop_header_item.icon {
		font-size: 14px;
	}
	.clv_account_desktop_header_item.icon svg {
		min-width: 15px;
	}
	.clv_account_desktop_header_item.language {
		padding: 0 10px;
	}
	.clv_account_desktop_header_item_language {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 11px;
		width: 80px;
	}
	.clv_account_desktop_header_item_language_name {
		margin: 0 6px;
		line-height: 15px;
		display: inline-block;
		width: 24px;
	}
	.clv_account_desktop_header_item_language_name img {
		margin: -2px 0 0 0;
	}
	.clv_account_desktop_header_item_language_name.text {
		cursor: pointer;
	}
	.clv_account_desktop_header_item_language_name.text:hover,
	.clv_account_desktop_header_item_language_name.text:focus {
		border-bottom: 1px solid #FFFFFF;
	}
	.clv_account_desktop_header_item_language_name.text.active {
		border-bottom: 1px solid #FFFFFF;
		cursor: default;
	}
	.clv_account_desktop_page_loading {
		position: relative;
		height: 300px;
		height: calc(100vh - 120px);
		min-height: 200px;
	}
	.clv_account_desktop_page_content_title,
	.clv_account_desktop_page_content_navigation_title {
		margin: 0 0 2px 0;
		border-radius: 16px;
		background: #333333B3;
		text-align: center;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 13px;
		line-height: 30px;
		height: 32px;
	}
	.clv_account_desktop_page_content_navigation {
		margin: 0 0 15px 0;
		display: table;
		width: 100%;
	}
	.clv_account_desktop_page_content_navigation.extra {
		margin: -13px 0 15px 0;
	}
	.clv_account_desktop_page_content_navigation_search {
		position: relative;
		margin: 2px 0 0 0;
		display: table;
		width: 100%;
	}
	.clv_account_desktop_page_content_navigation_search input {
		position: relative;
		padding: 0 40px 1px 15px;
		border: 1px solid #AAAAAA;
		border-radius: 15px;
		box-shadow: inset 0 0 2px 0 #888888;
		background: #FFFFFF;
		color: #555555;
		text-align: left;
		z-index: 3;
	}
	.clv_account_desktop_page_content_navigation_search_button {
		position: absolute;
		top: 0;
		right: 0;
		border-radius: 0 15px 15px 0;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		color: #777777;
		cursor: pointer;
		text-align: center;
		line-height: 30px;
		width: 40px;
		height: 30px;
		z-index: 4;
	}
	.clv_account_desktop_page_content_navigation_search_button:hover {
		color: #555555;
	}
	.clv_account_desktop_page_content_navigation_search_result {
		position: absolute;
		padding: 0 15px;
		width: 100%;
		z-index: 2;
	}
	.clv_account_desktop_page_content_navigation_search_result_block {
		padding: 10px 15px 12px 15px;
		border-radius: 0 0 15px 15px;
		box-shadow: 0 0 3px #777777;
		background: #FFFFFF;
		color: #555555;
	}
	.clv_account_desktop_page_content_navigation_search_result_content_link {
		cursor: pointer;
	}
	.clv_account_desktop_page_content_navigation_search_result_content_link:hover {
		text-decoration: underline;
	}
	.clv_account_desktop_page_content_navigation_search_background {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #777777;
		opacity: 0.3;
		z-index: 1;
	}
	.clv_account_desktop_page_content_navigation_filters {
		position: relative;
		padding: 0 0 0 105px;
		display: table;
		width: 100%;
	}
	.clv_account_desktop_page_content_navigation_filters_label {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 15px;
		border-radius: 15px 2px 2px 15px;
		background: #333333B3;
		text-align: center;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 28px;
		width: 105px;
		height: 30px;
	}
	.clv_account_desktop_page_content_navigation_filters_block,
	.clv_account_desktop_page_content_navigation_filters_value {
		float: left;
		padding: 0 0 0 2px;
		width: 100%;
	}
	.clv_account_desktop_page_content_navigation_filters_block.col2,
	.clv_account_desktop_page_content_navigation_filters_value.col2 {
		width: 50%;
	}
	.clv_account_desktop_page_content_navigation_filters_block.col3,
	.clv_account_desktop_page_content_navigation_filters_value.col3 {
		width: 33.33333%;
	}
	.clv_account_desktop_page_content_navigation_filters_value div {
		border-radius: 2px;
		background: #111111B3;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		cursor: pointer;
		overflow: hidden;
		text-align: center;
		text-transform: lowercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 30px;
		width: 100%;
		height: 30px;
	}
	.clv_account_desktop_page_content_navigation_filters_value.active div,
	.clv_account_desktop_page_content_navigation_filters_value:hover div {
		background: #111111CC;
	}
	.clv_account_desktop_page_content_navigation_filters_value:last-child div {
		border-radius: 2px 15px 15px 2px;
	}
	.clv_account_desktop_page_content_navigation_filters_select {
		padding: 0 15px;
		border-radius: 2px;
		background: #111111B3;
		width: 100%;
		height: 30px;
	}
	.clv_account_desktop_page_content_navigation_filters_select.last {
		border-radius: 2px 15px 15px 2px;
	}
	.clv_account_desktop_page_content_navigation_filters_select .clv_select .vs__dropdown-toggle {
		padding: 6px 0 7px 0;
		border: none;
	}
	.clv_account_desktop_page_content_navigation_filters_checkboxes {
		padding: 0 15px;
		border-radius: 2px;
		background: #111111B3;
		width: 100%;
		min-height: 30px;
	}
	.clv_account_desktop_page_content_navigation_filters_checkboxes:last-child {
		border-radius: 2px 15px 15px 2px;
	}
	.clv_account_desktop_page_content_navigation_filters_checkboxes .clv_checkbox {
		margin: 6px 15px 8px 0;
		display: inline-block;
	}
	.clv_account_desktop_page_content_navigation_filters_checkboxes .clv_checkbox:last-child {
		margin: 6px 0 8px 0;
	}
	.clv_account_desktop_page_content_navigation_filters_checkboxes .clv_checkbox_mark {
		top: 1px;
	}
	.clv_account_desktop_page_content_loading {
		line-height: 35px;
		height: 35px;
	}
	.clv_account_desktop_page_content_loading,
	.clv_account_desktop_page_content_buttons {
		margin: 15px 0 20px 0;
		text-align: right;
	}
	.clv_account_desktop_portlet {
		position: relative;
		margin: 0 0 15px 0;
		padding: 30px;
		border-radius: 15px;
		background: #333333B3;
		color: #FFFFFF;
		font-size: 13px;
		line-height: 22px;
	}
	.clv_account_desktop_portlet.blue {
		background: #0066BBB3;
	}
	.clv_account_desktop_portlet.green {
		background: #008000B3;
	}
	.clv_account_desktop_portlet.grey {
		background: #CCCCCC99;
		color: #555555;
	}
	.clv_account_desktop_portlet.red {
		background: #FF000080;
	}
	.clv_account_desktop_portlet.violet {
		background: #883388B3;
	}
	.clv_account_desktop_portlet.transparent {
		padding: 0;
		background: transparent;
	}
	.clv_tabs .clv_account_desktop_portlet:first-child {
		border-radius: 0 15px 15px 15px;
	}
	.clv_account_desktop_portlet_title {
		margin: 0 0 25px 0;
		padding: 0 0 10px 0;
		border-bottom: 2px solid #FFFFFF;
		text-align: center;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 14px;
	}
	.clv_account_desktop_portlet_content p {
		margin: 0 0 15px 0;
	}
	.clv_account_desktop_portlet_content p:last-child {
		margin: 0;
	}
	.clv_account_desktop_portlet_content a {
		color: #A5E5FF;
	}
	.clv_account_desktop_portlet_content a:hover {
		opacity: 0.8;
	}
	.clv_account_desktop_portlet_content_description {
		margin: 0 0 15px 0;
	}
	.clv_account_desktop_portlet_content_row {
		position: relative;
		margin: 0 0 5px 0;
		line-height: 30px;
		display: table;
		width: 100%;
		min-height: 30px;
	}
	.clv_account_desktop_portlet_content_row.single .clv_account_desktop_portlet_content_label {
		float: left;
		position: relative;
		padding: 0 10px 0 0;
		width: auto;
	}
	.clv_account_desktop_portlet_content_row.single .clv_account_desktop_portlet_content_value {
		float: left;
		padding: 0;
		width: auto;
	}
	.clv_account_desktop_portlet_content_label {
		position: absolute;
		text-align: right;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		width: 280px;
		z-index: 1;
	}
	.clv_account_desktop_portlet_content_value {
		position: relative;
		padding: 0 0 0 310px;
	}
	.clv_account_desktop_portlet_content_value .clv_input {
		margin: 0 10px 0 0;
		text-align: left;
		line-height: 26px;
		width: 200px;
		height: 26px;
	}
	.clv_account_desktop_portlet_content_value_editing_alert {
		display: table;
		width: 100%;
	}
	.clv_account_desktop_portlet_content_value_editing_alert .alert {
		position: relative;
		margin: 15px 0 0 0;
		padding: 6px 10px 8px 10px;
		border: 1px solid #FFFFFF;
		border-radius: 15px;
		background: #FFFFFF;
		color: #333333;
		text-align: center;
		font-size: 11px;
		line-height: 16px;
		width: 200px;
	}
	.clv_account_desktop_portlet_content_value_content {
		padding: 5px 0;
		line-height: 20px;
	}
	.clv_account_desktop_portlet_content_value_content .vue-js-switch {
		margin: -7px 0 -4px 0;
	}
	.clv_account_desktop_portlet_content_value_content .clv_value {
		word-break: break-all;
		word-break: break-word;
	}
	.clv_account_desktop_portlet_content_value_content .clv_value.blue {
		color: #A5E5FF;
	}
	.clv_account_desktop_portlet_content_value_content .clv_value.green {
		color: #00FF00;
	}
	.clv_account_desktop_portlet_content_value_content .clv_value.red {
		color: #FFBBBB;
	}
	.clv_account_desktop_portlet_content_value_content .clv_value,
	.clv_account_desktop_portlet_content_value_buttons button {
		margin: 0 10px 0 0;
	}
	.clv_account_desktop_portlet_content_value_buttons .clv_button.clv_is_mobile {
		margin: 0 5px 0 0;
	}
	.clv_account_desktop_portlet_content_value_buttons button.clv_is_desktop.last,
	.clv_account_desktop_portlet_content_value_buttons button.clv_is_mobile.last,
	.clv_account_desktop_portlet_content_value_buttons button:last-child {
		margin: 0;
	}
	.clv_account_desktop_portlet_alert .alert {
		margin: 20px 0 0 0;
		color: #FF9999;
	}
	.clv_account_desktop_portlet_loading {
		margin: 20px 0 0 0;
		text-align: right;
		line-height: 35px;
		height: 35px;
	}
	.clv_account_desktop_portlet_buttons {
		margin: 20px 0 0 0;
		text-align: right;
	}
	.clv_account_desktop_footer {
		margin: 30px 0 0 0;
		color: #555555;
		text-align: center;
	}
	.clv_account_preload {
		position: absolute;
		display: none;
		width: 0;
		height: 0;
	}

	@media (max-width: 1080px) {
		.clv_account_desktop_portlet_content_label {
			width: 255px;
		}
		.clv_account_desktop_portlet_content_value {
			padding: 0 0 0 285px;
		}
		.clv_account_desktop_portlet_content_value .clv_input {
			width: 154px;
		}
	}
	@media (max-width: 1000px) {
		.clv_account_sidebar {
			position: fixed;
			background: #FFFFFF;
			overflow-x: hidden;
			overflow-y: auto;
			display: none;
			height: 100vh;
			z-index: 1;
		}
		.clv_account_sidebar_mobile {
			display: block;
		}
		.clv_account_desktop {
			padding: 50px 10px 20px 10px;
		}
		.clv_account_desktop_header {
			display: none;
		}
		.clv_account_desktop_page_loading {
			height: calc(100vh - 60px);
		}
		.clv_account_desktop_page_content_title,
		.clv_account_desktop_page_content_navigation_title {
			font-size: 12px;
		}
		.clv_account_desktop_page_content_navigation {
			margin: 0 0 10px 0;
		}
		.clv_account_desktop_page_content_navigation.extra {
			margin: -8px 0 10px 0;
		}
		.clv_account_desktop_page_content_loading,
		.clv_account_desktop_page_content_buttons {
			text-align: center;
		}
		.clv_account_desktop_portlet {
			margin: 0 0 10px 0;
			padding: 20px;
			font-size: 12px;
			line-height: 20px;
		}
		.clv_account_desktop_portlet_title {
			margin: 0 0 20px 0;
			font-size: 13px;
		}
		.clv_account_desktop_portlet_content_value .clv_input {
			width: 200px;
		}
		.clv_account_desktop_portlet_loading,
		.clv_account_desktop_portlet_buttons {
			margin: 15px 0 0 0;
		}
		.clv_account_desktop_footer {
			margin: 20px 0 0 0;
		}
	}
	@media (max-width: 800px) {
		.clv_account_desktop_portlet_content_row {
			margin: 0;
		}
		.clv_account_desktop_portlet_content_label {
			float: left;
			position: relative;
			padding: 0 10px 0 0;
			width: 50%;
		}
		.clv_account_desktop_portlet_content_value {
			float: right;
			padding: 0 0 0 10px;
			width: 50%;
		}
		.clv_account_desktop_portlet_content_value .clv_input {
			width: 180px;
		}
		.clv_account_desktop_portlet_content_value_editing .clv_is_desktop {
			display: none;
		}
		.clv_account_desktop_portlet_content_value_editing .clv_is_mobile {
			display: inline;
		}
		.clv_account_desktop_portlet_content_value_editing_alert .alert {
			margin: 10px 0 0 0;
		}
	}
	@media (max-width: 650px) {
		.clv_account_desktop_page_content_title {
			margin: 0 0 10px 0;
		}
		.clv_account_desktop_page_content_navigation.extra {
			margin: 0 0 10px 0;
		}
		.clv_account_desktop_page_content_navigation_filters {
			padding: 0;
		}
		.clv_account_desktop_page_content_navigation_filters_label {
			position: relative;
			border-radius: 15px 15px 2px 2px;
			width: 100%;
		}
		.clv_account_desktop_page_content_navigation_filters_block,
		.clv_account_desktop_page_content_navigation_filters_value {
			padding: 2px 0 0 0;
			width: 100% !important;
		}
		.clv_account_desktop_page_content_navigation_filters_value:last-child div,
		.clv_account_desktop_page_content_navigation_filters_select.last,
		.clv_account_desktop_page_content_navigation_filters_checkboxes:last-child {
			border-radius: 2px 2px 15px 15px;
		}
		.clv_account_desktop_page_content_navigation_filters_checkboxes {
			padding: 15px;
		}
		.clv_account_desktop_page_content_navigation_filters_checkboxes .clv_checkbox,
		.clv_account_desktop_page_content_navigation_filters_checkboxes .clv_checkbox:last-child {
			margin: 0 0 5px 0;
			width: 100%;
		}
		.clv_account_desktop_page_content_navigation_filters_checkboxes .clv_checkbox:last-child {
			margin: 0 0 3px 0;
		}
	}
	@media (max-width: 644px) {
		.clv_account_desktop_footer.account {
			padding: 0;
		}
	}
	@media (max-width: 550px) {
		.clv_account_desktop_portlet_content_row {
			font-size: 11px;
			line-height: 26px;
			min-height: 26px;
		}
		.clv_account_desktop_portlet_content_label {
			width: auto;
		}
		.clv_account_desktop_portlet_content_value {
			float: left;
			padding: 0;
			width: auto;
		}
		.clv_account_desktop_portlet_content_value .clv_input {
			line-height: 20px;
			width: 200px;
			height: 20px;
		}
		.clv_account_desktop_portlet_content_value_editing_alert .alert {
			margin: 10px 0 5px 0;
			width: 100%;
		}
		.clv_account_desktop_portlet_content_value_content {
			padding: 3px 0;
		}
		.clv_account_desktop_portlet_loading {
			margin: 10px 0 0 0;
			text-align: center;
		}
		.clv_account_desktop_portlet_buttons .clv_button {
			margin: 5px 0 0 0;
			width: 100%;
		}
		.clv_account_desktop_portlet_buttons .clv_button:first-child {
			margin: 0;
		}
	}
</style>
