import Vue from 'vue'
import App from '@/views/App.vue'
import store from '@/assets/scripts/store'
import router from '@/assets/scripts/router'
import cookies from '@/assets/scripts/cookies'
import components from '@/assets/scripts/components'

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  cookies,
  components,
  render: h => h(App)
}).$mount('#app');

router.beforeEach(function (to, from, next) {
	document.body.style.overflow = 'visible';
	store.state.clvIsMobileMenu = false;
	next();
});
