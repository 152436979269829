<template>
	<div class="clv_account_desktop_page">
		<div class="clv_account_desktop_page_loading" v-if="clvIsLoading"><div class="clv_loading"></div></div>
		<div class="clv_account_desktop_page_content" v-else>
			<div class="clv_account_desktop_portlet">
				<div class="clv_account_desktop_portlet_title">Настройки символов</div>
				<div class="clv_account_desktop_portlet_content">
					<div class="clv_account_desktop_symbol_settings">
						<table class="clv_table">
							<thead>
								<tr>
									<th>№</th>
									<th>Символ</th>
									<th>Тип символа</th>
									<th><font-awesome-icon :icon="['fas', 'pencil-alt']" /></th>
									<th><font-awesome-icon :icon="['fas', 'times']" /></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, index) in clvSymbolSettingsData" :key="index">
									<td data-label="№:">{{ (index + 1) }}</td>
									<td data-label="Символ:">{{ value.index.toUpperCase() }}</td>
									<td data-label="Тип символа:">{{ value.type }}</td>
									<td data-label="Изменить:"><button type="button" class="clv_button blue icon" @click="$modal.show('ClvModalSymbolSettings', { type: 'editSymbol', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button></td>
									<td data-label="Удалить:"><button type="button" class="clv_button red icon" @click="$modal.show('ClvModalSymbolSettings', { type: 'deleteSymbol', index: index, id: value.id })"><font-awesome-icon :icon="['fas', 'times']" /></button></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="clv_account_desktop_portlet_loading" :class="{ hide: !clvIsPortletLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="clv_account_desktop_portlet_buttons" :class="{ hide: clvIsPortletLoading }">
					<button type="button" class="clv_button big" @click="axios('getSymbolSettingsData')" v-if="clvSymbolSettingsDataLimitOffset"><font-awesome-icon :icon="['fas', 'arrow-down']" />Показать еще</button>
					<button type="button" class="clv_button big green" @click="$modal.show('ClvModalSymbolSettings', { type: 'addSymbol' })"><font-awesome-icon :icon="['fas', 'plus']" />Добавить символ</button>
				</div>
			</div>
			<div class="clv_account_desktop_modals">
				<div is="ClvModalSymbolSettings"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			clvSymbolSettingsData: [],
			clvSymbolSettingsDataLimitOffset: 0,
			clvSymbolSettingsDataLimitCount: 100,
			clvIsPortletLoading: false,
			clvIsLoading: true
		}),
		methods: {
			isReady: function() {
				if (this.$store.state.clvIsAccount) {
					setTimeout(this.isReady, 0); return false;
				}
				if (this.$store.state.clvAccountData.group == 1) {
					this.axios('getSymbolSettingsData');
				}
				else {
					this.$parent.$parent.exit();
				}
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('clvToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.clvApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('clvToken') }
					};

					if (type == 'getSymbolSettingsData') {
						if (!_this.clvSymbolSettingsDataLimitOffset) {
							_this.clvIsLoading = true;
							_this.$store.state.clvIsAccount = true;
						}
						_this.clvIsPortletLoading = true;
						config.url = '/v2/admin/account/symbol_settings';
						config.params = { limit_offset: _this.clvSymbolSettingsDataLimitOffset, limit_count: _this.clvSymbolSettingsDataLimitCount };
					}

					axios(config).then(function(data) {
						if (data.status == 200) {
							if (type == 'getSymbolSettingsData') {
								_this.$parent.$parent.goToTop(!_this.clvSymbolSettingsDataLimitOffset ? true : false);
								_this.$parent.$parent.checkAppVersion(data.headers);
								if (data.data.data.length) {
									for (var i in data.data.data) {
										_this.clvSymbolSettingsData.push(data.data.data[i]);
									}
								}
								_this.clvSymbolSettingsDataLimitOffset = (data.data.count > _this.clvSymbolSettingsDataLimitOffset + _this.clvSymbolSettingsDataLimitCount ? _this.clvSymbolSettingsDataLimitOffset + _this.clvSymbolSettingsDataLimitCount : 0);
							}
						}
					}).catch(function() {
						_this.$parent.$parent.exit();
					}).finally(function() {
						_this.clvIsLoading = false;
						_this.clvIsPortletLoading = false;
						_this.$store.state.clvIsAccount = false;
						_this.$store.state.clvIsLoading = false;
					});
				}
				else {
					_this.$parent.$parent.exit();
				}
			}
		},
		components: {
			ClvModalSymbolSettings: () => import('@/views/modal_components/ClvModalSymbolSettings.vue')
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.clv_account_desktop_symbol_settings tr th,
	.clv_account_desktop_symbol_settings tr td {
		width: 50%;
	}
	.clv_account_desktop_symbol_settings tr th:nth-child(1),
	.clv_account_desktop_symbol_settings tr td:nth-child(1),
	.clv_account_desktop_symbol_settings tr th:nth-child(4),
	.clv_account_desktop_symbol_settings tr td:nth-child(4),
	.clv_account_desktop_symbol_settings tr th:nth-child(5),
	.clv_account_desktop_symbol_settings tr td:nth-child(5) {
		min-width: 45px;
		width: 1%;
	}
</style>
