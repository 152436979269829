<template>
	<div class="clv">
		<div class="clv_bg"><img src="@/assets/images/bg.png"></div>
		<div class="clv_auth login">
			<div class="clv_auth_block">
				<div is="ClvAuthHeader"></div>
				<div class="clv_auth_block_content">
					<div class="clv_auth_block_content_name">Авторизация</div>
					<div class="clv_auth_block_content_form">
						<form>
							<div class="clv_auth_block_content_form_row">
								<div class="clv_auth_block_content_form_row_label">E-mail</div>
								<div class="clv_auth_block_content_form_row_input">
									<input type="text" class="clv_input" id="email" name="email" v-model.trim="clvEmail" autocomplete="off">
									<div v-html="clvAlertEmail"></div>
								</div>
							</div>
							<div class="clv_auth_block_content_form_row last">
								<div class="clv_auth_block_content_form_row_label">Пароль</div>
								<div class="clv_auth_block_content_form_row_input">
									<input type="password" class="clv_input" id="password" name="password" v-model.trim="clvPassword" autocomplete="off">
									<div v-html="clvAlertPassword"></div>
								</div>
							</div>
							<div class="clv_auth_block_content_form_row last">
								<div class="clv_auth_block_content_form_row_recaptcha">
									<vue-recaptcha class="clv_recaptcha" ref="recaptcha" size="normal" theme="light" :sitekey="$store.state.clvRecaptchaSiteKey" @verify="verifyRecaptcha" @expired="resetRecaptcha"></vue-recaptcha>
									<div v-html="clvAlertRecaptcha"></div>
								</div>
							</div>
							<div class="clv_auth_block_content_form_alert" v-html="clvAlert"></div>
							<div class="clv_auth_block_content_form_loading" :class="{ hide: !clvIsLoading }"><img src="@/assets/images/loading.gif"></div>
							<div class="clv_auth_block_content_form_buttons" :class="{ hide: clvIsLoading }">
								<button type="submit" class="clv_button common green" @click="executeLogin">Войти</button>
							</div>
						</form>
					</div>
				</div>
				<div is="ClvAuthFooter"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import ClvAuthHeader from './ClvAuthHeader.vue'
	import ClvAuthFooter from './ClvAuthFooter.vue'
	import VueRecaptcha from 'vue-recaptcha'
	import axios from 'axios'
	export default {
		data: () => ({
			clvTemp: '',
			clvAlert: '',
			clvAlertEmail: '',
			clvAlertPassword: '',
			clvAlertRecaptcha: '',
			clvEmail: '',
			clvPassword: '',
			clvRecaptcha: '',
			clvIsLoading: false,
			clvIsError: false
		}),
		methods: {
			isReady: function() {
				var _this = this;
				_this.$parent.initRecaptcha();
				if (localStorage.getItem('clvToken')) {
					setTimeout(function() { _this.$router.push({ path: '/account' }); }, 0);
				}
			},
			resetAlerts: function() {

				this.clvAlert = '';
				this.clvAlertEmail = '';
				this.clvAlertPassword = '';
				this.clvAlertRecaptcha = '';
			},
			getError: function(i) {

				this.clvIsError = true;

				if      (i == 1) { this.clvTemp = 'Необходимо указать e-mail.'; }
				else if (i == 2) { this.clvTemp = 'Необходимо указать верный e-mail.'; }
				else if (i == 3) { this.clvTemp = 'Необходимо указать пароль.'; }
				else if (i == 4) { this.clvTemp = 'Необходимо указать верный пароль. Разрешено вводить только буквы и цифры. Длина: от 6 до 30 символов. Обязательно: хотя бы одна буква.'; }
				else if (i == 5) { this.clvTemp = 'Необходимо пройти анти-спам проверку.'; }
				else if (i == 6) { this.clvTemp = 'Необходимо указать верные учетные данные.'; }
				else if (i == 7) { this.clvTemp = 'Аккаунт не активирован! Для активации аккаунта необходимо перейти по ссылке, которая была отправлена на ваш электронный адрес.'; }
				else             { this.clvTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><strong>Ошибка!</strong> ' + this.clvTemp + '</div>';
			},
			resetRecaptcha: function() {
				this.clvRecaptcha = '';
				this.$refs.recaptcha.reset();
			},
			verifyRecaptcha: function(token) {
				this.clvRecaptcha = token;
			},
			setRoutePath: function() {
				var clvRoutePath = '/account';
				if (this.$cookies.get('clvRoutePath')) {
					clvRoutePath = this.$cookies.get('clvRoutePath'); this.$cookies.remove('clvRoutePath');
				}
				this.$router.push({ path: clvRoutePath });
			},
			executeLogin: function(event) {

				event.preventDefault();

				this.resetAlerts();
				this.clvIsLoading = false;
				this.clvIsError = false;

				if (!this.clvEmail) {
					this.clvAlertEmail = this.getError(1);
				}
				else if (!this.$store.state.clvRegexEmail.test(this.clvEmail)) {
					this.clvAlertEmail = this.getError(2);
				}
				if (!this.clvPassword) {
					this.clvAlertPassword = this.getError(3);
				}
				else if (!this.$store.state.clvRegexPassword.test(this.clvPassword) || this.clvPassword.search(this.$store.state.clvRegexSearchENRULetters) < 0) {
					this.clvAlertPassword = this.getError(4);
				}
				if (!this.clvRecaptcha) {
					this.clvAlertRecaptcha = this.getError(5);
				}

				if (!this.clvIsError) {
					var _this = this; _this.clvIsLoading = true;
					axios({
						url: '/v2/oauth/login',
						data: { email: _this.clvEmail, password: _this.clvPassword, recaptcha: _this.clvRecaptcha },
						method: 'post', baseURL: _this.$store.state.clvApiBaseURL
					}).then(function(data) {
						if (data.status == 200) {
							if (data.data.token) {
								_this.setRoutePath(); localStorage.setItem('clvToken', data.data.token);
							}
						}
					}).catch(function(data) {
						if (data.response !== undefined) {
							if (data.response.status == 400) {
								if      (data.response.data.error == 'EMAIL_IS_EMPTY') { _this.clvAlertEmail = _this.getError(1); }
								else if (data.response.data.error == 'EMAIL_NOT_VALID') { _this.clvAlertEmail = _this.getError(2); }
								else if (data.response.data.error == 'PASSWORD_IS_EMPTY') { _this.clvAlertPassword = _this.getError(3); }
								else if (data.response.data.error == 'PASSWORD_NOT_VALID') { _this.clvAlertPassword = _this.getError(4); }
								else if (data.response.data.error == 'RECAPTCHA_IS_EMPTY') { _this.clvAlertRecaptcha = _this.getError(5); }
								else if (data.response.data.error == 'RECAPTCHA_NOT_VALID') { _this.clvAlertRecaptcha = _this.getError(5); }
							}
							else if (data.response.status == 404) {
								if (data.response.data.error == 'BAD_CREDENTIALS') { _this.clvAlert = _this.getError(6); }
							}
							else if (data.response.status == 409) {
								if (data.response.data.error == 'ACCOUNT_NOT_ACTIVATED') { _this.clvAlert = _this.getError(7); }
							}
						}
						if (!_this.clvIsError) {
							_this.clvAlert = _this.getError(0);
						}
						_this.resetRecaptcha();
					}).finally(function() {
						_this.clvIsLoading = false;
					});
				}
			}
		},
		components: {
			ClvAuthHeader,
			ClvAuthFooter,
			VueRecaptcha
		},
		created: function() {
			this.isReady();
		}
	}
</script>

<style>
	.clv_auth {
		text-align: center;
		vertical-align: middle;
		display: table-cell;
		width: 100vw;
		height: 100vh;
	}
	.clv_auth_block {
		padding: 30px 0;
		display: inline-block;
		width: 500px;
	}
	.clv_auth_block_logo {
		margin: 15px 0 30px 0;
	}
	.clv_auth_block_logo a {
		text-decoration: none;
	}
	.clv_auth_block_logo img {
		margin: 0 0 -5px 0;
		height: 60px;
		opacity: 0.4;
	}
	.clv_auth_block_logo div {
		color: #555555;
		display: inline-block;
		width: 60%;
	}
	.clv_auth_block_logo div:last-child {
		margin: 10px 0 0 0;
		font-size: 15px;
	}
	.clv_auth_block_content {
		margin: 15px 0;
		padding: 20px 30px;
		border-radius: 15px;
		background: #33333380;
		color: #FFFFFF;
	}
	.clv_auth_block_content a,
	.clv_auth_block_content input,
	.clv_auth_block_content button {
		color: #FFFFFF;
	}
	.clv_auth_block_content_name {
		margin: 0 0 20px 0;
		padding: 0 0 10px 0;
		border-bottom: 2px solid #FFFFFF;
		text-transform: uppercase;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		font-size: 15px;
	}
	.clv_auth_block_content_form .alert {
		position: relative;
		margin: 10px 0 5px 0;
		padding: 5px 10px 7px 10px;
		border: 1px solid #FFFFFF;
		border-radius: 15px;
		background: #FFFFFF;
		color: #333333;
		font-size: 10px;
		line-height: 14px;
	}
	.clv_auth_block_content_form_row {
		position: relative;
		display: table;
		width: 100%;
		height: 30px;
	}
	.clv_auth_block_content_form_row .alert:before {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 100%;
		margin-left: -8px;
		border: 8px solid transparent;
		border-bottom-color: #FFFFFF;
		pointer-events: none;
		width: 0;
		height: 0;
	}
	.clv_auth_block_content_form_row.last .alert {
		margin: 10px 0 0 0;
	}
	.clv_auth_block_content_form_row_label {
		position: absolute;
		top: 15px;
		left: -15px;
		text-align: right;
		width: 30%;
	}
	.clv_auth_block_content_form_row_input {
		float: right;
		width: 70%;
	}
	.clv_auth_block_content_form_row_recaptcha {
		float: right;
		position: relative;
		margin: 20px 0 0 0;
		display: table;
		width: 70%;
		height: 60px;
	}
	.clv_auth_block_content_form_row_recaptcha .clv_recaptcha {
		position: absolute;
		top: 0;
		left: 50%;
		margin: 0 0 0 -86px;
		transform: scale(0.795);
		transform-origin: 0 0;
	}
	.clv_auth_block_content_form_row_recaptcha .alert {
		margin: 70px 0 0 0 !important;
	}
	.clv_auth_block_content_form_alert .alert {
		margin: 15px 0 0 0;
		padding: 8px 10px 10px 10px;
	}
	.clv_auth_block_content_form_loading,
	.clv_auth_block_content_form_buttons {
		margin: 20px 0 0 0;
		text-align: right;
		line-height: 20px;
		height: 20px;
	}
	.clv_auth_block_content_form_buttons button {
		margin: 0 0 0 10px;
	}
	.clv_auth_block_content_form_buttons button:first-child {
		margin: 0;
	}
	.clv_auth_block_footer_menu {
		position: fixed;
		top: 30px;
		right: 130px;
	}
	.clv_auth_block_footer_menu a {
		margin: 0 8px 0 0;
		padding: 0 15px;
		border-radius: 15px;
		background: #33333380;
		color: #FFFFFF;
		text-align: center;
		text-decoration: none;
		font-family: 'OpenSansSemiBold', Arial, sans-serif;
		line-height: 30px;
		display: inline-block;
	}
	.clv_auth_block_footer_menu a:hover {
		background: #333333B3;
	}
	.clv_auth_block_footer_copyright {
		margin: 15px 0 0 0;
		color: #555555;
		display: inline-block;
		width: 100%;
	}

	@media (max-width: 1370px) {
		.clv_auth_block_footer_menu {
			margin: 5px 0 0 0;
		}
		.clv_auth_block_footer_menu a {
			margin: 0 5px 5px 0;
		}
	}
	@media (max-width: 540px) {
		.clv_auth_block {
			padding: 30px 20px;
			width: 100%;
		}
		.clv_auth_block_content {
			padding: 20px;
		}
		.clv_auth_block_content_form_row_label {
			top: 6px;
			left: 0;
			text-shadow: 1px 1px 1px #555555;
			width: auto;
			z-index: 1;
		}
		.clv_auth_block_content_form_row_input {
			position: relative;
			width: 100%;
		}
		.clv_auth_block_content_form_row_input .clv_input {
			padding: 0 0 0 54px;
		}
		.clv_auth_block_content_form_row_recaptcha {
			width: 100%;
		}
		.clv_auth_block_content_form_row_recaptcha .clv_recaptcha {
			margin: 0 0 0 -120px;
		}
		.clv_auth_block_content_form_loading,
		.clv_auth_block_content_form_buttons {
			text-align: center;
		}
	}
	@media (max-width: 350px) {
		.clv_auth_block_footer_menu a {
			padding: 0 14px;
		}
	}
</style>
